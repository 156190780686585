import { Card } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CommonCodeInfoReturn from "components/commonCode/CommonCodeInfoReturn";
import { useEffect, useState } from "react";
import SoftAvatar from "components/SoftAvatar";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Footer from "examples/Footer";

import Table from "examples/Tables/MemberTable";
import allMemberTableData from "../data/allMemberTableData";
import { useQuery } from "react-query";
import allCommonCodeList from "api/commonCode/allCommonCodeList";
import { customAxios } from '../../../axios/CustomAxios'
import { myIpAddress } from '../../../config'
import Swal from "sweetalert2";
import { useRecoilState, useRecoilValue } from "recoil";
import listUpdate from "atom/data/listUpdate";
import { loginUser } from "atom/member/loginUser";
import ReactDatePicker from "react-datepicker";
import {ko} from "date-fns/esm/locale";
import './index.css';
import { DateToString } from "utils/DateToString";
import SoftButton from "components/SoftButton";

function MemberTable() {
  
    const currentUser = useRecoilValue(loginUser);
    const currentUserRole = currentUser.role;

    const { columns } = allMemberTableData;
    const [userList, setUserList] = useState([]);   
    const [roleList, setRoleList] = useState([]);
    const [teamList, setTeamList] = useState([]);
    const [rankList, setRankList] = useState([]);
    
    const [joinDate, setJoinDate] = useState(null);
    const [memUpdate, setMemUpdate] = useRecoilState(listUpdate);      

    const updateUser = (userNo, userName, userTeam, userRank, role, joinDate) => {
          const roleInfo = roleList.find((item) => item.role === role)?.info;
          const teamInfo = teamList.find((item) => item.team === userTeam)?.info;
          const rankInfo = rankList.find((item) => item.rank === userRank)?.info;
          const userJoinDate = joinDate;
          Swal.fire({
          title: "회원 : " + userName + '&nbsp; <br/>' + "--- 변경 후 --- " + '<br/>' + "부서 : " + teamInfo + '<br/>' +  
                  "직급 : " + rankInfo + '<br/>' + "권한 : " + roleInfo + '<br/>' + "입사일 : " + userJoinDate,
          text: "위와 같이 변경할까요?",      
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '변경',
          backdrop:false         
        }).then((result) => {
          if (result.isConfirmed) {            
              customAxios
                .post(myIpAddress+"/admin/user/updateInfo", { memNo: userNo, role: role, memTeam : userTeam, memRank : userRank, memJoinDate : userJoinDate })
                .then(()=>{
                 Swal.fire({
                 title:'변경 완료',
                 text:'해당 회원의 정보가 변경되었습니다.',          
                 icon:'success',
                 backdrop:false               
              })             
              setMemUpdate(true)
            })
            .catch(()=>{
              Swal.fire({
                title:'변경 실패',
                text:'관리자만 변경 할 수 있습니다.',          
                icon:'error',
                backdrop:false               
              })
            })              
          } 
        })     
     }    
    
    const handleDelete = (userNo, userName) => {
      Swal.fire({
      title: "회원 : " + userName + '&nbsp;',
      text: "해당 회원을 추방하시겠습니까?",      
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '추방',
      backdrop:false         
    }).then((result) => {
      if (result.isConfirmed) {
          customAxios
            .post(myIpAddress+"/admin/user/delete", { memNo: userNo })
            .then(()=>{
             Swal.fire({
             title:'추방 완료',
             text:'해당 회원이 추방되었습니다.',          
             icon:'success',
             backdrop:false               
          }) 
          setMemUpdate(true)                         
        })
        .catch(()=>{
          Swal.fire({
            title:'추방 실패',
            text:'관리자만 할 수 있습니다.',          
            icon:'error',
            backdrop:false               
         })
       })         
      } 
      })     
 }
      
 
    useEffect(() => {
      customAxios.get(myIpAddress+"/user/all")
        .then(response => {
          const users = response.data.map(user => {
            return {
              ...user,
              role: user.role || '', // 회원의 role 값이 null인 경우 빈 문자열로 설정
            };
          });
          setUserList(users);
          setMemUpdate(false)          
        })
        .catch(error => {
          console.log(error);
        });
    }, [memUpdate]);

      const {data, isSuccess} = useQuery('allCommonCodeList',allCommonCodeList,{
        // 데이터 불러오지(fetch) 않는 시간 지정 20분
        staleTime : 120000,
        refetchOnWindowFocus : false,
        refetchOnMount : false,
        select : (data) => {
            return data.data
        }
    })
    useEffect(() => {
      if (isSuccess) {
        const newRoleArr = data.filter((item) => item.cc_category === 'AUTH').map((item) => ({
          role: item.cc_name,
          info: item.cc_info,
        }));
        setRoleList(newRoleArr);
        const newTeamArr = data.filter((item) => item.cc_category === 'TEAM').map((item) => ({
          team: item.cc_name,
          info: item.cc_info,
        }));
        setTeamList(newTeamArr);
        const newRankArr = data.filter((item) => item.cc_category === 'RANK').map((item) => ({
          rank: item.cc_name,
          info: item.cc_info,
        }));
        // 240109 CC_NAME 기준으로 순서 정렬
        const sortRankArr = newRankArr.sort((a, b) => a.rank.localeCompare(b.rank));
        setRankList(sortRankArr);
        setRankList(newRankArr);
      }
    }, [isSuccess]);

    
    function User({ image, name, email, tel }) {
        return (
          <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
            <SoftBox mr={2}>
              <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
            </SoftBox>
            <SoftBox display="flex" flexDirection="column">
              <SoftTypography variant="button" fontWeight="medium">
                {name}
              </SoftTypography>
              <SoftTypography variant="caption" color="secondary">
                {tel}
              </SoftTypography>
              <SoftTypography variant="caption" color="secondary">
                {email}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        );
      }
      
      function Division({ team, rank }) {
        return (
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="h6" fontWeight="medium" color="text">
              {team}
            </SoftTypography>
            <SoftTypography variant="h6" color="secondary">
              {rank}
            </SoftTypography>
          </SoftBox>
        );
      }
  
     return (
        <DashboardLayout>
        <DashboardNavbar />
        <SoftBox py={3}>
          <SoftBox mb={3}>
            <Card>
              <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <SoftTypography variant="h5">전체 회원 목록</SoftTypography>
              </SoftBox>
              <SoftBox
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                <Table columns={columns} rows={userList.map((user) => (                 
                  {
                    사원: <User image={user.imageUrl} name={user.memName} email={user.memId} tel={user?.memTel}/>,
                    소속: <Division team={<CommonCodeInfoReturn init={user.memTeam}/>} rank={<CommonCodeInfoReturn init={user.memRank}/>} />,                    
                    입사일: <SoftTypography variant="h6" fontWeight="light">{user.memJoinDate}</SoftTypography>,
                    수정:<td style={{ display: 'flex', alignItems: 'center', borderBottom : "none" }}>
                    <select
                      style={{height:"30px"}}
                      value={user.memTeam ? user.memTeam : ""}
                      disabled={(currentUserRole !== "ADMIN" && currentUserRole !== "MASTER") ? true : false} 
                      onChange={(e) => {
                        const newTeam = e.target.value;
                        setUserList((prevUserList) => prevUserList.map((user2) => {
                          if (user.memNo === user2.memNo) {
                            return { ...user, memTeam: newTeam };
                          } else {
                            return user2;
                          }
                        }));
                      }}
                    > 
                    <option value=""> </option>
                    {teamList.map((team) => (
                      <option key={team.team} value={team.team}>
                        {team.info}
                      </option>
                    ))}
                    </select>&nbsp;
                    <select
                      style={{height:"30px"}}
                      value={user.memRank? user.memRank : ""}
                      disabled={(currentUserRole !== "ADMIN" && currentUserRole !== "MASTER") ? true : false} 
                      onChange={(e) => {
                        const newRank = e.target.value;
                        setUserList((prevUserList) => prevUserList.map((user2) => {
                          if (user.memNo === user2.memNo) {
                            return { ...user, memRank: newRank };
                          } else {
                            return user2;
                          }
                        }));
                      }}
                    > 
                    <option value=""> </option>
                      {rankList.map((rank) => (
                        <option key={rank.rank} value={rank.rank}>
                          {rank.info}
                        </option>
                      ))}
                    </select>&nbsp;
                    <select
                      style={{height:"30px"}}
                      value={user.role}
                      disabled={(currentUserRole !== "ADMIN" && currentUserRole !== "MASTER") ? true : false} 
                      onChange={(e) => {
                        const newRole = e.target.value;
                        setUserList((prevUserList) => prevUserList.map((user2) => {
                          if (user.memNo === user2.memNo) {
                            return { ...user, role: newRole };
                          } else {
                            return user2;
                          }
                        }));
                      }}
                    > 
                      {roleList.map((role) => (
                        <option key={role.role} value={role.role}>
                          {role.info}
                        </option>
                      ))}
                    </select>
                      <SoftBox ml={1} style={{height:"40px"}}>
                      <ReactDatePicker
                      popperClassName="popper-container"
                      inputProps={{ height: "40px" }}
                      portal={true}
                      locale={ko} // 언어설정 기본값은 영어
                      dateFormat="yyyy-MM-dd" // 날짜 형식 설정
                      className="join-datepicker" // 클래스 명 지정 css주기 위해
                      placeholderText="입사일 수정"
                      showYearDropdown // 연도 선택
                      showMonthDropdown // 달 선택
                      disabled={(currentUserRole !== "ADMIN" && currentUserRole !== "MASTER") ? true : false} 
                      selected={user.memJoinDate ? new Date(user.memJoinDate) : null} // 선택된 날짜
                      onChange={(date) => {
                        setUserList((prevUserList) =>
                          prevUserList.map((user2) => {
                            if (user.memNo === user2.memNo) {
                              return { ...user2, memJoinDate: date ? DateToString(date) : null };
                            } else {
                              return user2;
                            }
                          })
                        );
                      }} 
                    /></SoftBox>
                      </td>,
                     비고: (currentUserRole === "ADMIN" || currentUserRole === "MASTER" ? <td><SoftButton variant="gradient" color="dark" className="modBtn" style={{height:"30px"}} onClick={() => updateUser(user.memNo, user.memName, user.memTeam, user.memRank, user.role, user.memJoinDate)}>정보 수정</SoftButton> &nbsp;
                             <SoftButton variant="gradient" color="dark" className="modBtn" style={{height:"30px"}} onClick={() => handleDelete(user.memNo, user.memName)}>회원 추방</SoftButton></td> : <></>)
                }
                ))} />                
              </SoftBox>
            </Card>
          </SoftBox>
        </SoftBox>        
        <Footer />
      </DashboardLayout>
    )
}

export default MemberTable;