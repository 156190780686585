import {useState, useEffect} from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import breakpoints from "assets/theme/base/breakpoints";
import curved0 from "assets/images/curved-images/curved0.jpg";
import CommonCodeInfoReturn from "../../../../components/commonCode/CommonCodeInfoReturn";
import {useRecoilValue} from "recoil";
import {loginUser} from "atom/member/loginUser";

function Header() {
    const [tabsOrientation, setTabsOrientation] = useState("horizontal");
    const [tabValue, setTabValue] = useState(0);
    const user = useRecoilValue(loginUser);

    useEffect(() => {
        // A function that sets the orientation state of the tabs.
        function handleTabsOrientation() {
            return window.innerWidth < breakpoints.values.sm
                ? setTabsOrientation("vertical")
                : setTabsOrientation("horizontal");
        }

        /**
         The event listener that's calling the handleTabsOrientation function when resizing the window.
         */
        window.addEventListener("resize", handleTabsOrientation);

        // Call the handleTabsOrientation function to set the state with the initial value.
        handleTabsOrientation();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleTabsOrientation);
    }, [tabsOrientation, user]);

    const handleSetTabValue = (event, newValue) => setTabValue(newValue);

    return (
        <SoftBox mb={3} position="relative">
            <DashboardNavbar absolute light/>
            <SoftBox
                display="flex"
                alignItems="center"
                position="relative"
                minHeight="18.75rem"
                borderRadius="xl"
                sx={{
                    backgroundImage: ({functions: {rgba, linearGradient}, palette: {gradients}}) =>
                        `${linearGradient(
                            rgba(gradients.info.main, 0.6),
                            rgba(gradients.info.state, 0.6)
                        )}, url(${curved0})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50%",
                    overflow: "hidden",
                }}
            />
            <Card
                sx={{
                    backdropFilter: `saturate(200%) blur(30px)`,
                    backgroundColor: ({functions: {rgba}, palette: {white}}) => rgba(white.main, 0.8),
                    boxShadow: ({boxShadows: {navbarBoxShadow}}) => navbarBoxShadow,
                    position: "relative",
                    mt: -8,
                    mx: 3,
                    py: 2,
                    px: 2,
                }}
            >
                <Grid container spacing={3} alignItems="center">
                    <Grid item>
                        <SoftAvatar
                            src={user.imageUrl}
                            alt="profile-image"
                            variant="rounded"
                            size="xl"
                            shadow="sm"
                            referrerpolicy="no-referrer"
                        />
                    </Grid>
                    <Grid item>
                        <SoftBox height="100%" mt={0.5} lineHeight={1}>
                            <SoftTypography variant="h5" fontWeight="medium">
                                {user.name}
                            </SoftTypography>
                            {user.team && user.rank ?
                                <SoftTypography variant="button" color="text" fontWeight="medium">
                                    <CommonCodeInfoReturn init={user.team}/> / <CommonCodeInfoReturn init={user.rank}/>
                                </SoftTypography>
                                : <></>
                            }
                            <br/>
                            <SoftTypography variant="button" fontWeight="medium">
                                {user.tel === null ? '연락처를 등록해주세요' : user.tel}
                            </SoftTypography>
                            <br/>
                            <SoftTypography variant="button" fontWeight="medium">
                                {user.email}
                            </SoftTypography>
                        </SoftBox>
                    </Grid>
                </Grid>
            </Card>
        </SoftBox>
    );
}

export default Header;
