import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import {isRecoilValue, useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {useEffect, useState} from "react";
import {toMap} from "../../../../utils/ToMap";
import Grid from "@mui/material/Grid";
import userAnnualInfo from "../../../../api/vacation/userAnnualInfo";
import {QueryClient, useQueries} from "react-query";
import {vacationInsertAtom} from "../../../../atom/vacation/vacationInsertAtom";
import {radioValueAtomFamily} from "../../../../atom/radioCheckBox/radioCheckBox";
import {selectDaysAtomFamily} from "../../../../atom/calendar/selectDaysAtomFamily";
import VacationDaysData from "../../../vacation/data/VacationDaysData";
import VacationBarChartData from "../../../vacation/data/VacationBarChartData";
import RadioCheckBox from "../../../../components/checkBox/RadioCheckBox";
import {Alert} from "@mui/material";
import Icon from "@mui/material/Icon";
import typography from "../../../../assets/theme/base/typography";
import DateRangePicker from "../../../../components/calendar/DateRangePicker";
import Switch from "@mui/material/Switch";
import {loginUser} from "../../../../atom/member/loginUser";
import SoftAvatar from "../../../../components/SoftAvatar";
import CommonCodeInfoReturn from "../../../../components/commonCode/CommonCodeInfoReturn";
import VacationPieChart from "../../../vacation/components/VacationPieChart";
import vacationView from "../../../../api/vacation/vacationView";
import VacationReason from "layouts/vacationInsert/components/VacationReason";
import VacationPhoneNum from "layouts/vacationInsert/components/VacationPhoneNum";
import {vacationDayStateAtom} from "../../../../atom/vacation/vacationDayStateAtom";
import SoftInput from "../../../../components/SoftInput";
import VacationUseDay from "layouts/vacationInsert/components/VacationUseDay";
import {vacationUseDayValueAtom} from "../../../../atom/vacation/vacationUseDayValueAtom";

/**
 * 휴가 input 영역
 * @param keyName : String - 키이름
 * @param vacNo : String - 문서번호
 * @returns {JSX.Element}
 */
function VacationWithdrawInputArea({keyName, vacNo}) {

    // 상세보기로 들어왔을때 기본값
    const [vacationData, setVacationData] =useState();

    // 사이즈 정보
    const {size} = typography;

    // react query
    const queryClient = new QueryClient();

    // login 정보
    const recoilState = isRecoilValue(loginUser);
    const user = useRecoilValue(loginUser);

    // recoil 정보가 가져와 지면 refetch
    useEffect(() => {
        if (recoilState) {
            // Only execute the query if myState is not null
            queryClient.refetchQueries('userAnnualInfo');
        }
    }, [recoilState]);


    // 휴가 일 수 DB정보
    const {items} = VacationDaysData().dayData;

    // 당해년도 월당 휴가 사용 정보
    const {chart} = VacationBarChartData().chartData;

    // 휴가 일 수 정보
    const [leftAnnualDay, setLeftAnnualDay] = useState();

    // key값
    const vacCategoryKey = keyName + "_category";
    const vacDateKey = keyName + "_day";
    const vacTelKey = keyName + "_tel";

    // 사용예정연차의 잔여연차 초과 에러
    const [leftAnnualError, setLeftAnnualError] = useState(false);
    // 휴가 당겨쓰기 여부
    const [pullAnnual, setPullAnnual] = useState(false);

    // 휴가 시작일 : start, 종료일 : end, 휴가일수 : day
    const vacationDateInfo = useRecoilValue(selectDaysAtomFamily(vacDateKey));
    // 휴가 구분 : value
    const vacationCategory = useRecoilValue(radioValueAtomFamily(vacCategoryKey));
    // recoil에 전체 값 저장
    const [vacationInfo, setVacationInfo] = useRecoilState(vacationInsertAtom);
    // 휴가 에러값
    const setVacationPullAnnalState = useSetRecoilState(vacationDayStateAtom);
    // 휴가 사용예정일
    const [vacUseDay, setVacUseDay] = useRecoilState(vacationUseDayValueAtom);

    // 휴가 당겨쓴 여부 저장
    useEffect(() => {
        const insert = toMap(vacationInfo);
        if(pullAnnual) {
            insert.set("vac_overuse", 1);
        } else {
            insert.set("vac_overuse", 0);
        }
        setVacationInfo(Object.fromEntries(insert));
    }, [pullAnnual]);

    // react-query
    useQueries(
        (vacNo !== undefined) ?
            [
                // queries[0] : 유저 휴가 정보
                {
                    queryKey: ['userAnnualInfo', user?.id],
                    queryFn: () => userAnnualInfo(parseInt(user?.id)),
                    options: {
                        staleTime: 120000,
                        refetchOnWindowFocus: false,
                        refetchOnMount: false,
                    },
                    onSuccess: (result)=>{
                        // 잔여 연차 정보
                        if (result.data.total_annual !== 0) {
                            setLeftAnnualDay(result.data.left_annual);
                        }
                    }
                },
                // queries[1] : 휴가 정보
                {
                    queryKey: ["vacationView", vacNo],
                    queryFn: () => vacationView(parseInt(vacNo)),
                    options: {
                        staleTime: 120000,
                        refetchOnWindowFocus: false,
                        refetchOnMount: false,
                    },
                    onSuccess: (result)=>{
                        setVacationData(result.data);
                        if (result?.data?.vac_overuse === 1) {
                            setPullAnnual(true);
                        }
                    }
                }
            ]
            : [
                // queries[0] : 유저 휴가 정보
                {
                    queryKey: ['userAnnualInfo', user?.id],
                    queryFn: () => userAnnualInfo(parseInt(user?.id)),
                    options: {
                        staleTime: 120000,
                        refetchOnWindowFocus: false,
                        refetchOnMount: false
                    },
                    onSuccess: (result)=>{
                        // 카테고리 기본값
                        setVacationData({vac_category : "VT000"});
                        // 잔여 연차 정보
                        if (result.data.total_annual !== 0) {
                            setLeftAnnualDay(result.data.left_annual);
                        }
                    }

                },
            ]
    );

    // 휴가 당겨쓰기
    const usePullAnnual = () => {
        return;
        // if (pullAnnual !== true) {
        //     setLeftAnnualError(false);
        //     setPullAnnual(true);
        // } else {
        //     setLeftAnnualError(true);
        //     setPullAnnual(false);
        // }
    }

    // 잔여 연차보다 사용 예정 휴가 일수가 적은지
    useEffect(() => {
        if(vacationCategory?.value === 'VT000' || vacationCategory?.value === 'VT004' || vacationCategory?.value === 'VT005') {
            if ((vacUseDay > leftAnnualDay) && pullAnnual !== true) {
                setLeftAnnualError(true);
                setVacationPullAnnalState(false);
            } else {
                setLeftAnnualError(false);
                setVacationPullAnnalState(true);
            }
        } else {
            setLeftAnnualError(false);
            setVacationPullAnnalState(true);
        }
    }, [vacUseDay, usePullAnnual])

    // 연차 대비 사용 휴가 일 수 많을 때
    const overVacationDays = ((leftAnnualError && vacationCategory?.value === "VT000") ? (
            <>
                <Alert variant="outlined" severity="error" style={{height: "60%", width: "100%"}}>
                    <strong>잔여 연차</strong>와 <strong>사용 예정 연차 일</strong>이 맞지 않습니다.
                    날짜나 휴가일을 수정하시거나 휴가를 당겨서 사용해주십시오
                </Alert>
            </>
        ) : (
            <></>
        )
    );

    return (
        <Card>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12}>
                    <Grid container spacing={1} p={2} justifyContent="flex-start" alignItems="flex-start">
                        <Grid item xs={12} md={6} lg={6}>
                            <Grid container spacing={3} justifyContent="space-between" alignItems="center">
                                <Grid item xs={12} md={12} lg={12}>
                                    <SoftBox display="flex" alignItems="center">
                                        <SoftBox
                                            bgColor="info"
                                            width="1.25rem"
                                            height="1.25rem"
                                            borderRadius="sm"
                                            color="white"
                                            fontSize={size.lg}
                                            shadow="md"
                                            mr={1}
                                            mt={-0.7}
                                            variant="gradient"
                                        >
                                            <Icon>person</Icon>
                                        </SoftBox>
                                        <SoftTypography
                                            variant="h6" gutterBottom fontWeight="bold"
                                        >
                                            사용자정보 및 휴가정보
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}
                                      display="flex" justifyContent="flex-start" alignItems="center">
                                    <SoftBox>
                                        <SoftAvatar
                                            src={user?.imageUrl}
                                            alt="profile-image"
                                            variant="rounded"
                                            size="xl"
                                            shadow="sm"
                                        />
                                    </SoftBox>
                                    <SoftBox ml={1}>
                                        <SoftBox mt={0.5} lineHeight={1}>
                                            <SoftTypography variant="h5" fontWeight="medium">
                                                {user?.name}
                                            </SoftTypography>
                                            <SoftTypography variant="button" color="text" fontWeight="medium">
                                                <CommonCodeInfoReturn init={user?.team}/> / <CommonCodeInfoReturn
                                                init={user?.rank}/>
                                            </SoftTypography>
                                        </SoftBox>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <VacationPieChart/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Grid item ml={-2} mb={1}>
                                <RadioCheckBox keyName={vacCategoryKey} category="VACATION_TYPE"
                                               title="휴가 구분"
                                               defaultValue={vacationData?.vac_category}
                                               isWithdraw={true}/>
                            </Grid>
                            <Grid item mb={-3}>
                                <DateRangePicker keyName={vacDateKey} vacationType={vacationCategory?.value === undefined ? "VT000" : vacationCategory?.value}
                                                 defaultStart={vacationData?.vac_start}
                                                 defaultEnd={vacationData?.vac_end}
                                                 isWithdraw={true}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                            <Grid item xs={12} lg={12} md={12}>
                                <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                                    <SoftTypography variant="h6" gutterBottom fontWeight="bold">
                                        휴가 당겨쓰기&nbsp;&nbsp;&nbsp;
                                    </SoftTypography>
                                    <Switch checked={pullAnnual} onChange={usePullAnnual}/>
                                </SoftBox>
                                <SoftBox display="flex" justifyContent='flex-start' alignItems="center">
                                    {overVacationDays}
                                </SoftBox>
                            </Grid>
                            <Grid item xs={6} md={3.5} lg={3.5}>
                                <Card>
                                    <SoftBox p={1}>
                                        <SoftBox display="flex" alignItems="center">
                                            <SoftBox
                                                bgColor="info"
                                                width="1.25rem"
                                                height="1.25rem"
                                                borderRadius="sm"
                                                color="white"
                                                fontSize={size.lg}
                                                shadow="md"
                                                mr={1}
                                                mt={-0.7}
                                                variant="gradient"
                                            >
                                                <Icon>person</Icon>
                                            </SoftBox>
                                            <SoftTypography
                                                variant="h6" gutterBottom fontWeight="bold"
                                            >
                                                연락처
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftBox>
                                            <VacationPhoneNum category="TEL" keyName={vacTelKey} defaultValue={vacationData?.vac_tel} isWithdraw={true}/>
                                        </SoftBox>
                                    </SoftBox>
                                </Card>
                            </Grid>
                            <Grid item xs={6} md={4} lg={4}>
                                <Card>
                                    <SoftBox p={1}>
                                        <SoftBox display="flex" alignItems="center">
                                            <SoftBox
                                                bgColor="info"
                                                width="1.25rem"
                                                height="1.25rem"
                                                borderRadius="sm"
                                                color="white"
                                                fontSize={size.lg}
                                                shadow="md"
                                                mr={1}
                                                mt={-0.7}
                                                variant="gradient"
                                            >
                                                <Icon>add</Icon>
                                            </SoftBox>
                                            <SoftTypography
                                                variant="h6" gutterBottom fontWeight="bold"
                                            >
                                                사용예정 휴가일
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                                            <VacationUseDay defaultValue={vacationDateInfo?.day} editData={vacationData?.vac_days} error={(leftAnnualError && vacationCategory?.value === "VT000")} isWithdraw={true}/>
                                        </SoftBox>
                                    </SoftBox>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <Card>
                                    <SoftBox p={1}>
                                        <SoftBox display="flex" alignItems="center">
                                            <SoftBox
                                                bgColor="info"
                                                width="1.25rem"
                                                height="1.25rem"
                                                borderRadius="sm"
                                                color="white"
                                                fontSize={size.lg}
                                                shadow="md"
                                                mr={1}
                                                mt={-0.7}
                                                variant="gradient"
                                            >
                                                <Icon>calendar_today</Icon>
                                            </SoftBox>
                                            <SoftTypography
                                                variant="h6" gutterBottom fontWeight="bold"
                                            >
                                                잔여 연차
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                                            <SoftTypography
                                                variant="h5"
                                                fontWeight="bold"
                                                color={(leftAnnualError && vacationCategory?.value === "VT000") ? "error" : "inherit"}
                                            >
                                                {leftAnnualDay}일&nbsp;&nbsp;&nbsp;
                                            </SoftTypography>
                                        </SoftBox>
                                    </SoftBox>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <VacationReason row={2} defaultValue={vacationData?.vac_reason} isWithdraw={true}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
}

export default VacationWithdrawInputArea;
