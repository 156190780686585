import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import {useQueries} from "react-query";
import allCommonCodeList from "../../api/commonCode/allCommonCodeList";
import allCommonCodeCategoryMap from "../../api/commonCode/allCommonCodeCategoryMap";
import {toMap} from "../../utils/ToMap";
import {useRecoilState} from "recoil";
import {radioValueAtomFamily} from "../../atom/radioCheckBox/radioCheckBox";
import {useEffect, useState} from "react";
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import HoverTip from "../hover/HoverTip";
import Icon from "@mui/material/Icon";
import typography from "../../assets/theme/base/typography";
import Grid from "@mui/material/Grid";
import vacationView from "../../api/vacation/vacationView";

/**
 * 키값을 받아서 해당하는 이름으로 리코일 값 저장
 * @param keyName : String key이름
 * @param category : String 카테고리값
 * @param defaultValue : String 휴가 값
 * @param title : String 제목
 * @returns {JSX.Element}, setValue : String 클릭한 공용코드 값
 */
const RadioCheckBox = ({keyName, category, defaultValue, title, isWithdraw}) => {
    const key = keyName; // 키값
    const [useCommonCodeList, setUseCommonCodeList] = useState([]); // 사용할 공용코드 리스트
    const [labelMap, setLabelMap] = useState(new Map()); // 공용코드에 해당하는 라벨을 만들어주는 map

    // 카테고리가 휴가 분류 일때 호버 기능추가
    let hoverUse;
    if (category === "VACATION_TYPE") {
        hoverUse = true;
    }

    // size 설정값
    const {size} = typography;

    // radio값 저장하는 리코일
    const [radioValue, setRadioValue] = useRecoilState(radioValueAtomFamily(key));

    // react-query
    useQueries([
        // queries[0] : 공용코드 리스트
        {
            queryKey: ["allCommonCodeList"],
            queryFn: () => allCommonCodeList(),
            onSuccess: (result)=>{
                const commonCodeList = result.data;
                const filterCommonCode = [];
                const filterMap = new Map();
                for (let i = 0; i < commonCodeList?.length; i++) {
                    if (commonCodeList[i].cc_category === category) {
                        filterCommonCode.push(commonCodeList[i]?.cc_name);
                        filterMap.set(commonCodeList[i]?.cc_name, commonCodeList[i]?.cc_info);
                    }
                }
                setUseCommonCodeList(filterCommonCode);
                setLabelMap(filterMap);
            }
        }
    ]);

    // 처음 실행 시 실행
    useEffect(() => {
        const lastValue = toMap(radioValue);
        if (defaultValue !== undefined && defaultValue !== '') {
            // 기본값이 넘어왔을때
            lastValue.set("value", defaultValue);
        } else {
            // 기본값이 넘어오지 않았을때
            lastValue.set("value", useCommonCodeList[0]);
        }
        setRadioValue(Object.fromEntries(lastValue));
    }, [defaultValue]);

    // 라벨 렌더링
    function rendering() {
        const result = [];
        const commonCodeList = [...useCommonCodeList];
        for (let i = 0; i < commonCodeList.length; i++) {
            if (hoverUse) {
                result.push(
                    <HoverTip key={`${keyName}+${i}`} commonCode={commonCodeList[i]}
                              category={category}/>
                );
            } else {
                result.push(
                    <FormControlLabel
                        key={`${commonCodeList[i]}+${i}`}
                        value={commonCodeList[i]}
                        control={<Radio/>}
                        label={labelMap.get(commonCodeList[i])}
                        style={{paddingRight: '0.5rem'}}
                    />
                );
            }
        }
        return result;
    }

    // 클릭한 버튼 값 밸류로 넣어주기
    const radioButtonClick = (e) => {
        const lastValue = toMap(radioValue);
        // 휴가 취소 폼이면 라디오 버튼 값 변경 못하게
        isWithdraw ? lastValue.set("value", defaultValue) : lastValue.set("value", e.target.value);
        setRadioValue(Object.fromEntries(lastValue));
    }

    return (
        <>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={12} md={12} lg={12}>
                    <SoftBox display="flex" alignItems="center" ml={-1} p={3}>
                        <SoftBox
                            bgColor="info"
                            width="1.25rem"
                            height="1.25rem"
                            borderRadius="sm"
                            color="white"
                            fontSize={size.lg}
                            shadow="md"
                            mr={1}
                            mt={-0.7}
                            variant="gradient"
                        >
                            <Icon>flag</Icon>
                        </SoftBox>
                        <SoftTypography
                            variant="h6" gutterBottom fontWeight="bold"
                        >
                            {title}
                        </SoftTypography>
                    </SoftBox>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <SoftBox ml={3.5} mt={-3}>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby={keyName}
                                value={radioValue.value}
                                onChange={radioButtonClick}
                            >
                                {rendering()}
                            </RadioGroup>
                        </FormControl>
                    </SoftBox>
                </Grid>
            </Grid>
        </>
    );
}

export default RadioCheckBox;