import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import {useLocation, useNavigate} from "react-router-dom";
import Card from "@mui/material/Card";
import SoftTypography from "../../components/SoftTypography";
import SoftButton from "../../components/SoftButton";
import {useMutation, useQueries} from "react-query";
import vacationView from "../../api/vacation/vacationView";
import allCommonCodeMap from "../../api/commonCode/allCommonCodeMap";
import userWorkInfo from "../../api/user/userWorkInfo";
import SoftAvatar from "../../components/SoftAvatar";
import Tooltip from "@mui/material/Tooltip";
import borders from "../../assets/theme/base/borders";
import {GetDayOfWeek} from "../../utils/GetDayOfWeek";
import iconConfig from "../../iconConfig";
import colorConfig from "../../colorConfig";
import {DiffTwoDate, TimestampToStringDate, TimestampToYMD} from "../../utils/DateUtils";
import {PDFViewer, Document, Page, Text, Font, View, StyleSheet, Image} from '@react-pdf/renderer';
import {useEffect, useState} from "react";
import {Modal} from "@mui/material";
import godicFont from '../../assets/font/malgunbd.ttf';
import {useRecoilValue} from "recoil";
import {loginUser} from "../../atom/member/loginUser";
import Swal from "sweetalert2";
import vacationApprovalCancel from "../../api/vacation/vacationApprovalCancel";
import vacationApprovalSignUp from "../../api/vacation/vacationApprovalSignUp";
import vacationApprovalReject from "../../api/vacation/vacationApprovalReject";
import vacationDocDelete from "../../api/vacation/vacationDocDelete";
import typography from "../../assets/theme/base/typography";
import CommonCodeInfoReturn from "../../components/commonCode/CommonCodeInfoReturn";
import refViewCheckUpdate from "../../api/refer/refViewCheckUpdate";
import rejectViewCheckUpdate from "../../api/approval/rejectViewCheckUpdate";

/**
 * 문서 번호로 휴가 문서 상세보기
 * @returns {JSX.Element}
 * @constructor
 */
function VacationView() {
    // 로그인 리코일
    const user = useRecoilValue(loginUser);

    // config 값
    const {size} = typography;
    const {borderWidth, borderColor} = borders;
    const {vacationTypeIcon} = iconConfig;
    const {approvalType} = colorConfig;

    // vavigate
    const navigate = useNavigate();

    // 전달된 문서 번호
    const location = useLocation();
    const docNo = location.state;

    // 직접접근 방지
    useEffect(() => {
        if (location.state === null) {
            Swal.fire({
                title: '잘못된 접근입니다.',
                icon: 'warning',
                backdrop: true
            })
            navigate("/dashboard")
        }
    }, [])

    // 상신 취소
    const handleCancel = () => {
        const body = {
            doc_no: docNo
            , approval_no: approvalNo
            , vac_no: docNo
            , vac_approved: approvalStatusCode === 'AP008' ? 'AP011' :'AP006'
            , doc_type: docType
            , mem_no: user?.id
            , mem_name: user?.name
            , approverList : approverList ? approverList : []
        }

        Swal.fire({
            title: '문서를 상신 취소하시겠습니까?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '상신취소',
            cancelButtonText: '나가기',
            backdrop: false

        }).then((result) => {
            if (result.isConfirmed) {
                addCancelBody(body)
            }
            if (result.isDenied) {
                Swal.close();
            }
        })
    }

    // 상신 취소
    const {mutate: addCancelBody} = useMutation(vacationApprovalCancel, {
        onSuccess: (data) => {
            Swal.fire({
                title: '상신 취소 되었습니다.',
                icon: 'success',
                backdrop: false
            })
            navigate('/docs/sent', {state: 'APT000'});
        },
        onError: () => {
            Swal.fire({
                title: '업데이트 중 에러가 발생했습니다. 잠시후 다시 실행해주세요',
                icon: 'error',
            })
        }
    });

    // 임시보관
    const handleStore = () => {
        const body = {
            doc_no: docNo
            , approval_no: approvalNo
            , vac_no: docNo
            , vac_approved: 'AP005'
            , mem_no: user?.id
            , mem_name: user?.name
            , doc_type: docType
        }

        Swal.fire({
            title: '문서를 보관함으로 이동하시겠습니까?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '문서보관',
            cancelButtonText: '취소',
            backdrop: false

        }).then((result) => {
            if (result.isConfirmed) {
                addStoreBody(body)
            }
            if (result.isDenied) {
                Swal.close();
            }
        })
    }

    // 보관함으로 이동
    const {mutate: addStoreBody} = useMutation(vacationApprovalCancel, {
        onSuccess: (data) => {
            Swal.fire({
                title: '보관함으로 이동 되었습니다.',
                icon: 'success',
                backdrop: false
            })
            navigate('/docs/store', {state: 'APT000'});
        },
        onError: () => {
            Swal.fire({
                title: '업데이트 중 에러가 발생했습니다. 잠시후 다시 실행해주세요',
                icon: 'error',
            })
        }
    });

    // 상신 문서 결재
    const handleSignUp = () => {
        let vacApproved;
        // 마지막 결재일때 결재 완료 처리
    
        if (approvalApprovers === approvalDo + 1) {
            if (approvalStatusCode === 'AP008') {
                vacApproved = 'AP009'
            } else {
                vacApproved = 'AP003'
            }
        } else {
            vacApproved = 'AP002'
        }
        const body = {
            doc_no: docNo
            , approval_no: approvalNo
            , vac_no: docNo
            , vac_approved: vacApproved
            , approval_do: approvalDo
            , approver_mem_no: approvalOrderMemNo
            , next_approver_mem_no: nextApproverMemNo
            , mem_no: memNo
            , mem_name: name
            , doc_type: docType
            , vac_start: startDate
            , vac_end: endDate
        }

        Swal.fire({
            title: (approvalStatusCode === 'AP008') ?  '결재 취소 승인 처리 하시겠습니까?' : '결재 승인 처리 하시겠습니까?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: (approvalStatusCode === 'AP008') ? '결재 취소 승인' : '결재승인',
            cancelButtonText: '취소',
            backdrop: false

        }).then((result) => {
            if (result.isConfirmed) {
                addSignUpBody(body);
            }
            if (result.isDenied) {
                Swal.close();
            }
        })
    }

    // 상신 문서 결재
    const {mutate: addSignUpBody} = useMutation(vacationApprovalSignUp, {
        onSuccess: (data) => {
            Swal.fire({
                title: '문서결재 되었습니다.',
                icon: 'success',
                backdrop: false
            })
            navigate('/docs/receive', {state: 'APT000'});
        },
        onError: () => {
            Swal.fire({
                title: '업데이트 중 에러가 발생했습니다. 잠시후 다시 실행해주세요',
                icon: 'error',
            })
        }
    });

    // 상신 문서 결재반려
    const handleReject = () => {
        Swal.fire({
            title: '결재 반려 처리 하시겠습니까?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: '취소',
            confirmButtonText: '결재반려',
            backdrop: false

        }).then((result) => {
            if (result.isConfirmed) {
                (async () => {
                    const {value: getReason} = await Swal.fire({
                        title: '결재 반려 사유를 입력해주세요.',
                        text: '100자 이내로 작성해주십시오.',
                        input: 'text',
                        inputPlaceholder: '결재 반려사유 ',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: '취소',
                        confirmButtonText: '반려',
                        backdrop: false
                    })

                    // 이후 처리되는 내용.
                    if (getReason !== undefined) {
                        if (getReason && getReason.length <= 100) {
                            let vacApproved = 'AP004';
    
                            const body = {
                                doc_no: docNo
                                , approval_no: approvalNo
                                , vac_no: docNo
                                , vac_approved: vacApproved
                                , approver_mem_no: approvalOrderMemNo
                                , approval_cancel_reason: getReason
                                , mem_no: user?.id
                                , mem_name: user?.name
                                , doc_type: docType
                            }
                            addRejectBody(body);
                        } else if (getReason && getReason.length > 100) {
                            Swal.fire({
                                title: '반려 사유가 100자를 초과하였습니다',
                                icon: 'warning',
                                backdrop: false
                            })
                        } else {
                            Swal.fire({
                                title: '반려 사유 입력해주세요',
                                icon: 'warning',
                                backdrop: false
                            })
                        }
                    }
                })()
            }
        })
    }

    const handleWithdraw = () => {
        Swal.fire({
            title: '결재 취소를 반려 처리 하시겠습니까?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: '취소',
            confirmButtonText: '결재 취소 반려',
            backdrop: false

        }).then((result) => {
            if (result.isConfirmed) {
                (async () => {
                    const {value: getReason} = await Swal.fire({
                        title: '결재 취소 반려 사유를 입력해주세요.',
                        text: '100자 이내로 작성해주십시오.',
                        input: 'text',
                        inputPlaceholder: '결재 취소 반려사유 ',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: '취소',
                        confirmButtonText: '결재 취소 반려',
                        backdrop: false
                    })

                    // 이후 처리되는 내용.
                    if (getReason !== undefined) {
                        if (getReason && getReason.length <= 100) {
                            let vacApproved = 'AP010'; // 결재 취소 반려 -> 결제 승인된 채("AP001")로 백에서 처리 

                            const body = {
                                doc_no: docNo
                                , approval_no: approvalNo
                                , vac_no: docNo
                                , vac_approved: vacApproved
                                , approver_mem_no: approvalOrderMemNo
                                , approval_cancel_reason: getReason
                                , mem_no: user?.id
                                , mem_name: user?.name
                                , doc_type: docType
                                , approverList : approverList ? approverList : []
                                , vac_start : startDate
                                , vac_end : endDate
                            }
                            addRejectBody(body);
                        } else if (getReason && getReason.length > 100) {
                            Swal.fire({
                                title: '결재 취소 반려 사유가 100자를 초과하였습니다',
                                icon: 'warning',
                                backdrop: false
                            })
                        } else {
                            Swal.fire({
                                title: '결재 취소 반려 사유 입력해주세요',
                                icon: 'warning',
                                backdrop: false
                            })
                        }
                    }
                })()
            }
        })
    }

    // 상신 문서 결재 반려
    const {mutate: addRejectBody} = useMutation(vacationApprovalReject, {
        onSuccess: (data) => {
            Swal.fire({
                title: '문서 반려 되었습니다.',
                icon: 'success',
                backdrop: false,
            })
            navigate('/docs/receive', {state: 'APT000'});
        },
        onError: () => {
            Swal.fire({
                title: '업데이트 중 에러가 발생했습니다. 잠시후 다시 실행해주세요',
                icon: 'error',
            })
        }
    });

    // 상신 문서 삭제
    const handleDelete = () => {
        if (approvalStatusCode === "AP005") {
            const body = {
                doc_no: docNo
                , approval_no: approvalNo
                , vac_no: docNo
                , mem_no: user?.id
                , mem_name: user?.name
                , doc_type: docType
            }

            Swal.fire({
                title: '문서를 삭제 하시겠습니까?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: '문서삭제',
                cancelButtonText: '취소',
                backdrop: false

            }).then((result) => {
                if (result.isConfirmed) {
                    addDeleteBody(body);
                }
                if (result.isDenied) {
                    Swal.close();
                }
            })
        }
    }

    // 상신 문서 삭제
    const {mutate: addDeleteBody} = useMutation(vacationDocDelete, {
        onSuccess: (data) => {
            Swal.fire({
                title: '문서 삭제 되었습니다.',
                icon: 'success',
                backdrop: false
            })
            navigate('/docs/store', {state: 'APT000'});
        },
        onError: () => {
            Swal.fire({
                title: '삭제 중 에러가 발생했습니다. 잠시후 다시 실행해주세요',
                icon: 'error',
            })
        }
    });

    // 참조자 문서열람 여부 업데이트
    const {mutate: refViewCheckBody} = useMutation(refViewCheckUpdate, {
        onSuccess: (data) => {
            Swal.fire({
                title: '참조 확인 되었습니다.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: '확인',
                backdrop: false
            })
        },
        onError: () => {
            console.log('참조자 열람 업데이트 중 에러')
        }
    });

    // 반려문서 열람여부 업데이트
    const {mutate: rejectViewCheckBody} = useMutation(rejectViewCheckUpdate, {
        onSuccess: (data) => {

        },
        onError: () => {
            console.log('반려문서 열람 업데이트 중 에러')
        }
    });


    const queries = useQueries([
            // queries[0] : 휴가 정보
            {
                queryKey: ["vacationView", docNo],
                queryFn: () => vacationView(parseInt(docNo)),
                options: {
                    staleTime: 120000,
                    refetchOnWindowFocus: false,
                    refetchOnMount: false,
                },
                onError: (data) => {
                    Swal.fire({
                        title: '잘못된 접근입니다.',
                        icon: 'warning',
                        backdrop: true
                    })
                        .then(() => {
                            navigate("/")
                        });
                }
            },
            // queries[1] : 공용코드 리스트
            {
                queryKey: ["allCommonCodeMap"],
                queryFn: () => allCommonCodeMap(),
                options: {
                    staleTime: 120000,
                    refetchOnWindowFocus: false,
                    refetchOnMount: false,
                }
            },
            // queries[2] : 유저 리스트
            {
                queryKey: ["userWorkInfo"],
                queryFn: () => userWorkInfo(),
                options: {
                    staleTime: 120000,
                    refetchOnWindowFocus: false,
                    refetchOnMount: false,
                }
            }
        ]
    )

    // 휴가 정보
    let startDate; // 휴가 시작일
    let startDateWeek; // 휴가 시작일 요일정보
    let endDate; // 휴가 종료일
    let endDateWeek; // 휴가 종료일 요일정보
    let tel; // 연락처
    let days; // 총 휴가 일 수
    let type; // 휴가 타입
    let typeIcon; // 휴가 타입 아이콘
    let approvalStatus; // 결재 상태
    let approvalStatusCode; // 결재상태 코드
    let approvalStatusColor; // 결재상태 색
    let name; // 이름
    let team; // 소속부서
    let rank; // 직급
    let signature; // 결재요청자 서명 이미지
    let reason; // 휴가 사유
    let regDate; // 문서 상신일
    let regDateYMD; // 문서 상신일 연월일
    let approverList; // 결재자 리스트
    let referrerList; // 참조자 리스트
    let codeMap; // 공용코드 리스트
    let view; // 문서 정보
    let fileName; // 파일이름
    let approvalNo; // 결재 번호
    let approvalApprovers; // 요구되는 결재자 수
    let approvalDo; // 결재한 총 결재자 수
    let approvalOrderMemNo; // 이번 결재자 멤버 번호
    let img; // 결재요청자 프로필 이미지
    let docType; // 문서타입
    let memNo; // 멤버번호
    let nextApproverMemNo; // 다음 결재자 멤버 번호

    // 참조자 및 반려 문서 체크
    const [refCheck, setRefCheck] = useState(false);
    const [rejectDocCheck, setRejectDocCheck] = useState(true);

    if (queries[0].isSuccess && queries[1].isSuccess && queries[2].isSuccess) {
        // 휴가 상세보기
        view = queries[0].data.data;
        // 공용코드 리스트
        codeMap = queries[1].data.data;
        // 유저 리스트
        const userInfo = queries[2].data.data;

        memNo = view["mem_no"];
        type = codeMap[view["vac_category"]]["info"];
        typeIcon = vacationTypeIcon[view["vac_category"]];
        startDate = view["vac_start"];
        docType = view["doc_type"];
        startDateWeek = GetDayOfWeek(startDate);
        endDate = view["vac_end"];
        endDateWeek = GetDayOfWeek(endDate);
        days = view["vac_days"];
        tel = view["vac_tel"];
        img = view["imageUrl"];
        approvalStatus = codeMap[view["vac_approved"]]["info"];
        approvalStatusCode = view["vac_approved"];
        approvalStatusColor = approvalType[view["vac_approved"]];
        reason = view["vac_reason"];
        regDate = TimestampToStringDate(view["vac_regdate"]);
        regDateYMD = TimestampToYMD(view["vac_regdate"]);
        for (let i = 0; i < userInfo.length; i++) {
            if (memNo === parseInt(userInfo[i]["memNo"])) {
                name = userInfo[i]["memName"];
                team = userInfo[i]["memTeam"];
                rank = userInfo[i]["memRank"];
                signature = window.atob(userInfo[i]["signature"]);
                break;
            }
        }
        approverList = view["approverList"];
        referrerList = view["referrerList"];
        fileName = "[휴가]" + startDate + "~" + endDate + "_" + name + ".pdf";
        approvalNo = view['approval_no'];
        if (approverList.length > 0) {
            approvalApprovers = approverList[0]['approval_approvers'];
            approvalDo = approverList[0]['approval_do'];
        }
    
        for (let i = 0; i < approverList.length; i++) {
            console.log(approverList[i]["approver_order"]);
            if (approverList[i]["approver_order"] === 1) {
                approvalOrderMemNo = approverList[i]["approver_mem_no"];

                console.log(approvalOrderMemNo);
            
                for (let j = 0; j < approverList.length; j++) {
                    if ((approverList[i]["approver_level"] + 1) === approverList[j]["approver_level"]) {
                        nextApproverMemNo = approverList[j]["approver_mem_no"];
                        break;
                    }
                }
                break;
            }
        }

        // 반려문서일때 해당하는 문서가 로그인 사람 문서일때
        if (rejectDocCheck && approvalStatusCode === "AP004" && user?.id === memNo) {
            const rejectViewBody = {
                mem_no: memNo,
                approval_no: view['approval_no']
            }
            rejectViewCheckBody(rejectViewBody);
            setRejectDocCheck(false);
        }

        // 내가 해당하는 문서의 참조자일 때 참조 확인 처리
        if (!refCheck && (approvalStatusCode === "AP003" || approvalStatusCode === "AP000" || approvalStatusCode === "AP008")) {
            for (let i = 0; i < referrerList.length; i++) {
                if (referrerList[i]["ref_mem_no"] === user?.id) {
                    if(referrerList[i]["ref_regdate"] === null) {
                        const refBody = {
                            ref_mem_no: user?.id,
                            approval_no: view['approval_no']
                        }
                        refViewCheckBody(refBody);
                        setRefCheck(true);
                        break;
                    }
                }
            }
            if (!refCheck) {
                setRefCheck(true);
            }
        }
    }

    /**
     * 결재자 리스트업
     * @returns {JSX.Element}
     */
    const approverListShow = () => {
        return (
            <>
                {approverList?.map((item, index) => (
                    <Grid item xs={12} md={6} lg={6}>
                        <Tooltip
                            title={
                                item["approver_do"] === 1 ?
                                    "결재 완료 [결재일 : " + TimestampToStringDate(item["approver_regdate"]) + "]"
                                    :
                                    item["approver_cancel"] === 2 ?
                                        `결재 반려 [반려사유: ${item["approval_cancel_reason"]}] ` :
                                        "결재 미완료"
                            }
                            placement="top"
                        >
                            <SoftBox display="flex" justifyContent="flex-start" alignItems="center" flexWrap="wrap">
                                <SoftAvatar
                                    src={item["approver_img"]}
                                    alt="profile-image"
                                    variant="rounded"
                                    size="md"
                                    shadow="sm"
                                />
                                <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize" ml={1}
                                                color={item["approver_do"] === 1 ? "success" : item["approver_cancel"] === 2 ? "error" : "secondary"}
                                >
                                    {item["approver_level"] + 1}차 결재자
                                    {item["approver_do"] === 1 ? <Icon fontSize="small">check_circle</Icon>
                                        :
                                        item["approver_cancel"] === 2 ?
                                            <Icon fontSize="small">dangerous</Icon>
                                            : <Icon fontSize="small">warning</Icon>
                                    }

                                    <br/> [{codeMap[item["approver_team"]]["info"]}] {item["approver_name"]}&nbsp;{codeMap[item["approver_rank"]]["info"]}
                                </SoftTypography>
                            </SoftBox>
                        </Tooltip>
                    </Grid>
                ))}
            </>
        )
    }

    /**
     * 참조자 리스트업
     * @returns {JSX.Element}
     */
    const referrerListShow = () => {
        return (
            <>
                {referrerList?.map((item, index) => (
                    <Grid item xs={12} md={6} lg={6}>
                        <Tooltip
                            title={
                                item["ref_regdate"] !== null ?
                                    "참조 확인 확인일 : " + TimestampToStringDate(item["ref_regdate"]) + ""
                                    : "참조 미확인"
                            }
                            placement="top"
                        >
                            <SoftBox display="flex" justifyContent="flex-start" alignItems="center" flexWrap="wrap">
                                <SoftAvatar
                                    src={item["referrer_img"]}
                                    alt="profile-image"
                                    variant="rounded"
                                    size="md"
                                    shadow="sm"
                                />
                                <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize" ml={1}
                                                color={item["ref_regdate"] !== null ? "success" : "secondary"}
                                >
                                    참조자
                                    {item["ref_regdate"] !== null ? <Icon fontSize="small">check_circle</Icon> :
                                        <Icon fontSize="small">warning</Icon>}
                                    <br/> [{codeMap[item["referrer_team"]]["info"]}] {item["referrer_name"]}&nbsp;{codeMap[item["ref_rank"]]["info"]}
                                </SoftTypography>
                            </SoftBox>
                        </Tooltip>
                    </Grid>
                ))}
            </>
        )
    }

    // 나눔고딕 폰트 등록
    Font.register({
        family: 'godic',
        src: godicFont
    });

    const styles = StyleSheet.create({
        page: {
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 35,
        },
        section: {
            border: '1.5pt solid black',
            padding: 20,
            margin: 10,
            flexGrow: 1,
        },
        table: {
            display: "table",
            width: "auto",
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row",
        },
        tableCellHeader: {
            marginTop: 5,
            marginBottom: 5,
            fontSize: 15,
            textAlign: "center",
            fontFamily: "godic"
        },
        tableCellContent: {
            marginTop: 5,
            marginBottom: 5,
            fontSize: 12,
            textAlign: "center",
            fontFamily: "godic"
        },
    });

    /**
     * pdf
     * @returns {JSX.Element}
     * @constructor
     */
    function PDFDocView() {
        const companyLogo = "/company-logo.jpeg";
        return (
            <Document title={fileName}>
                <Page size="A4" style={styles.page}>
                    <View style={styles.section}>
                        {/* 상단 로고 추가 */}
                        <View style={{ position:"absolute",left:20, top:20 }}>
                            <Image src={companyLogo} style={{ width: 75}}/>
                        </View>
                        <Text style={{
                            paddingTop: 30,
                            paddingBottom: 50,
                            fontFamily: 'godic',
                            fontSize: "20px",
                            textAlign: "center"
                        }}>
                            휴 가 신 청 서
                        </Text>
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={{width: "100%", flexDirection: "row"}}>
                                    <View style={{
                                        width: "30%",
                                    }}>
                                    </View>
                                    <View style={{
                                        width: "40%",
                                    }}>
                                    </View>
                                    {/* 결재 */}
                                    {/* 무결재 일때*/}
                                    {approvalStatusCode === "AP000" ? (
                                        <></>
                                    ) : (
                                        <>
                                            <View style={{
                                                width: "20%",
                                                borderStyle: "solid",
                                                borderColor: "#000",
                                                borderWidth: 1,
                                                borderRightWidth: 1,
                                                borderLeftWidth: 1,
                                                borderBottomWidth: 0,
                                            }}>
                                                <Text style={styles.tableCellContent}>결 재</Text>
                                            </View>
                                            <View style={{
                                                width: "70%",
                                                borderStyle: "solid",
                                                borderColor: "#000",
                                                borderWidth: 1,
                                                borderLeftWidth: 0,
                                                borderBottomWidth: 0,
                                            }}>
                                                <View style={styles.tableRow}>
                                                    {approverList?.map((item, index) => (
                                                        index === approverList.length - 1 ?
                                                            <View style={{
                                                                width: `${100 / approverList.length}%`,
                                                                borderStyle: "solid",
                                                                borderColor: "#000",
                                                                borderWidth: 0,
                                                                borderBottomWidth: 1,
                                                            }}>
                                                                <Text
                                                                    style={styles.tableCellContent}>{codeMap[approverList[index]["approver_rank"]].info}</Text>
                                                            </View>
                                                            :
                                                            <View style={{
                                                                width: `${100 / approverList.length}%`,
                                                                borderStyle: "solid",
                                                                borderColor: "#000",
                                                                borderRightWidth: 1,
                                                                borderBottomWidth: 1,
                                                            }}>
                                                                <Text style={styles.tableCellContent}>
                                                                    {codeMap[approverList[index]["approver_rank"]].info}
                                                                </Text>
                                                            </View>
                                                    ))}
                                                </View>
                                                <View style={styles.tableRow}>
                                                    {approverList?.map((item, index) => (
                                                        index === approverList.length - 1 ?
                                                            <View style={{
                                                                width: `${100 / approverList.length}%`,
                                                                margin: "auto",
                                                                height: "7vh",
                                                                borderStyle: "solid",
                                                                borderColor: "#000",
                                                                borderWidth: 0

                                                            }}>
                                                                <Image src={approverList[index]?.approver_signature}
                                                                       style={{
                                                                           margin: "auto",
                                                                           width: "40%",
                                                                           height: "90%"
                                                                       }}/>
                                                            </View>
                                                            : <View style={{
                                                                width: `${100 / approverList.length}%`,
                                                                margin: "auto",
                                                                height: "7vh",
                                                                borderStyle: "solid",
                                                                borderColor: "#000",
                                                                borderRightWidth: 1
                                                            }}>
                                                                <Image src={approverList[index]?.approver_signature}
                                                                       style={{
                                                                           margin: "auto",
                                                                           width: "90%",
                                                                           height: "90%"
                                                                       }}/>
                                                            </View>
                                                    ))}
                                                </View>
                                            </View>
                                        </>
                                    )}
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={{width: "100%", margin: "auto", flexDirection: "row"}}>
                                    <View style={{
                                        width: "100%",
                                        borderStyle: "solid",
                                        borderColor: "#000",
                                        borderWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 0,
                                    }}>
                                        <Text style={styles.tableCellHeader}>신 청 내 역</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={{width: "100%", margin: "auto", flexDirection: "row"}}>
                                    <View style={{
                                        width: "25%",
                                        borderStyle: "solid",
                                        borderColor: "#000",
                                        borderWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 0,
                                    }}>
                                        <Text style={styles.tableCellContent}>소속</Text>
                                    </View>
                                    <View style={{
                                        width: "50%",
                                        borderStyle: "solid",
                                        borderColor: "#000",
                                        borderWidth: 1,
                                        borderRightWidth: 1,
                                        borderLeftWidth: 0,
                                        borderBottomWidth: 0,
                                    }}>
                                        <Text style={styles.tableCellContent}>{codeMap[team]?.info}</Text>
                                    </View>
                                    <View style={{
                                        width: "25%",
                                        borderStyle: "solid",
                                        borderColor: "#000",
                                        borderWidth: 1,
                                        borderRightWidth: 1,
                                        borderLeftWidth: 0,
                                        borderBottomWidth: 0,
                                    }}>
                                        <Text style={styles.tableCellContent}>직급</Text>
                                    </View>
                                    <View style={{
                                        width: "50%",
                                        borderStyle: "solid",
                                        borderColor: "#000",
                                        borderWidth: 1,
                                        borderLeftWidth: 0,
                                        borderBottomWidth: 0,
                                    }}>
                                        <Text style={styles.tableCellContent}>{codeMap[rank]?.info}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={{width: "100%", margin: "auto", flexDirection: "row"}}>
                                    <View style={{
                                        width: "25%",
                                        borderStyle: "solid",
                                        borderColor: "#000",
                                        borderWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 0,
                                    }}>
                                        <Text style={styles.tableCellContent}>연락처</Text>
                                    </View>
                                    <View style={{
                                        width: "50%",
                                        borderStyle: "solid",
                                        borderColor: "#000",
                                        borderWidth: 1,
                                        borderRightWidth: 1,
                                        borderLeftWidth: 0,
                                        borderBottomWidth: 0,
                                    }}>
                                        <Text style={styles.tableCellContent}>{tel}</Text>
                                    </View>
                                    <View style={{
                                        width: "25%",
                                        borderStyle: "solid",
                                        borderColor: "#000",
                                        borderWidth: 1,
                                        borderRightWidth: 1,
                                        borderLeftWidth: 0,
                                        borderBottomWidth: 0,
                                    }}>
                                        <Text style={styles.tableCellContent}>성명</Text>
                                    </View>
                                    <View style={{
                                        width: "50%",
                                        borderStyle: "solid",
                                        borderColor: "#000",
                                        borderWidth: 1,
                                        borderLeftWidth: 0,
                                        borderBottomWidth: 0,
                                    }}>
                                        <Text style={styles.tableCellContent}>{name}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={{width: "100%", margin: "auto", flexDirection: "row"}}>
                                    <View style={{
                                        width: "20%",
                                        borderStyle: "solid",
                                        borderColor: "#000",
                                        borderWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 0,
                                    }}>
                                        <Text style={styles.tableCellContent}>휴가구분</Text>
                                    </View>
                                    <View style={{
                                        width: "100%",
                                        borderStyle: "solid",
                                        borderColor: "#000",
                                        borderWidth: 1,
                                        borderRightWidth: 1,
                                        borderLeftWidth: 0,
                                        borderBottomWidth: 0,
                                    }}>
                                        <Text style={styles.tableCellContent}>{type}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={{width: "100%", margin: "auto", flexDirection: "row"}}>
                                    <View style={{
                                        width: "20%",
                                        borderStyle: "solid",
                                        borderColor: "#000",
                                        borderWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 0,
                                    }}>
                                        <Text style={styles.tableCellContent}>휴가기간</Text>
                                    </View>
                                    <View style={{
                                        width: "100%",
                                        borderStyle: "solid",
                                        borderColor: "#000",
                                        borderWidth: 1,
                                        borderRightWidth: 1,
                                        borderLeftWidth: 0,
                                        borderBottomWidth: 0,
                                    }}>
                                        <Text
                                            style={styles.tableCellContent}>{startDate} ~ {endDate}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={{width: "100%", margin: "auto", flexDirection: "row"}}>
                                    <View style={{
                                        width: "20%",
                                        borderStyle: "solid",
                                        borderColor: "#000",
                                        borderWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 0,
                                    }}>
                                        <Text style={styles.tableCellContent}>사용 휴가일</Text>
                                    </View>
                                    <View style={{
                                        width: "100%",
                                        borderStyle: "solid",
                                        borderColor: "#000",
                                        borderWidth: 1,
                                        borderRightWidth: 1,
                                        borderLeftWidth: 0,
                                        borderBottomWidth: 0,
                                    }}>
                                        <Text style={styles.tableCellContent}>{days}일</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={{width: "100%", margin: "auto", flexDirection: "row"}}>
                                    <View style={{
                                        width: "20%",
                                        borderStyle: "solid",
                                        borderColor: "#000",
                                        borderWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 0,
                                    }}>
                                        <Text style={styles.tableCellContent}>휴가사유</Text>
                                    </View>
                                    <View style={{
                                        width: "100%",
                                        borderStyle: "solid",
                                        borderColor: "#000",
                                        borderWidth: 1,
                                        borderRightWidth: 1,
                                        borderLeftWidth: 0,
                                        borderBottomWidth: 0,
                                    }}>
                                        <Text style={styles.tableCellContent}>{reason}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={{width: "100%", margin: "auto", flexDirection: "row"}}>
                                    <View style={{
                                        width: "100%",
                                        borderStyle: "solid",
                                        borderColor: "#000",
                                        borderWidth: 1,
                                        borderBottomWidth: 1,
                                    }}>
                                        <Text style={{
                                            marginTop: 50,
                                            marginBottom: 5,
                                            fontSize: 12,
                                            textAlign: "center",
                                            fontFamily: "godic"
                                        }}>위와 같이 휴가를 신청하오니 허가하여 주시기 바랍니다.</Text>
                                        <Text style={{
                                            marginTop: 40,
                                            marginBottom: 35,
                                            fontSize: 12,
                                            textAlign: "center",
                                            fontFamily: "godic"
                                        }}>{regDateYMD["year"]}년 {regDateYMD["month"]}월 {regDateYMD["day"]}일</Text>
                                        <View style={styles.tableRow}>
                                            <Text style={{
                                                marginTop: 10,
                                                marginBottom: 10,
                                                fontSize: 12,
                                                textAlign: "center",
                                                fontFamily: "godic"
                                            }}>신청자 성명 : {name}  </Text>
                                            <Image src={signature} style={{margin: "auto", width: 30, height: 30}}/>
                                        </View>
                                        <Text style={{
                                            marginTop: 40,
                                            marginBottom: 35,
                                            fontSize: 12,
                                            textAlign: "center",
                                            fontFamily: "godic"
                                        }}>나인원소프트주식회사</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </Page>
            </Document>
        );
    }

    // modal open
    const [modalOpen, setModalOpen] = useState(false);

    /**
     * modal close
     * @param event
     */
    const modalClose = (event) => {
        if (event.target === event.currentTarget) {
            setModalOpen(false);
        }
    };

    const editButton = () => {
        navigate("/vacation/edit", {state: {vacNo: parseInt(docNo), approvalNo: parseInt(approvalNo)}});
    }

    const withdrawButton = () => {
        navigate("/vacation/withdraw", {state: {vacNo: parseInt(docNo), approvalNo: parseInt(approvalNo)}});
    }

    // 접속한 사용자가 관리자인지 체크
    const masterCheck = () => {
        if (user && user != undefined) {
            if (!user.role || user.role == undefined) return false;
            if (!user.roles || user.roles == undefined) return false;
            if (user.role === 'MASTER') return true;
            if (Array.from(user.roles).includes('MASTER')) return true;
        } 

        return false;
    }

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <SoftBox>
                <SoftBox mb={1}>
                    <Card>
                        <Grid container spacing={1} p={1}>
                            <Grid item xs={12} md={4} lg={4}>
                                <SoftBox p={1}>
                                    <SoftTypography variant="h5" fontWeight="bold" textTransform="capitalize">
                                        상세보기(
                                        <span style={{color: approvalStatusColor}}>{approvalStatus}</span>
                                        )
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={12} md={8} lg={8} display="flex" justifyContent="flex-end"
                                  alignItems="center">
                                {/* 상신 취소일때 */}
                                {approvalStatusCode === "AP006" && user?.id === view?.mem_no ?
                                    <SoftButton variant="gradient" color="primary"
                                                onClick={() =>
                                                    editButton()
                                                }>
                                        <Icon sx={{fontWeight: "bold"}}>edit_note</Icon>
                                        &nbsp;문서수정
                                    </SoftButton>
                                    :<></>
                                }
                                {/* 결재 완료일때 */}
                                {approvalStatusCode === "AP003"?
                                    <>
                                        {user?.id === view?.mem_no ? 
                                                <SoftButton variant="gradient" color="primary"
                                                                onClick={() =>
                                                                editButton()
                                                                }>
                                                                <Icon sx={{fontWeight: "bold"}}>edit_note</Icon>
                                                                &nbsp;수정 후 재요청
                                                </SoftButton>
                                        : <></>}
                                        &nbsp;&nbsp;
                                        {user?.id === view?.mem_no ?
                                            <SoftButton variant="gradient" color="error" onClick={() =>
                                                // handleCancel()
                                                withdrawButton()
                                            }>
                                                <Icon sx={{fontWeight: "bold"}}>cancel</Icon>
                                                &nbsp;휴가 취소 요청
                                            </SoftButton>
                                        : <></>}
                                         &nbsp;&nbsp;
                                        <SoftButton variant="gradient" color="info" onClick={() => {
                                            setModalOpen(true);
                                        }
                                        }>
                                            <Icon sx={{fontWeight: "bold"}}>picture_as_pdf</Icon>
                                            &nbsp;PDF미리보기
                                        </SoftButton>
                                    </>
                                    :<></>
                                }
                                {(approvalStatusCode === "AP001" || approvalStatusCode === "AP008") && user?.id === view?.mem_no ?
                                    <>
                                        {user?.id === approvalOrderMemNo ?
                                            <>
                                                <SoftButton variant="gradient" color="success"
                                                            onClick={() =>
                                                             handleSignUp()
                                                            }>
                                                    <Icon sx={{fontWeight: "bold"}}>archive</Icon>
                                                    &nbsp; { approvalStatusCode === "AP001" ? '결재 승인' : '결재 취소 승인'}
                                                </SoftButton>
                                                &nbsp;&nbsp;
                                                <SoftButton variant="gradient" color="secondary"
                                                            onClick={() =>
                                                                (approvalStatusCode === "AP001") ? handleReject() : handleWithdraw() 
                                                            }>
                                                    <Icon sx={{fontWeight: "bold"}}>cancel</Icon>
                                                    &nbsp;{approvalStatusCode === "AP001" ? '결재 반려' : '결재 취소 반려'}
                                                </SoftButton>
                                            </>
                                            :<></>
                                        }
                                        &nbsp;&nbsp;
                                        <SoftButton variant="gradient" color="error" onClick={() =>
                                            handleCancel()
                                        }>
                                            <Icon sx={{fontWeight: "bold"}}>cancel</Icon>
                                            &nbsp;상신취소
                                        </SoftButton>
                                        &nbsp;&nbsp;
                                        {(approvalStatusCode === "AP001") ? 
                                        <><SoftButton variant="gradient" color="info" onClick={() =>
                                            handleStore()
                                        }>
                                            <Icon sx={{fontWeight: "bold"}}>archive</Icon>
                                            &nbsp;임시보관
                                        </SoftButton>
                                        &nbsp;&nbsp;
                                        </> : <></>}
                                        <SoftButton variant="gradient" color="primary"
                                                    onClick={() =>
                                                        editButton()
                                                    }>
                                            <Icon sx={{fontWeight: "bold"}}>edit_note</Icon>
                                            &nbsp;문서수정
                                        </SoftButton>
                                    </>
                                    :
                                    // 상신문서이거나 결재중일때 로그인한 유저와 결재순번이 맞을 때
                                    ((approvalStatusCode === "AP001") || (approvalStatusCode === "AP002") || (approvalStatusCode === "AP008")) && (user?.id === approvalOrderMemNo) ?
                                        <>
                                            <SoftButton variant="gradient" color="success"
                                                        onClick={() =>
                                                            handleSignUp()
                                                        }>
                                                <Icon sx={{fontWeight: "bold"}}>archive</Icon>
                                                &nbsp;{(approvalStatusCode === "AP008") ? '결재 취소 승인' : '결재 승인'}
                                            </SoftButton>
                                            &nbsp;&nbsp;
                                            <SoftButton variant="gradient" color="secondary"
                                                        onClick={() =>
                                                            (approvalStatusCode === "AP008") ? handleWithdraw() : handleReject()
                                                        }>
                                                <Icon sx={{fontWeight: "bold"}}>cancel</Icon>
                                                &nbsp;{(approvalStatusCode === "AP008") ? '결재 취소 반려' : '결재 반려'}
                                            </SoftButton>
                                        </>
                                        :
                                        // 임시보관 문서일때
                                        (approvalStatusCode === "AP005") ?
                                            <>
                                                <SoftButton variant="gradient" color="error"
                                                            onClick={() =>
                                                                handleDelete()
                                                            }>
                                                    <Icon sx={{fontWeight: "bold"}}>cancel</Icon>
                                                    &nbsp;문서 삭제
                                                </SoftButton>
                                                &nbsp;&nbsp;
                                                <SoftButton variant="gradient" color="primary"
                                                            onClick={() =>
                                                                editButton()
                                                            }>
                                                    <Icon sx={{fontWeight: "bold"}}>edit_note</Icon>
                                                    &nbsp;문서수정
                                                </SoftButton>
                                            </>
                                            : // 반려 문서일때
                                            (approvalStatusCode === "AP004" && (user?.id === view?.mem_no)) ?
                                                <>
                                                    <SoftButton variant="gradient" color="primary"
                                                                onClick={() =>
                                                                    editButton()
                                                                }>
                                                        <Icon sx={{fontWeight: "bold"}}>edit_note</Icon>
                                                        &nbsp;문서수정
                                                    </SoftButton>
                                                </>
                                                : <></>
                                }
                            </Grid>
                        </Grid>
                    </Card>
                </SoftBox>
                <SoftBox mb={1}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Card>
                                <Grid container spacing={3} p={1.5} justifyContent="space-between" alignItems="center">
                                    <Grid item xs={12} md={12} lg={12}>
                                        <SoftBox display="flex" alignItems="center">
                                            <SoftBox
                                                bgColor="info"
                                                width="1.25rem"
                                                height="1.25rem"
                                                borderRadius="sm"
                                                color="white"
                                                fontSize={size.lg}
                                                shadow="md"
                                                mr={1}
                                                mt={-0.7}
                                                variant="gradient"
                                            >
                                                <Icon>person</Icon>
                                            </SoftBox>
                                            <SoftTypography
                                                variant="h6" gutterBottom fontWeight="bold"
                                            >
                                                사용자정보 및 휴가정보
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={12} md={3} lg={3} mt={-2.5}
                                          display="flex" justifyContent="flex-start" alignItems="center">
                                        <SoftBox>
                                            <SoftAvatar
                                                src={img}
                                                alt="profile-image"
                                                variant="rounded"
                                                size="xl"
                                                shadow="sm"
                                            />
                                        </SoftBox>
                                        <SoftBox ml={1}>
                                            <SoftBox mt={0.5} lineHeight={1}>
                                                <SoftTypography variant="h5" fontWeight="medium">
                                                    {name}
                                                </SoftTypography>
                                                <SoftTypography variant="button" color="text" fontWeight="medium">
                                                    <CommonCodeInfoReturn init={team}/> / <CommonCodeInfoReturn
                                                    init={rank}/>
                                                    <br/>
                                                    {tel}
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={12} md={9} lg={9} mt={-1.5}>
                                        <Grid container spacing={1.5} display="flex" justifyContent="space-between"
                                              alignItems="center">
                                            <Grid item xs={12} md={4} lg={4}>
                                                <Tooltip title="휴가 출발일" placement="top">
                                                    <SoftBox
                                                        border={`${borderWidth[1]} solid ${borderColor}`}
                                                        borderRadius="lg"
                                                        display="flex"
                                                        justifyContent="space-around"
                                                        alignItems="center"
                                                        flexWrap="wrap"
                                                        p={2}
                                                        sx={{
                                                            cursor: "pointer",
                                                            ':hover': {
                                                                backgroundColor: "#B9E4EE",
                                                                borderColor: "#35d1f5",
                                                                borderWidth: borderWidth[4]
                                                            }
                                                        }}
                                                    >
                                                        <SoftBox mb={-1} align="center">
                                                            <Icon fontSize="3rem">flight_takeoff</Icon>
                                                        </SoftBox>
                                                        <SoftTypography variant="h6" fontWeight="medium" align="center">
                                                            {startDate} ({startDateWeek})
                                                        </SoftTypography>
                                                    </SoftBox>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <Tooltip title="휴가 도착일" placement="top">
                                                    <SoftBox
                                                        border={`${borderWidth[1]} solid ${borderColor}`}
                                                        borderRadius="lg"
                                                        display="flex"
                                                        justifyContent="space-around"
                                                        alignItems="center"
                                                        flexWrap="wrap"
                                                        p={2}
                                                        sx={{
                                                            cursor: "pointer",
                                                            ':hover': {
                                                                backgroundColor: "#B9E4EE",
                                                                borderColor: "#35d1f5",
                                                                borderWidth: borderWidth[4],
                                                            }
                                                        }}
                                                    >
                                                        <SoftBox mb={-1} align="center">
                                                            <Icon fontSize="3rem">flight_land</Icon>
                                                        </SoftBox>
                                                        <SoftTypography variant="h6" fontWeight="medium" align="center">
                                                            {endDate} ({endDateWeek})
                                                        </SoftTypography>
                                                    </SoftBox>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <Tooltip title={`휴가타입(${type}) / 휴가일수`} placement="top">
                                                    <SoftBox
                                                        border={`${borderWidth[1]} solid ${borderColor}`}
                                                        borderRadius="lg"
                                                        display="flex"
                                                        justifyContent="space-around"
                                                        alignItems="center"
                                                        flexWrap="wrap"
                                                        p={2}
                                                        sx={{
                                                            cursor: "pointer",
                                                            ':hover': {
                                                                backgroundColor: "#B9E4EE",
                                                                borderColor: "#35d1f5",
                                                                borderWidth: borderWidth[4],
                                                            }
                                                        }}
                                                    >
                                                        <SoftBox mb={-1} align="center">
                                                            <Icon fontSize="3rem">{typeIcon}</Icon>
                                                        </SoftBox>
                                                        <SoftTypography variant="h6" fontWeight="medium" align="center">
                                                            {days}일
                                                        </SoftTypography>
                                                    </SoftBox>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} mt={-1}>
                                        <Grid container spacing={1.5} display="flex" justifyContent="space-between"
                                              alignItems="center">
                                            <Grid item xs={4} md={3} lg={3}>
                                                <SoftBox display="flex" alignItems="center" justifyContent="center">
                                                    <SoftBox
                                                        bgColor="info"
                                                        width="1.25rem"
                                                        height="1.25rem"
                                                        borderRadius="sm"
                                                        color="white"
                                                        fontSize={size.lg}
                                                        shadow="md"
                                                        mr={1}
                                                        mt={-0.7}
                                                        variant="gradient"
                                                    >
                                                        <Icon>edit_document</Icon>
                                                    </SoftBox>
                                                    <SoftTypography
                                                        variant="h6" gutterBottom fontWeight="bold"
                                                    >
                                                    {/* 결제 상세보기 -> 휴가사유 | 결제 취소 상세보기 -> 취소사유 */}
                                                        {approvalStatusCode === 'AP008' ? "취소사유" : "휴가사유"}
                                                    </SoftTypography>
                                                </SoftBox>
                                            </Grid>
                                            <Grid item xs={8} md={9} lg={9}>
                                                <SoftBox
                                                    border={`${borderWidth[1]} solid ${borderColor}`}
                                                    display="flex"
                                                    justifyContent="center"
                                                    borderRadius="lg"
                                                    p={1}
                                                    alignItems="center"
                                                >
                                                    <SoftTypography variant="h6" fontWeight="bold"
                                                                    textTransform="capitalize">
                                                        {reason}
                                                    </SoftTypography>
                                                </SoftBox>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} mt={-1}>
                                        <Grid container spacing={1.5} display="flex" justifyContent="space-between"
                                              alignItems="center">
                                            <Grid item xs={4} md={3} lg={3}>
                                                <SoftBox display="flex" alignItems="center" justifyContent="center">
                                                    <SoftBox
                                                        bgColor="info"
                                                        width="1.25rem"
                                                        height="1.25rem"
                                                        borderRadius="sm"
                                                        color="white"
                                                        fontSize={size.lg}
                                                        shadow="md"
                                                        mr={1}
                                                        mt={-0.7}
                                                        variant="gradient"
                                                    >
                                                        <Icon>pending_actions</Icon>
                                                    </SoftBox>
                                                    <SoftTypography
                                                        variant="h6" gutterBottom fontWeight="bold"
                                                    >
                                                        등록일
                                                    </SoftTypography>
                                                </SoftBox>
                                            </Grid>
                                            <Grid item xs={8} md={9} lg={9}>
                                                <SoftBox
                                                    border={`${borderWidth[1]} solid ${borderColor}`}
                                                    display="flex"
                                                    justifyContent="center"
                                                    borderRadius="lg"
                                                    p={1}
                                                    alignItems="center"
                                                >
                                                    <SoftTypography variant="h6" fontWeight="bold"
                                                                    textTransform="capitalize">
                                                        {regDate}
                                                    </SoftTypography>
                                                </SoftBox>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>
            <SoftBox>
                <SoftBox mb={1}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} md={referrerList?.length !== 0 ? 6 : 12}
                              lg={referrerList?.length !== 0 ? 6 : 12}>
                            <Card>
                                <Grid container spacing={1} p={1} mt={1} mb={1}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <SoftBox
                                            border={`${borderWidth[1]} solid ${borderColor}`}
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            borderRadius="lg"
                                            p={0.5}
                                        >
                                            <SoftBox
                                                bgColor="info"
                                                width="1.25rem"
                                                height="1.25rem"
                                                borderRadius="sm"
                                                color="white"
                                                fontSize={size.lg}
                                                shadow="md"
                                                mr={1}
                                                mt={-0.7}
                                                variant="gradient"
                                            >
                                                <Icon>groups</Icon>
                                            </SoftBox>
                                            <SoftTypography
                                                variant="h6" gutterBottom fontWeight="bold"
                                            >
                                                결재자
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid container ml={2.5} mt={1}>
                                        {approverListShow()}
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        {referrerList?.length !== 0 ?
                            <Grid item xs={12} md={6} lg={6}>
                                <Card>
                                    <Grid container spacing={1} p={1} mt={1} mb={1}>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <SoftBox
                                                border={`${borderWidth[1]} solid ${borderColor}`}
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                borderRadius="lg"
                                                p={0.5}
                                            >
                                                <SoftBox
                                                    bgColor="info"
                                                    width="1.25rem"
                                                    height="1.25rem"
                                                    borderRadius="sm"
                                                    color="white"
                                                    fontSize={size.lg}
                                                    shadow="md"
                                                    mr={1}
                                                    mt={-0.7}
                                                    variant="gradient"
                                                >
                                                    <Icon>person_search</Icon>
                                                </SoftBox>
                                                <SoftTypography
                                                    variant="h6" gutterBottom fontWeight="bold"
                                                >
                                                    참조자
                                                </SoftTypography>
                                            </SoftBox>
                                        </Grid>
                                        <Grid container ml={2.5} mt={1}>
                                            {referrerListShow()}
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                            :
                            <></>
                        }
                    </Grid>
                </SoftBox>
            </SoftBox>
            {/* modal */}
            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}
                     onClick={modalClose}>
                    <PDFViewer style={{fontSize: "20px", width: "70%", height: "90%"}}>
                        <PDFDocView/>
                    </PDFViewer>
                </div>
            </Modal>
            <Footer/>
        </DashboardLayout>
    );
}

export default VacationView;
