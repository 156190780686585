import {useEffect, useMemo, useState} from "react";
import {useRecoilCallback, useRecoilState} from "recoil";
import {toMap} from "../../../../utils/ToMap";
import SoftInput from "../../../../components/SoftInput";
import Card from "@mui/material/Card";
import SoftBox from "../../../../components/SoftBox";
import Icon from "@mui/material/Icon";
import SoftTypography from "../../../../components/SoftTypography";
import typography from "../../../../assets/theme/base/typography";
import {vacationReasonValueAtom} from "../../../../atom/vacation/vacationReasonValueAtom";
import {vacationUseDayValueAtom} from "../../../../atom/vacation/vacationUseDayValueAtom";
import {vacationInsertAtom} from "../../../../atom/vacation/vacationInsertAtom";

/**
 * 휴가 사용 예정일 컴포넌트
 * @param defaultValue : Number-사용휴가일
 * @param editData : Number-문서수정시 사용예정 휴가일
 * @param error : Boolean-에러여부
 * @returns {JSX.Element}
 * @constructor
 */
const VacationUseDay = ({defaultValue, editData, error, isWithdraw}) => {
    // recoil에 전체 값 저장
    const [vacationInfo, setVacationInfo] = useRecoilState(vacationInsertAtom);

    // 휴가 사용예정일
    const [vacUseDay, setVacUseDay] = useRecoilState(vacationUseDayValueAtom);

    // 휴가 사용예정일 error
    const [useDayError, setUseDayError] = useState(true);

    // 최초 실행 여부
    const [isFirst, setIsFirst] = useState(false);

    // 휴가 사용예정일 input value 핸들링
    const vacUseDayHandle = (e) => {
        if (isNumeric(e.target.value) && e.target.value !== '') {
            setVacUseDay(e.target.value);
            setUseDayError(error);
        } else {
            setVacUseDay('');
            setUseDayError(true);
        }
    }

    // 숫자인지 검사
    function isNumeric(value) {
        return /^[1-9]\d*$/.test(value);
    }

    // 디폴트값 넘어올때 set
    useEffect(() => {
        if(editData !== undefined && !isFirst){
            setVacUseDay(editData);
            setUseDayError(false);
            setIsFirst(true);
        } else {
            if (defaultValue !== '' && defaultValue !== undefined) {
                setVacUseDay(defaultValue);
                setUseDayError(false);
            }
        }
    }, [defaultValue]);

    useEffect(()=>{
        setUseDayError(error);
    },[error])

    return (
        <>
            <SoftInput
                size="small"
                onChange={vacUseDayHandle}
                value={vacUseDay}
                error={useDayError}
                readOnly
            />
            &nbsp;일
        </>
    );
}
export default VacationUseDay;