import { useState, useEffect } from "react";
// react-router components
import {useLocation, Link, useNavigate} from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

// Soft UI Dashboard React examples
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
    navbar,
    navbarContainer,
    navbarRow,
    navbarIconButton,
    navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard React context
import {
    useSoftUIController,
    setTransparentNavbar,
    setMiniSidenav,
    setOpenConfigurator,
} from "context";

// Images
import team2 from "assets/images/team-2.jpg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import {useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";
import {loginUser} from "atom/member/loginUser";
import {loginState} from "atom/member/loginState";
import Swal from "sweetalert2";
import SoftAvatar from "components/SoftAvatar";
import {ACCESS_TOKEN, REFRESH_TOKEN} from "../../../config";
import {useQuery} from "react-query";
import moment from 'moment';
import {AccessTime} from "@mui/icons-material";
import Weather from "../../../layouts/dashboard/components/Weather";
import routes from "routes";
import DeleteCookie from "../../../api/auth/deleteCookie";
import {useMediaQuery} from "@mui/material";

function DashboardNavbar({absolute, light, isMini}) {
    const isXsScreen = useMediaQuery('(max-width:1300px)');

    const user = useRecoilValue(loginUser);
    const [navbarType, setNavbarType] = useState();
    const [controller, dispatch] = useSoftUIController();
    const {miniSidenav, transparentNavbar, fixedNavbar, openConfigurator} = controller;
    const [openMenu, setOpenMenu] = useState(false);
    const [color, setColor] = useState('success');
    const route = useLocation().pathname.split("/").slice(1);

    const mainRoute = "/" + route[0];
    const organeRoute = useLocation().pathname;

    const matchingName = [];
    const mainRouteName = [];
    const data = routes;

    data.forEach(item => {
        if (item.route && item.name) {
            if (item.route === organeRoute) {
                matchingName.push(item.name);
            } else if (item.route === mainRoute) {
                mainRouteName.push(item.name);
            } else if (item.route.split("/")[2] === ":id" && !isNaN(parseInt(route[1]))) {
                matchingName.push(route[1]);
            }
        }
    });


    useEffect(() => {
        // Setting the navbar type
        if (fixedNavbar) {
            setNavbarType("sticky");
        } else {
            setNavbarType("static");
        }

        // A function that sets the transparent state of the navbar.
        function handleTransparentNavbar() {
            setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
        }

        /**
         The event listener that's calling the handleTransparentNavbar function when
         scrolling the window.
         */
        window.addEventListener("scroll", handleTransparentNavbar);

        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar();

        // Remove event listener on cleanup
        return () => window.removeEventListener("scroll", handleTransparentNavbar);
    }, [dispatch, fixedNavbar]);

    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(false);
    const resetLoginUser = useResetRecoilState(loginUser)
    const resetLoginState = useResetRecoilState(loginState)
    const navigate = useNavigate();
    const setAuthorized = useSetRecoilState(loginState);
    const [deleteFlag, setDeleteFlag] = useState(false);

    // useEffect(() => {
    //     const tokenData = JSON.parse(localStorage.getItem('accessToken'));
    //     const issuedAt = moment(tokenData.createdAt);
    //     const expirationTime = moment(issuedAt).add(24, 'hours');
    //
    //     const timer = setInterval(() => {
    //         const remainingTime = moment.duration(expirationTime.diff(moment()));
    //         const hours = remainingTime.hours();
    //         const minutes = remainingTime.minutes();
    //         const seconds = remainingTime.seconds();
    //
    //         setCountdown(`${hours}시간 ${minutes}분 ${seconds}초`);
    //
    //
    //         if (remainingTime <= 0) {
    //             clearInterval(timer);
    //             navigate("/errorPage/401")
    //         } else if (minutes < 30) {
    //             setColor('error');
    //         }
    //     }, 1000);
    //
    //     return () => {
    //         clearInterval(timer);
    //     };
    // }, []);

    const {data: cookie, isLoading} = useQuery("deleteCookie", async () => await DeleteCookie(), {
        enabled: deleteFlag,
        onSuccess: (data) => {
            navigate("/", {state: 1});
            Swal.fire(
                '로그아웃 되었습니다.'
            )
            resetLoginUser();
            resetLoginState();
            setAuthorized(false);
            setDeleteFlag(false);
        },
        onError: (err) => {
            Swal.fire({
                title: '로그아웃이 정상적으로 진행되지 않았습니다. 잠시후 다시 시도해주세요.',
                icon: 'error',
                backdrop: false

            })
        }
    })

    const handleLogout = () => {
        Swal.fire({
            title: '로그아웃 하시겠습니까?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: '취소',
            confirmButtonText: '로그아웃',
            backdrop: false

        }).then((result) => {
            if (result.isConfirmed) {
                setDeleteFlag(true);
            }
        })
    }

//   const getTileName = () => {
// 	switch(route[route.length - 1]){
// 		case "welfarePoint":
// 			return "복지포인트";
// 		case "cost":
// 			return "비용처리";
// 		default:
// 			return route[route.length - 1];
// 	}
//   }

    // Render the notifications menu
    const renderMenu = () => (
        <Menu
            anchorEl={openMenu}
            anchorReference={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            sx={{mt: 2}}
        >
            <NotificationItem
                image={<img src={team2} alt="person"/>}
                title={["New message", "from Laur"]}
                date="13 minutes ago"
                onClick={handleCloseMenu}
            />
            <NotificationItem
                image={<img src={logoSpotify} alt="person"/>}
                title={["New album", "by Travis Scott"]}
                date="1 day"
                onClick={handleCloseMenu}
            />
            <NotificationItem
                color="secondary"
                image={
                    <Icon fontSize="small" sx={{color: ({palette: {white}}) => white.main}}>
                        payment
                    </Icon>
                }
                title={["", "Payment successfully completed"]}
                date="2 days"
                onClick={handleCloseMenu}
            />
        </Menu>
    );

    return (
        <AppBar
            position={absolute ? "absolute" : navbarType}
            color="inherit"
            sx={(theme) => navbar(theme, {transparentNavbar, absolute, light})}
        >
            <Toolbar sx={(theme) => navbarContainer(theme)}>
                <SoftBox color="inherit" mb={{xs: 1, md: 0}} sx={(theme) => navbarRow(theme, {isMini})}>
                    <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light}
                                 matchingNames={matchingName} mainRouteName={mainRouteName}/>
                </SoftBox>
                {isMini ? null : (
                    <SoftBox sx={(theme) => navbarRow(theme, {isMini})}>
                        <SoftBox display="flex" alignItems="center" justifyContent="flex-start">
                            <SoftAvatar
                                src={user.imageUrl}
                                alt="Avatar"
                                size="sm"
                                variant="rounded"
                            />
                            <SoftTypography
                                variant="button"
                                sx={{width: "max-content"}}
                                pl={0.2}
                            >
                                &nbsp;{user.name} 님 &nbsp;
                            </SoftTypography>
                            <Weather/>
                        </SoftBox>
                        <SoftBox display="flex" alignItems="center" justifyContent="flex-end"
                                 onClick={handleLogout}
                                 sx={{
                                     cursor: "pointer",
                                 }}
                        >
                            <Icon fontSize="large">
                                logout
                            </Icon>
                            {isXsScreen ? <></>:
                                <SoftTypography
                                    fontSize="large"
                                >
                                    로그아웃
                                </SoftTypography>
                            }
                        </SoftBox>
                        {/*<IconButton sx={navbarMobileMenu} size="large" onClick={handleLogout}>*/}
                        {/*    <Icon className={light ? "text-white" : "text-dark"}>*/}
                        {/*        logout*/}
                        {/*    </Icon>*/}
                        {/*</IconButton>*/}
                        {isXsScreen ?
                            <SoftBox display="flex" alignItems="center" justifyContent="flex-end"
                                              onClick={handleMiniSidenav}
                                              sx={{
                                                  cursor: "pointer",
                                              }}
                        >
                            <Icon fontSize="large">
                                {miniSidenav ? "menu_open" : "menu"}
                            </Icon>
                        </SoftBox>:<></>}

                        {/*<IconButton*/}
                        {/*    size="large"*/}
                        {/*    color="inherit"*/}
                        {/*    sx={navbarMobileMenu}*/}

                        {/*    onClick={handleMiniSidenav}*/}
                        {/*>*/}
                        {/*    <Icon className={light ? "text-white" : "text-dark"}>*/}
                        {/*        {miniSidenav ? "menu_open" : "menu"}*/}
                        {/*    </Icon>*/}
                        {/*</IconButton>*/}
                        {/* <IconButton
                                size="small"
                                color="inherit"
                                sx={navbarIconButton}
                                onClick={handleConfiguratorOpen}
                            >
                                <Icon>settings</Icon>
                            </IconButton> */}
                        {/*<IconButton*/}
                        {/*    size="small"*/}
                        {/*    color="inherit"*/}
                        {/*    sx={navbarIconButton}*/}
                        {/*    aria-controls="notification-menu"*/}
                        {/*    aria-haspopup="true"*/}
                        {/*    variant="contained"*/}
                        {/*    onClick={handleOpenMenu}*/}
                        {/*>*/}
                        {/*    <Icon className={light ? "text-white" : "text-dark"}>notifications</Icon>*/}
                        {/*</IconButton>*/}
                        {renderMenu()}
                    </SoftBox>
                )}
            </Toolbar>
        </AppBar>
    );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
    absolute: false,
    light: false,
    isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
    absolute: PropTypes.bool,
    light: PropTypes.bool,
    isMini: PropTypes.bool,
};

export default DashboardNavbar;
