import { Modal, Pagination } from '@mui/material';
import allCommonCodeMap from 'api/commonCode/allCommonCodeMap';
import costAdminLists from 'api/cost/costAdminLists';
import { loginUser } from 'atom/member/loginUser';
import SoftBox from 'components/SoftBox';
import CostReceptionTable from '../../../../examples/Tables/CostTable/CostReceptionTable';
import React, { useEffect, useState } from 'react'
import { useQueries, useQuery } from 'react-query';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { Document, Page, Text, PDFViewer, StyleSheet, View, Font, Image } from '@react-pdf/renderer';
import malgun from "../../../../assets/font/malgun.ttf";
import { CostAdminPageNumber } from 'atom/cost/CostWriteDetail';
import SoftAvatar from "../../../../components/SoftAvatar";
import SoftTypography from "../../../../components/SoftTypography";
import userAll from "../../../../api/user/userAll";
import SoftBadge from "../../../../components/SoftBadge";
import { CostAdminMonth } from 'atom/cost/CostWriteDetail'; // 241031 주석 -> 주석 해제 - 이준복

const CostPaymentComplete = (props) => {
    const user = useRecoilValue(loginUser);
    const [page, setPage] = useRecoilState(CostAdminPageNumber);
    const [size, setSize] = useState(10);
    const [modalOpen, setModalOpen] = useState(false);
    const [commonCodeMap, setCommonCodeMap] = useState(new Map()); // 공용코드 맵

    const modalClose = (event) => {
        if (event.target === event.currentTarget) {
            props.setModal(false);
        }
    };
    const month = useRecoilValue(CostAdminMonth);
    let costAdminList;
    let commonCode;
    let amountSum;
    useEffect(()=>{ // 241031 주석 -> 주석 해제 - 이준복
        setPage(1)
    },[month])

    const columns = [
        { key: "costNo", name: "costNo", align: "center", value:"번호",width: "10%"},
        { key: "costWriter", name: "costWriter", align: "center" , value:"이름", width: "20%"},
        { key: "costInfo", name: "costInfo", align: "center", value:"정보", width: "20%"},
        { key: "costAmount", name: "costAmount", align: "center" , value:"금액", width: "20%"},
        { key: "regDate", name: "regDate", align: "center" , value:"작성일", width: "20%"},
        { key: "approved", name: "approved", align: "center" , value:"결재상태", width: "10%"}
    ]
    const rows = []
    let quotient = 1;
    const queries = useQuery(['costAdminLists', props.month, page, size, props.year, props.approvedCodeList, props.selectMember], () => costAdminLists(props.month, page, size, props.year, props.approvedCodeList, props.selectMember));
    const allCommonCodesQuery = useQueries([
        {
            queryKey: ["allCommonCodeMap"],
            queryFn: () => allCommonCodeMap(),
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            },
            onSuccess: (result) => {
                setCommonCodeMap(result.data);
            }
        },
    ]);
    if (queries.error) {
        setPage(1)
    }

    /**
     * 네임 카드
     * @param name:String-이름
     * @param rank:String-랭크공용코드
     * @param team:String-팀공용코드
     * @param img:String-구글이미지
     * @returns {JSX.Element}
     */
    const nameCard = (name, rank, team, img) => {
        return (
            <SoftBox display="flex">
                <SoftBox>
                    <SoftAvatar
                        src={img}
                        alt="profile-image"
                        variant="rounded"
                        size="md"
                        shadow="sm"
                    />
                </SoftBox>
                <SoftBox ml={1}>
                    <SoftBox mt={0.5} lineHeight={1}>
                        <SoftTypography variant="h6" fontWeight="bold">
                            {name}
                        </SoftTypography>
                        <SoftTypography variant="button" color="text" fontWeight="medium">
                            {commonCodeMap[team]?.info} / {commonCodeMap[rank]?.info}
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
            </SoftBox>
        );
    }

    if (queries.isSuccess && allCommonCodesQuery[0].isSuccess) {
        // 비용처리 정보
        const costList = queries.data.data;

        // costAdminList.push(costList)
        costAdminList = costList
        // 공용코드 리스트
        const codeMap = allCommonCodesQuery[0].data.data;
        commonCode = codeMap;
        if (costList.length !== 0) {
            quotient = Math.ceil(costList[0]?.cost_doc_count / size)
        } else if (costList.length === 0) {
            setPage(1)
        }
        for (let i = 0; i < costList?.length; i++) {
            const approvedInfo = commonCodeMap[costList[i]?.cost_approved]?.info;
            const costRowNo = costList[i]?.cost_no;
            const costCategory = codeMap[costList[i]?.cost_category].info + " 등 " + costList[i]?.cost_history + " 건";
            const costWriter = nameCard(costList[i]?.mem_name, costList[i]?.cost_rank, costList[i]?.cost_team, costList[i]?.imageUrl);
                // "[" + codeMap[costList[i]?.cost_team].info + "] " + costList[i]?.mem_name + " " + codeMap[costList[i]?.cost_rank].info;
            const date = costList[i].cost_date;

            let amount = 0; // 총합계 금액
            let myAmount = 0; // 개인비용 소계
            let comAmount = 0; // 법인비용 소계

            if (costList[i]?.detail_otherList?.length > 0) {
                for (let j = 0; j < costList[i].detail_otherList.length; j++) {
                    amount += costList[i].detail_otherList[j].other_amount
                    if(costList[i].detail_otherList[j].other_paytype === "PM002")  {
                        comAmount += costList[i].detail_otherList[j].other_amount;
                    } else {
                        myAmount += costList[i].detail_otherList[j].other_amount;
                    }
                }
            } else if (costList[i]?.detail_trafficList?.length > 0) {
                for (let j = 0; j < costList[i].detail_trafficList.length; j++) {
                    amount += costList[i].detail_trafficList[j].traffic_amount
                    if(costList[i].detail_trafficList[j].other_paytype === "PM002")  {
                        comAmount += costList[i].detail_trafficList[j].traffic_amount;
                    } else {
                        myAmount += costList[i].detail_trafficList[j].traffic_amount;
                    }
                }
            } else if (costList[i]?.fuelList?.length > 0) {
                for (let j = 0; j < costList[i].fuelList.length; j++) {
                    amount += costList[i].fuelList[j].fuel_amount
                    if(costList[i].fuelList[j].other_paytype === "PM002")  {
                        comAmount += costList[i].fuelList[j].fuel_amount;
                    } else {
                        myAmount += costList[i].fuelList[j].fuel_amount;
                    }
                }
            }

            const costRows = {
                cost_no: costRowNo,
                costInfo: costCategory,
                costWriter: costWriter,
                costAmount: amount.toLocaleString(),
                myCardSumAmount: myAmount.toLocaleString(),
                comCardSumAmount: comAmount.toLocaleString(),
                regDate: date,
                approved:(
                    <SoftBadge
                        variant="contained"
                        badgeContent={approvedInfo}
                        color={approvedInfo === "결재완료" ? "success" : approvedInfo === "반려" ? "error" : "info"}
                        size="lm" container
                        sx={{display: "inline-block", width: "max-content"}}
                    />
                )
            }
            rows.push(costRows)
        }

    }

    // page 변경
    function handlePageChange(event, value) {
        setPage(value)
    }
    Font.register({
        family: 'malgun',
        src: malgun,
    });
    let benefitDetailRow = [
        { key: "번호", width: '7%' },
        { key: "일자", width: '8.5%' },
        { key: "용도", width: '16%' },
        { key: "내 역", width: '35%' },
        { key: "인원", width: '8.5%' },
        { key: "금액", width: '13%' },
        { key: "비고", width: '12%' },
    ]

    let publicTransportaionDetailRow = [
        { key: "번호", width: '7%' },
        { key: "일자", width: '8.5%' },
        { key: "방문장소", width: "22%" },
        { key: "방문목적", width: "22%" },
        { key: "교통수단", width: "16.5%" },
        { key: "금액", width: '13%' },
        { key: "비고", width: '11%' },
    ]
    let fuelDetailRow = [
        { key: "번호", width: '6%' },
        { key: "일자", width: '8%' },
        { key: "용도", width: '17%' },
        { key: "차량번호", width: "14%" },
        { key: "출발지", width: "11%" },
        { key: "도착지", width: "11%" },
        { key: "운행거리", width: "10%" },
        { key: "금액", width: '13%' },
        { key: "비고", width: '10%' },
    ]
    const pdfStyles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            paddingTop: 30,
            paddingBottom: 30,
            paddingHorizontal: 18,
        },
        header: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        tableColHeaderText: {
            fontFamily: 'malgun',
            textAlign: 'center',
            fontSize: 13,
        },
        tableRowItem: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
    });
    let sumAmount;

    const PDFCostView = () => {
        return (
            <>
                <Document>
                    {costAdminList.map((list, idx) => (
                        <Page size="A4" style={pdfStyles.page}>
                            <View style={{ marginBottom: 10 }}>
                                <Text style={[pdfStyles.tableColHeaderText, { fontSize: 17 }]}>
                                    비용처리 명세서 ({commonCode[list.cost_category].info})
                                </Text>
                            </View>
                            <View style={[pdfStyles.header, { marginBottom: 4 }]}>
                                <Text style={[pdfStyles.tableColHeaderText, { marginLeft: 8 }]}>
                                    성명 : {list.mem_name}
                                </Text>
                                <Text style={[pdfStyles.tableColHeaderText, { marginRight: 8 }]}>{list.cost_date}</Text>
                            </View>
                            <View style={{ alignItems: 'center', width: '100%' }}>
                                <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "99%", borderRight: '1px solid black', borderLeft: '1px solid black', }}>
                                    <View style={{ width: '98%', borderTop: '1px solid black', borderBottom: '1px solid black', }}>
                                        <View style={{ flexDirection: 'row' }}>
                                            {(list.cost_category === 'C000') && benefitDetailRow.map((item, idx) =>
                                                <View key={idx} style={{ width: item.width, paddingBottom: 3, paddingTop: 1, backgroundColor: '#e8fcf2', borderRight: idx === benefitDetailRow.length - 1 ? 'none' : '1px solid black' }}>
                                                    <Text style={pdfStyles.tableColHeaderText}>{item.key}</Text>
                                                </View>
                                            )}
                                            {(list.cost_category === 'C001') && benefitDetailRow.map((item, idx) =>
                                                <View key={idx} style={{ width: item.width, paddingBottom: 3, paddingTop: 1, backgroundColor: '#e8fcf2', borderRight: idx === benefitDetailRow.length - 1 ? 'none' : '1px solid black' }}>
                                                    <Text style={pdfStyles.tableColHeaderText}>{item.key}</Text>
                                                </View>
                                            )}
                                            {(list.cost_category === 'C002') && publicTransportaionDetailRow.map((item, idx) =>
                                                <View key={idx} style={{ width: item.width, paddingBottom: 3, paddingTop: 1, backgroundColor: '#e8fcf2', borderRight: idx === publicTransportaionDetailRow.length - 1 ? 'none' : '1px solid black' }}>
                                                    <Text style={pdfStyles.tableColHeaderText}>{item.key}</Text>
                                                </View>
                                            )}
                                            {(list.cost_category === 'C003') && fuelDetailRow.map((item, idx) =>
                                                <View key={idx} style={{ width: item.width, paddingBottom: 3, paddingTop: 1, backgroundColor: '#e8fcf2', borderRight: idx === fuelDetailRow.length - 1 ? 'none' : '1px solid black' }}>
                                                    <Text style={pdfStyles.tableColHeaderText}>{item.key}</Text>
                                                </View>
                                            )}
                                        </View>
                                        {list.detail_otherList?.length !== 0 ? list.detail_otherList.map((item, idx) =>
                                            <View style={{ flexDirection: 'row', borderTop: '1px solid black', flexWrap: 'wrap' }} key={idx}>
                                                <View style={{ width: benefitDetailRow[0].width, borderRight: '1px solid black' }}>
                                                    <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 }]}>{idx + 1}</Text>
                                                </View>
                                                <View style={{ width: benefitDetailRow[1].width, borderRight: '1px solid black' }}>
                                                    <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2, textAlign : 'center',  }]}>{new Date(item.other_date).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' })}</Text>
                                                </View>
                                                <View style={{ width: benefitDetailRow[2].width, borderRight: '1px solid black' }}>
                                                    <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2, textAlign : 'left' , paddingLeft : 1 }]}>{commonCode[item.other_category].info}</Text>
                                                </View>
                                                <View style={{ width: benefitDetailRow[3].width, borderRight: '1px solid black', flexWrap: 'wrap' }}>
                                                    <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2, flexWrap: 'wrap' ,textAlign : 'left' , paddingLeft : 3  }]}>{item.other_contents}</Text>
                                                </View>
                                                <View style={{ width: benefitDetailRow[4].width, borderRight: '1px solid black' }}>
                                                    <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2, textAlign : 'center', paddingRight : 3 }]}>{item.other_personnel}</Text>
                                                </View>
                                                <View style={{ width: benefitDetailRow[5].width, borderRight: '1px solid black' }}>
                                                    <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2, textAlign : 'right', paddingRight : 3  }]}>{item.other_amount.toLocaleString()}</Text>
                                                </View>
                                                <View style={{ width: benefitDetailRow[6].width }}>
                                                    <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2, textAlign : 'center' }]}>{commonCodeMap[item?.other_paytype]?.info}</Text>
                                                </View>
                                            </View>
                                        ) : null}
                                        {list.detail_trafficList?.length !== 0 ? list.detail_trafficList.map((item, idx) =>
                                            <View style={{ flexDirection: 'row', borderTop: '1px solid black' }} key={idx}>
                                                <View style={{ width: publicTransportaionDetailRow[0].width, borderRight: '1px solid black' }}>
                                                    <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 }]}>{idx + 1}</Text>
                                                </View>
                                                <View style={{ width: publicTransportaionDetailRow[1].width, borderRight: '1px solid black' }}>
                                                    <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'center'}]}>{new Date(item.traffic_date).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' })}</Text>
                                                </View>
                                                <View style={{ width: publicTransportaionDetailRow[2].width, borderRight: '1px solid black' }}>
                                                    <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'left', paddingLeft :2}]}>{item.traffic_location}</Text>
                                                </View>
                                                <View style={{ width: publicTransportaionDetailRow[3].width, borderRight: '1px solid black' }}>
                                                    <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'left', paddingLeft :2}]}>{item.traffic_purpose}</Text>
                                                </View>
                                                <View style={{ width: publicTransportaionDetailRow[4].width, borderRight: '1px solid black' }}>
                                                    <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'left', paddingLeft :2}]}>{item.traffic_method}</Text>
                                                </View>
                                                <View style={{ width: publicTransportaionDetailRow[5].width, borderRight: '1px solid black' }}>
                                                    <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'right' }]}>{item.traffic_amount.toLocaleString()} </Text>
                                                </View>
                                                <View style={{ width: publicTransportaionDetailRow[6].width }}>
                                                    <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'center'}]}>{commonCodeMap["PM001"]?.info}</Text>
                                                </View>
                                            </View>
                                        ) : null}
                                        {list.fuelList?.length !== 0 ? list.fuelList.map((item, idx) =>
                                            <View style={{ flexDirection: 'row', borderTop: '1px solid black' }} key={idx}>
                                                <View style={{ width: fuelDetailRow[0].width, borderRight: '1px solid black' }}>
                                                    <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 }]}>{idx + 1}</Text>
                                                </View>
                                                <View style={{ width: fuelDetailRow[1].width, borderRight: '1px solid black' }}>
                                                    <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'center' }]}>{new Date(item.fuel_date).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' })}</Text>
                                                </View>
                                                <View style={{ width: fuelDetailRow[2].width, borderRight: '1px solid black' }}>
                                                    <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'left' , paddingLeft : 2 }]}>{item.fuel_category}</Text>
                                                </View>
                                                <View style={{ width: fuelDetailRow[3].width, borderRight: '1px solid black' }}>
                                                    <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'left' , paddingLeft : 2 }]}>{item.fuel_carnum}</Text>
                                                </View>
                                                <View style={{ width: fuelDetailRow[4].width, borderRight: '1px solid black' }}>
                                                    <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'left' , paddingLeft : 2 }]}>{item.fuel_start}</Text>
                                                </View>
                                                <View style={{ width: fuelDetailRow[5].width, borderRight: '1px solid black' }}>
                                                    <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'left' , paddingLeft : 2}]}>{item.fuel_end}</Text>
                                                </View>
                                                <View style={{ width: fuelDetailRow[6].width, borderRight: '1px solid black' }}>
                                                    <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'right' , paddingRight : 1}]}>{item.fuel_distance}</Text>
                                                </View>
                                                <View style={{ width: fuelDetailRow[7].width, borderRight: '1px solid black' }}>
                                                    <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'right' , paddingRight : 3 }]}>{item.fuel_amount.toLocaleString()}</Text>
                                                </View>
                                                <View style={{ width: fuelDetailRow[8].width }}>
                                                    <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'right' , paddingRight : 3 }]}>{commonCodeMap[item?.fuel_paytype]?.info}</Text>
                                                </View>
                                            </View>
                                        ) : null}
                                    </View>
                                    <View style={{ width: '98%', borderTop: '1px solid black', borderBottom: '1px solid black' }}>
                                        {list.fuelList?.length !== 0 ?
                                            <>
                                                <View style={{ flexDirection: 'row' , borderBottom: '1px solid black' }}>
                                                    <View style={{ width: '80%', borderRightWidth: 1, borderRightColor: 'black' }}>
                                                        <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 }]}>개 인 비 용</Text>
                                                    </View>
                                                    <View style={{ width: '20%', }}>
                                                        <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 ,textAlign : 'right', paddingRight : 3 }]}>{rows[idx]?.myCardSumAmount}</Text>
                                                    </View>
                                                </View>
                                                <View style={{ flexDirection: 'row' , borderBottom: '1px solid black' }}>
                                                    <View style={{ width: '80%', borderRightWidth: 1, borderRightColor: 'black' }}>
                                                        <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 }]}>법 인 비 용</Text>
                                                    </View>
                                                    <View style={{ width: '20%', }}>
                                                        <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 ,textAlign : 'right', paddingRight : 3 }]}>{rows[idx]?.comCardSumAmount}</Text>
                                                    </View>
                                                </View>
                                                <View style={{ flexDirection: 'row' }}>
                                                    <View style={{ width: '80%', borderRight: '1px solid black' }}>
                                                        <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 }]}>사  용  총  액</Text>
                                                    </View>
                                                    <View style={{ width: '20%', }}>
                                                        <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 ,textAlign : 'right', paddingRight : 3 }]}>{rows[idx]?.costAmount}</Text>
                                                    </View>
                                                </View>
                                            </>
                                            :
                                            <>
                                                <View style={{ flexDirection: 'row' , borderBottom: '1px solid black' }}>
                                                    <View style={{ width: '80%', borderRightWidth: 1, borderRightColor: 'black' }}>
                                                        <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 }]}>개 인 비 용</Text>
                                                    </View>
                                                    <View style={{ width: '20%', }}>
                                                        <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 ,textAlign : 'right', paddingRight : 3 }]}>{rows[idx]?.myCardSumAmount}</Text>
                                                    </View>
                                                </View>
                                                <View style={{ flexDirection: 'row' , borderBottom: '1px solid black' }}>
                                                    <View style={{ width: '80%', borderRightWidth: 1, borderRightColor: 'black' }}>
                                                        <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 }]}>법 인 비 용</Text>
                                                    </View>
                                                    <View style={{ width: '20%', }}>
                                                        <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 ,textAlign : 'right', paddingRight : 3 }]}>{rows[idx]?.comCardSumAmount}</Text>
                                                    </View>
                                                </View>
                                                <View style={{ flexDirection: 'row' }}>
                                                    <View style={{ width: '80%', borderRight: '1px solid black' }}>
                                                        <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 }]}>사  용  총  액</Text>
                                                    </View>
                                                    <View style={{ width: '20%', }}>
                                                        <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 ,textAlign : 'right', paddingRight : 3 }]}>{rows[idx]?.costAmount}</Text>
                                                    </View>
                                                </View>
                                            </>
                                        }
                                        <View style={{ flexDirection: 'row', borderTopWidth: 1, borderTopColor: 'black' }}>
                                            <View style={{ width: '50%' }}>
                                                <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 5, paddingTop: 5 }]}>작 성 자 : {list.mem_name}</Text>
                                            </View>
                                            <View style={{ width: '25%' }}>
                                                <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 5, paddingTop: 5 }]}>서 명 : </Text>
                                            </View>
                                            <View style={{ width: '25%', }}>
                                                <Image src={list.signature} style={{ width: 28, height: 28, borderRadius: 5 }} />
                                            </View>
                                        </View>
                                        {list.approvalList.length !== 0 && list.approvalList.map((item, idx) =>
                                            <View style={{ flexDirection: 'row', borderTopWidth: 1, borderTopColor: 'black' }}>
                                                <View style={{ width: '50%' }}>
                                                    <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 5, paddingTop: 5 }]}>{idx + 1}차 결재자 : {item.approver_name}</Text>
                                                </View>
                                                <View style={{ width: '25%' }}>
                                                    <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 5, paddingTop: 5 }]}>서 명 : </Text>
                                                </View>
                                                <View style={{ width: '25%', }}>
                                                    {item.approver_do === 1 &&
                                                        <Image src={item.approver_signature} style={{ width: 28, height: 28, borderRadius: 5 }} />
                                                    }
                                                </View>
                                            </View>
                                        )}
                                    </View>
                                </View>
                            </View>
                        </Page>
                    ))}
                </Document>
            </>
        );
    };


    return (
        <>
            <CostReceptionTable columns={columns} rows={rows} type={"admin"} />
            <SoftBox display='flex' justifyContent='center'p={1}>
                <Pagination
                    count={quotient}
                    page={page}
                    onChange={handlePageChange}
                    showFirstButton
                    showLastButton
                />
            </SoftBox>
            <Modal open={props.modal} onClose={() => props.setModal(false)}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}
                    onClick={modalClose}>
                    <PDFViewer style={{ fontSize: "20px", width: "70%", height: "90%" }}>
                        <PDFCostView />
                    </PDFViewer>
                </div>
            </Modal>
        </>
    )
}

export default CostPaymentComplete