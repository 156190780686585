import {useEffect, useState} from "react";
import SoftInput from "../../../../components/SoftInput";
import {PhoneNumCheck} from "../../../../utils/PhoneNumCheck";

/**
 * 전화번호 값을 받아서 정규식 처리해서 보내는 함수
 * @param defaultValue : String 사용자 연락처
 * @param setTelValue : fucntion 사용자 값 세팅
 * @returns {JSX.Element}
 * @constructor
 */

const ProfilePhoneNum = ({defaultValue, setTelValue}) => {

    // 입력값
    const [inputValue, setInputValue] = useState("");
    // 연락처 미입력 에러
    const [telError, setTelError] = useState(true);

    // 기본값 저장
    useEffect(()=>{
        if(defaultValue !== '' && defaultValue !== undefined ) {
            setInputValue(defaultValue);
            setTelError(false);
            // 값 저장
            setTelValue(defaultValue);
        }
    },[defaultValue, setTelValue]);

    // 전화번호 정규식 처리
    const inputChangeHandle = (e) => {
        const regex = /^[0-9\b -]{0,13}$/;
        const changeValue = e.target.value

        if(changeValue !== ''){
            // 숫자 또는 하이픈일때
            if (regex.test(changeValue)) {
                setInputValue(changeValue);
                // 함수 설명 참조
                if(PhoneNumCheck(changeValue)){
                    setTelError(false);
                    setTelValue(changeValue);
                } else {
                    setTelError(true);
                    setTelValue('');
                }
            }
        // ''이 아닐때
        } else {
            setInputValue(changeValue);
            setTelError(true);
            setTelValue(changeValue);
        }
    }

    // 전화번호 하이픈 넣기
    useEffect(() => {
        // 10글자일 떄
        if (inputValue.length === 10) {
            setInputValue(inputValue.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'));
        }
        // 13글자일때
        if (inputValue.length === 13) {
            setInputValue(inputValue.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
            // 전화번호 패턴과 맞으면 저장
            if(PhoneNumCheck([...inputValue].join(''))) {
                // 값 저장
                setTelError(false);
                setTelValue([...inputValue].join(''));
            }
        }
        // 13글자가 넘어갈때
        if (inputValue.length > 13){
            const inputLength = inputValue.length - 13;
            setInputValue(inputValue.slice(0, -inputLength));
        }
    }, [inputValue, setTelValue]);

    return (
        <SoftInput
            type="tel"
            size="small"
            value={inputValue}
            onChange={inputChangeHandle}
            error={telError}
        />
    );
}
export default ProfilePhoneNum;