import {useEffect, useMemo, useState} from "react";
import {useRecoilCallback, useRecoilState} from "recoil";
import {toMap} from "../../../../utils/ToMap";
import SoftInput from "../../../../components/SoftInput";
import Card from "@mui/material/Card";
import SoftBox from "../../../../components/SoftBox";
import Icon from "@mui/material/Icon";
import SoftTypography from "../../../../components/SoftTypography";
import typography from "../../../../assets/theme/base/typography";
import {vacationReasonValueAtom} from "../../../../atom/vacation/vacationReasonValueAtom";

/**
 * 휴가 사유 입력 컴포넌트
 * @param row : String 라벨이름
 * @param defaultValue : String 휴가 연락처
 * @returns {JSX.Element}
 * @constructor
 */
const VacationReason = ({row, defaultValue, isWithdraw}) => {

    const {size} = typography;

    // 휴가 목적
    const [vacReason, setVacReason] = useRecoilState(vacationReasonValueAtom);

    // 휴가 사유 미입력 error
    const [reasonError, setReasonError] = useState(true);

    // 휴가 목적 input value 핸들링
    const vacReasonHandle = (e) => {
        // if (isWithdraw) {
        //     if (defaultValue.length+13>= e.target.value.length) setVacReason("본문 : " + defaultValue + "\n취소 사유 : ");
        //     else setVacReason(e.target.value);
        // } else {
        //     setVacReason(e.target.value);
        // }
    
        if (e.target.value !== '') {
            setReasonError(false);
        } else {
            setReasonError(true);
        }
        setVacReason(e.target.value);
    }

    // 디폴트값 넘어올때 set
    useEffect(()=>{
        if(defaultValue !== '' && defaultValue !== undefined ) {
            // isWithdraw ? setVacReason("본문 : " + defaultValue + "\n취소 사유 : ") : setVacReason(defaultValue);
            setVacReason(defaultValue);
            setReasonError(false);
        }
    },[defaultValue]);

    return (
        <>
            <Card>
                <SoftBox p={1}>
                    <SoftBox display="flex" alignItems="center">
                        <SoftBox
                            bgColor="info"
                            width="1.25rem"
                            height="1.25rem"
                            borderRadius="sm"
                            color="white"
                            fontSize={size.lg}
                            shadow="md"
                            mr={1}
                            mt={-0.7}
                            variant="gradient"
                        >
                            <Icon>edit_note</Icon>
                        </SoftBox>
                        <SoftTypography
                            variant="h6" gutterBottom fontWeight="bold"
                            color={isWithdraw ? "primary" : "dark"}
                        >
                            {
                                isWithdraw ? '취소 사유 (※본문의 내용을 유지한 채 입력해주세요※)' : "휴가 사유"
                            }
                        </SoftTypography>
                    </SoftBox>
                    <SoftInput
                        multiline
                        rows={row}
                        onChange={vacReasonHandle}
                        value={vacReason}
                        error={reasonError}
                    />
                </SoftBox>
            </Card>
        </>
    );
}
export default VacationReason;