export const myIpAddress =
    window.location.hostname === "nasdoc.nineonesoft.com"
        ? "https://nasdoc.nineonesoft.com/nasdoc"
        : window.location.hostname === "ojt.nineonesoft.com"
            ? "https://ojt.nineonesoft.com/nasdoc"
            : "http://localhost:8080";

export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';

export const OAUTH2_REDIRECT_URI =
    window.location.hostname === "localhost"
        ? 'http://localhost:3000/login/success'
        : window.location.protocol+'//'+window.location.hostname+'/login/success';

export const GOOGLE_AUTH_URL = myIpAddress + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL = myIpAddress + '/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const GITHUB_AUTH_URL = myIpAddress + '/oauth2/authorize/github?redirect_uri=' + OAUTH2_REDIRECT_URI;