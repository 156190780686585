import {atom} from "recoil";

/**
 * 접속한 로그인 유저 정보
 * @type {RecoilState<{team, role, rank, id, email, name, joinDate ...etc}>}
 */

export const loginUser = atom({
    key: "loginUser",
    default: {
        delYn: '',
        email : '',
        emailVerified : false,
        id : "",
        imageUrl : '',
        joinDate : '',
        name : '',
        provider : '',
        providerId : '',
        rank : '',
        team : '',
        signature : '',
        tel: ''
    },
})