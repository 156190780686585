import React ,  { useEffect, useState  }from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";


// @mui material components
import { Table as MuiTable, TableCell } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
// Soft UI Dashboard React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import { AmountFormat } from "utils/AmountFormat";
import {  useRecoilState } from "recoil";
import { useQuery } from "react-query";
import AdminWPListTableUser from "./AdminWPListTableUser";
import allCommonCodeMap from "api/commonCode/allCommonCodeMap";
import { wpCsvDownloadRow } from "atom/wp/wpCsvDownloadRow";
import { myIpAddress } from "config";
import { customAxios } from "../../../../axios/CustomAxios";
import SoftProgress from "components/SoftProgress";
import { CSVLink } from "react-csv";
import SoftButton from "components/SoftButton";



function MemberWpUsageStatusList() {
    //const user = useRecoilValue(loginUser);
	const { light } = colors;
	const { size, } = typography;
	const { borderWidth } = borders;

	/** 오늘 일 */
	const todayDate = new Date();
	const year = todayDate.getFullYear().toString();

	const [ csvDownData , setCsvDownData ] = useRecoilState(wpCsvDownloadRow);

	const columns = [
		{ key: "mem_name" , name: "사원", align: "left" }, 
		{ key: "usedYearTotalPoint" , name: "사용 금액", align: "center" },
		{ key: "currentUsage" , name: "사용 현황", align: "center" },
		{ key: "usablePoint" , name: "사용 가능 금액", align: "center" },
		// { key: "remaining_point" , name: "남은 금액", align: "center" },
		{ key: "year_point" , name: "이번 연도 금액", align: "center" },
	]
	
	const excelColumns = [
		{ key: "mem_name" , name: "사원", align: "left" }, 
		{ key: "usedYearTotalPoint" , name: "사용 금액", align: "center" },
		{ key: "usablePoint" , name: "사용 가능 금액", align: "center" },
		// { key: "remaining_point" , name: "남은 금액", align: "center" },
		{ key: "year_point" , name: "이번 연도 금액", align: "center" },
	]

	const { data:info } = useQuery('allCommonCodeMap', allCommonCodeMap, {
		select : (data) => {
			return data.data;
		}
	});

	/** */
	const rows = []
	
	const memberWpList = () => {
		return customAxios.post(myIpAddress+'/wp/memberWpList', null, {
			params: { year: year }
		});
	}
	const { data } = useQuery(['memberWpList' ], () => memberWpList() , {
		select : (data) => {
			return data.data;
		}
	});

	
	
	/** 엑셀 다운로드 칼럼 및 데이터 */
	let csvRow; 

    if( data && info ) {
		//console.log("row :::: ", info)
		const memPointList = data;
        memPointList.map((list ,index) =>{
			const amount = (parseInt(list["usedYearTotalPoint"]) / parseInt(list["year_point"])) * 100;
			if(list["mem_no"] === 1) {
				console.log(amount)
			}
            let row ={}
				row["mem_no"] = list["mem_no"]
				row["imageUrl"]	= list["imageUrl"]
				row["mem_name"] = list["mem_name"]
				row["mem_team"]	= list["mem_team"] ? info[list["mem_team"]].info : "" ;
				row["mem_rank"]	= list["mem_rank"] ? info[list["mem_rank"]].info : "" ;
				row["usedYearTotalPoint"]	= AmountFormat(parseInt(list.usedYearTotalPoint)) + " 원" 
				row["currentUsage"]	= amount >= 1 || amount === 0 ? parseInt(amount) : amount.toFixed(2);
				//row["remaining_point"] = AmountFormat( parseInt(list["year_point"]) - parseInt(list["usedYearTotalPoint"]) ) + " 원"
				row["usablePoint"] = AmountFormat(parseInt(list.usablePoint + list.futurePoint)) + " 원"
				row["year_point"] = AmountFormat(parseInt(list["year_point"])) + " 원"
            rows.push(row)
        })
		csvRow = rows
    }

	// CSV 파일로 저장할 데이터 생성하기
	const [csvData, setCsvData ] = useState([]);
	const [headers, setHeaders ] = useState([]);
	const [filename , setFilename] = useState(`복지포인트 현황.csv`);

	const handleDownloadCsv = () => {
		const updatedFilename  = `복지포인트 현황.csv`;

		const header = excelColumns.map((column) => ({
			label: column.name.toUpperCase(),
			key: column.name,
		}));
		
		// 데이터(excelRows) 작성하기
		const data = csvDownData.map((row) => {
			const rowData = {};
			excelColumns.forEach((column) => {
				const value = row[column.key] || '';
				if (column.key === 'usedYearTotalPoint' || column.key === 'usablePoint' || column.key === 'year_point' ) {
					rowData[column.name] = value.replace(/(\s+원)/g, '');
				} else {
					rowData[column.name] = value;
				}
			});
			return rowData;
		});
		setFilename(updatedFilename);
		setCsvData(data)
		setHeaders(header)
	};


	useEffect(() =>{
		if(csvRow !== undefined){
			setCsvDownData(csvRow)
		}
	},[data])


	const renderColumns = columns.map(({ name, align, width }, key) => {
		let pl;
		let pr;
		if (key === 0) {pl = 3;pr = 3;
		} else if (key === columns.length - 1) {pl = 3;pr = 3;
		} else {pl = 1;pr = 1;
		}

		return (
			<SoftBox
				key={name} component="th" width={width || "auto"} pt={1.5} pb={1.25}  
				textAlign="center" opacity={0.7} borderBottom={`${borderWidth[1]} solid ${light.main}`}
			>
				<SoftTypography
					fontSize={size.xs}
					fontWeight="bold"
					color="secondary"
					sx={{ display: "inline-block", width: "max-content" , }}
					>
					{name.toUpperCase()}
				</SoftTypography>
			</SoftBox>
		);
	});

  	const renderRows = rows.map((row, key) => {
		const rowKey = `row-${key}`;


		const tableRow = columns.map(({ name, key }, index) => {
			let data = row[key]

			if(key === "mem_name")	{
				return (
					<SoftBox component="td" width="180px" pt={0.5} pl={3}
						key={key+"_"+index}
						borderBottom={`${borderWidth[1]} solid ${light.main}`}
						>
						<AdminWPListTableUser 
							name={row["mem_name"]} 
							rank={row["mem_rank"]} 
							team={row["mem_team"]} 
							imageUrl={row["imageUrl"]} 
						/>
					</SoftBox>
				)
			} else if (key === "currentUsage"){
				//.log(key)
				return (
					<SoftBox
						key={key+"_"+index} component="td" p={2} textAlign={"center"}
						borderBottom={`${borderWidth[1]} solid ${light.main}`}
						>
							<SoftProgress value={data} color="info" variant="gradient"
							label={true}/>
					</SoftBox>
				)
			} else {
				return (
					<SoftBox
						key={key+"_"+index} component="td" p={2} textAlign={"center"}
						borderBottom={`${borderWidth[1]} solid ${light.main}`}
						>
						<SoftTypography
							variant="button" fontWeight={ key === "wp_totalpoint" ? "bold" : "regular" }
							color={ name === "wp_totalpoint" ? "dark" : "text"}
							sx={{ display: "inline-block", width: "max-content" }}
							>
							{data}
						</SoftTypography>
					</SoftBox>
				)
			}
		});

        return (
			<>
				<TableRow key={rowKey}
					sx={{ "&:hover": { backgroundColor: "#eceff1" , } }} 
					>
					{tableRow}
				</TableRow>
			</>
		);
    });


    return (
		<>
			{	data &&
				<>
				<SoftBox p={1} pl={3} >
					<CSVLink onClick={handleDownloadCsv} data={csvData} headers={headers} filename={filename}>
						<SoftButton
							variant="outlined" 
							color="info"
							>
							엑셀 다운로드 
						</SoftButton>
					</CSVLink>
				</SoftBox>
				<TableContainer>
					<MuiTable>
					<SoftBox component="thead">
						<TableRow>{renderColumns}</TableRow>
					</SoftBox>
					<TableBody>{renderRows}</TableBody>
					</MuiTable>
				</TableContainer>
				</>
			}
		</>
        )
    }

    // Setting default values for the props of Table
    MemberWpUsageStatusList.defaultProps = {
    columns: [],
    rows: [{}],
    };

    // Typechecking props for the Table
    MemberWpUsageStatusList.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    rows: PropTypes.arrayOf(PropTypes.object),
    };


export default MemberWpUsageStatusList