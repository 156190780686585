// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import {useMutation, useQueries, useQuery} from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Card, Icon, Modal } from "@mui/material";
import SoftButton from "components/SoftButton";
import React, { useEffect, useRef, useState } from "react";
import { customAxios } from "../../axios/CustomAxios";
import { myIpAddress } from "config";
import CostCustomSoftBox from "components/SoftBox/CostCustomSoftBox";
import borders from "assets/theme/base/borders";
import { useRecoilValue } from "recoil";
import { loginUser } from "atom/member/loginUser";
import CostWriterInput from "layouts/costInsert/components/CostWriterInput";
import ReferrerList from "../../layouts/costInsert/components/ReferrerList";
import ApprovalList from "../../layouts/costInsert/components/ApprovalList";
import { Document, Page, Text, PDFViewer, StyleSheet, View, Font, Image } from '@react-pdf/renderer';
import malgun from "../../assets/font/malgun.ttf";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CostDetailTable from "../../examples/Tables/CostTable/CostDetailTable";
import Swal from "sweetalert2";
import costApprovalReject from "../../api/cost/costApprovalReject";
import useBackButton from "layouts/costInsert/components/useBackButton";
import { FaRedoAlt } from "react-icons/fa";
import refViewCheckUpdate from "../../api/refer/refViewCheckUpdate";
import rejectViewCheckUpdate from "../../api/approval/rejectViewCheckUpdate";
import allCommonCodeMap from "../../api/commonCode/allCommonCodeMap";
import ReactToPrint from "react-to-print";
import { Print } from "@mui/icons-material";
import WpBills from "layouts/wpDetail/components/WpBills";

function CostDetail() {

    const user = useRecoilValue(loginUser);

    // navigate
    const navigate = useNavigate();
    const location = useLocation();
    const componentRef = useRef(null);
    const { borderWidth, borderColor } = borders;
    let { id } = useParams();

    // 참조자 및 반려 문서 체크
    const [refCheck, setRefCheck] = useState(false);
    const [rejectDocCheck, setRejectDocCheck] = useState(true);

    const [mem_rank, setMem_rank] = useState('');
    const [mem_name, setMem_name] = useState('');
    const [mem_team, setMem_team] = useState('');
    const [cost_category, setCost_category] = useState('');
    const [cost_category_code, setCost_category_code] = useState('');
    const [cost_date, setCost_date] = useState('');
    const [cost_approval, setCost_approval] = useState('');
    const [detail_otherList, setDetail_otherList] = useState([]);
    const [detail_trafficList, setDetail_trafficList] = useState([]);
    const [detail_fuelList, setDetail_fuelList] = useState([]);
    const [sumAmount, setSumAmount] = useState("");
    const [myCardsumAmount, setMyCardSumAmount] = useState("");
    const [comCardsumAmount, setComCardSumAmount] = useState("");
    const [mem_img, setMem_img] = useState("");
    const [referrerList, setReferrerList] = useState([]);
    const [approvalList, setApprovalList] = useState([]);
    const [approved_code, setApproved_code] = useState("");
    const [approval_no, setApproval_no] = useState("");
    const [mem_no, setMem_no] = useState("")
    const [approver_no, setApprover_no] = useState("")
    const [approval_do, setApproval_do] = useState("")
    const [approval_approvers, setApproval_approvers] = useState("")
    const [doc_type, setDoc_type] = useState("")
    const [signature, setSignature] = useState("")

    let currentDate = new Date();
    let month = currentDate.getMonth() + 1;
    let day = currentDate.getDate();
    let displayMonth;

    if (day < 11) {
        if (month == 1) {
            displayMonth = 12;
        } else {
            displayMonth = month-1;
        }
    } else {
        displayMonth = month;
    }

    const [commonCodeMap, setCommonCodeMap] = useState(new Map()); // 공용코드 맵
    const allCommonCodesQuery = useQueries([
        {
            queryKey: ["allCommonCodeMap"],
            queryFn: () => allCommonCodeMap(),
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            },
            onSuccess: (result) => {
                setCommonCodeMap(result.data);
            }
        },
    ]);

    // 참조자 문서열람 여부 업데이트
    const {mutate: refViewCheckBody} = useMutation(refViewCheckUpdate, {
        onSuccess: (data) => {
            Swal.fire({
                title: '참조 확인 되었습니다.',
                icon: 'success',
                showCancelButton: true,
                backdrop: false
            })
        },
        onError: () => {
            console.log('참조자 열람 업데이트 중 에러')
        }
    });

    // 반려문서 열람여부 업데이트
    const {mutate: rejectViewCheckBody} = useMutation(rejectViewCheckUpdate, {
        onSuccess: (data) => {

        },
        onError: () => {
            console.log('반려문서 열람 업데이트 중 에러')
        }
    });


    const matchingItem = approvalList?.find(item => item.approver_order === 1 && item.approver_mem_no === user.id);
    const color = approved_code === 'AP001' ? "#0467d1" : approved_code === 'AP002' ? "#042ad1" : approved_code === 'AP003' ? "#04d104" : approved_code === 'AP004' ? "#d10404" : approved_code === 'AP005' ? "#8b8b8c" : approved_code === 'AP007'? "#f59802" : "#d10404"
    //작성자정보가져오기
    useEffect(() => {
        customAxios.get(myIpAddress + `/cost/costDetailWriterInfo/${id}`)
            .then((response) => {
                if(response.data === null || response.data === undefined || response.data === ''){
                    navigate("/cost");
                } else {
                    setMem_rank(response.data.rank_code);
                    setMem_name(response.data.mem_name);
                    setMem_team(response.data.team_code);
                    setCost_category(response.data.category_info);
                    setCost_date(response.data.dateformat);
                    setCost_approval(response.data.approved_info);
                    setMem_img(response.data.imageUrl);
                    setCost_category_code(response.data.cost_category);
                    setApproved_code(response.data.cost_approved);
                    setMem_no(response.data.mem_no);
                    setApproval_approvers(response.data.approval_approvers);
                    setApproval_no(response.data.approval_no);
                    setDoc_type(response.data.doc_type);
                    setSignature(response.data.signature);
                }
            }).catch((error)=>{
                navigate("/");
        });
        customAxios.get(myIpAddress + `/cost/costmainlist/${id}`)
            .then((response) => {
                if(response.data === null || response.data === undefined || response.data === ''){
                    navigate("/cost");
                } else {
                    setDetail_otherList(response.data.detail_otherList);
                    setDetail_trafficList(response.data.detail_trafficList);
                    setDetail_fuelList(response.data.fuelList);
                    setReferrerList(response.data.referrerList);
                    setApprovalList(response.data.approvalList);
                    setApproval_do(response.data.approval_do);
                    setApprover_no(response.data.cost_current_approve_mem_no);

                    const approvalStatusCode = response.data?.cost_approved;
                    const approvalNo = response.data?.approval_no;
                    const memNo = response.data?.mem_no;
                    const refList = response.data.referrerList;

                    // 반려문서일때 해당하는 문서가 로그인 사람 문서일때 반려문서 확인처리
                    if (rejectDocCheck && approvalStatusCode === "AP004" && user?.id === memNo) {
                        const rejectViewBody = {
                            mem_no: memNo,
                            approval_no: approvalNo
                        }
                        rejectViewCheckBody(rejectViewBody);
                        setRejectDocCheck(false);
                    }

                    // 내가 해당하는 문서의 참조자일 때 참조 확인 처리
                    if (!refCheck && (approvalStatusCode === "AP003" || approvalStatusCode === "AP000")) {
                        for (let i = 0; i < refList.length; i++) {
                            if (refList[i].ref_mem_no === user?.id) {
                                if(refList[i].ref_regdate === null) {
                                    const refBody = {
                                        ref_mem_no: user?.id,
                                        approval_no: approvalNo
                                    }
                                    refViewCheckBody(refBody);
                                    setRefCheck(true);
                                    break;
                                }
                            }
                        }
                        if (!refCheck) {
                            setRefCheck(true);
                        }
                    }
                }
            }).catch((error)=>{
            navigate("/");
        });
    }, []);

    useEffect(() => {
        let total = 0;
        let myAmount = 0;
        let comAmount = 0;

        detail_otherList?.map(list => {
            if (list.other_amount === "" || list.other_amount === null) {
                total += 0;
            } else {
                total += parseInt(list.other_amount)
                if(list.other_paytype === "PM002")  {
                    comAmount += list.other_amount
                } else {
                    myAmount += list.other_amount
                }
            } 
        })
        detail_trafficList?.map(list => {
            if (list.traffic_amount === "" || list.traffic_amount === null) {
                total += 0;
            } else {
                total += parseInt(list.traffic_amount)
                if(list.other_paytype === "PM002")  {
                    comAmount += list.traffic_amount
                } else {
                    myAmount += list.traffic_amount
                }
            } 
        })
        detail_fuelList?.map(list => {
            if (list.fuel_amount === "" || list.fuel_amount === null) {
                total += 0;
            } else {
                total += parseInt(list.fuel_amount)
                if(list.other_paytype === "PM002")  {
                    comAmount += list.fuel_amount
                } else {
                    myAmount += list.fuel_amount
                }
            } 
        })
        setMyCardSumAmount(myAmount)
        setComCardSumAmount(comAmount)
        setSumAmount(total)
    }, [detail_otherList, detail_trafficList, detail_fuelList])

    //240216 영수증 수정
    let CostBenefitDetailData = [
        { key: "other_date", width: 1.5, color: "warning", icon: "event_available", name: "지출일", align: "center" },
        { key: "category_info", width: 1.5, color: "success", icon: "fact_check", name: "용도", align: "center" },
        { key: "other_contents", width: 3, color: "info", icon: "notes", name: "내용", align: "center" },
        { key: "other_personnel", width: 1.5, color: "secondary", icon: "person", name: "인원", align: "center" },
        { key: "cost_filename", width: 1.3, color: "secondary", icon: "insert_photo", name: "영수증", align: "center" },
        { key: "other_amount", width: 1.5, color: "error", icon: "paid", name: "금액", align: "center" },
        { key: "paytype_info", width: 1.5, color: "primary", icon: "payment", name: "결제수단", align: "center" }
    ]

    let CostPublicTransportaionDetailData = [
        { key: "traffic_date", width: 1.5, color: "warning", icon: "event_available", name: "지출일", align: "center" },
        { key: "traffic_purpose", width: 2.25, color: "success", icon: "fact_check", name: "방문목적", align: "center" },
        { key: "traffic_method", width: 1.5, color: "info", icon: "directions_bus", name: "교통수단", align: "center" },
        { key: "traffic_location", width: 2.25, color: "secondary", icon: "location_on", name: "방문장소", align: "center" },
        { key: "way_info", width: 1.5, color: "success", icon: "route", name: "편도/왕복", align: "center" },
        { key: "traffic_amount", width: 1.5, color: "error", icon: "paid", name: "금액", align: "center" }
    ]
    let CostFuelDetailData = [
        { key: "fuel_date", width: 1.3, color: "warning", icon: "event_available", name: "운행일", align: "center" },
        { key: "fuel_category", width: 1.4, color: "success", icon: "fact_check", name: "운행용도", align: "center" },
        { key: "fuel_carnum", width: 1.3, color: "info", icon: "123", name: "차량번호", align: "center" },
        { key: "fuel_start", width: 1.3, color: "secondary", icon: "directions_run", name: "출발지", align: "center" },
        { key: "fuel_end", width: 1.3, color: "dark", icon: "keyboard_tab_rtl", name: "도착지", align: "center" },
        { key: "fuel_distance", width: 1.3, color: "success", icon: "moving", name: "운행거리", align: "center" },
        { key: "cost_filename", width: 1.3, color: "secondary", icon: "insert_photo", name: "영수증", align: "center"},
        { key: "fuel_amount", width: 1.3, color: "error", icon: "paid", name: "금액", align: "center" },
        { key: "paytype_info", width: 1.3, color: "primary", icon: "payment", name: "결제수단", align: "center" }
    ]

    let columns;
    if (cost_category_code === "C000") {
        columns = CostBenefitDetailData;
    } else if (cost_category_code === "C001") {
        columns = CostBenefitDetailData;
    } else if (cost_category_code === "C002") {
        columns = CostPublicTransportaionDetailData;
    } else if (cost_category_code === "C003") {
        columns = CostFuelDetailData;
    }

    let rows = [];
    if (detail_otherList?.length !== 0) {
        rows = detail_otherList;
    } else if (detail_trafficList?.length !== 0) {
        rows = detail_trafficList;
    } else if (detail_fuelList?.length !== 0) {
        rows = detail_fuelList;
    }

    let imgList = [];
    // 영수증 이미지 처리 --> 결재 승인된 경우 우측상단에서 프린트 가능하게 처리
    rows.map((list) =>{
        // 영수증 이미지가 있을 때 + "" 빈값 아닐때 체크 추가 
        if(list.cost_filename_real != null && list.cost_filename_real != "") {
            imgList.push(list.cost_filename_real)
        }
    })

    useBackButton({ oldPath: location.state, state: 'APT001' });

    // 삭제버튼
    const costDelete = () => {
        Swal.fire({
            title: '삭제 하시겠습니까?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '삭제',
            cancelButtonText: '취소',
            backdrop: false
        }).then((result) => {
            if (result.isConfirmed) {
                customAxios.delete(myIpAddress + '/cost/cost', { data: { cost_no: id } })
                    .then((response) => {
                        navigate('/cost')
                    });
            }
        })
    }

    /**
     * 상신취소
     */
    const costApproveCancle = () => {
        customAxios.post(myIpAddress + '/cost/costCancel', { approval_no: approval_no, doc_no: id, doc_approved: "AP006", approval_cancel: 1 })
            .then((response) => {
                navigate("/docs/sent", { state: 'APT001' })
            });
    }
    /**
     * 2023-05-10 김우성 : doc_approved 및 approval_cancel 추가
     */

    /**
     * 문서 결재 승인
     */
    const costApprove = () => {

        Swal.fire({
            title: '결재 승인 처리 하시겠습니까?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '승인',
            backdrop: false

        }).then((result) => {
            if (result.isConfirmed) {
                const newApprovalList = [...approvalList];
                if(referrerList.length > 0) {
                    newApprovalList[0].referrerList = referrerList;
                }
                customAxios.post(myIpAddress + '/cost/approve', newApprovalList, {
                    params: {
                        mem_no: mem_no,
                        doc_type: doc_type
                    }
                })
                    .then((response) => {
                        navigate("/docs/receive", { state: 'APT001' })
                    });
            }

        })

    }
    const costReExamination = () => {
        Swal.fire({
            title: '재검토 요청을 하시겠습니까?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '승인',
            backdrop: false

        }).then((result) => {
            if (result.isConfirmed) {
                customAxios.post(myIpAddress + '/cost/costReExamination', approvalList, {
                    params: {
                        mem_no: mem_no,
                        doc_type: doc_type,
                        doc_no: id,
                    }
                })
                    .then((response) => {
                        navigate("/docs/receive", { state: 'APT001' })
                    });
            }

        })
    }

    /**
     * 결재반려
     */
    const costRejection = () => {

        Swal.fire({
            title: '결재 반려 처리 하시겠습니까?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '결재반려',
            backdrop: false

        }).then((result) => {
            if (result.isConfirmed) {
                (async () => {
                    const {value: getReason} = await Swal.fire({
                        title: '결재 반려 사유를 입력해주세요.',
                        text: '100자 이내로 작성해주십시오.',
                        input: 'text',
                        inputPlaceholder: '결재 반려사유 ',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: '취소',
                        confirmButtonText: '반려',
                        backdrop: false
                    })

                    // 이후 처리되는 내용.
                    if (getReason !== undefined) {
                        if (getReason && getReason.length <= 100) {
                            // 마지막 결재일때 결재 완료 처리
                            const body = {
                                doc_no: id
                                , approval_no: approval_no
                                , cost_no: id
                                , cost_approved: 'AP004'
                                , approval_do: approval_do
                                , cost_current_approve_mem_no: approver_no
                                , approval_cancel_reason: getReason
                                , mem_no: user?.id
                                , doc_type: doc_type
                            }
                            addRejectBody(body);
                        } else if (getReason && getReason.length > 100) {
                            Swal.fire({
                                title: '반려 사유가 100자를 초과하였습니다',
                                icon: 'warning',
                                backdrop: false
                            })
                        } else {
                            Swal.fire({
                                title: '반려 사유 입력해주세요',
                                icon: 'warning',
                                backdrop: false
                            })
                        }
                    }
                })()
            }
        })
    }

    // useMutation - 상신 문서 결재 반려
    const { mutate: addRejectBody } = useMutation(costApprovalReject, {
        onSuccess: (data) => {
            Swal.fire({
                title: '정상처리 되었습니다.',
                icon: 'success',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: '보관함으로 이동',
                backdrop: false
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/docs/receive', { state: 'APT001' })
                }
            }
            )
        },
        onError: () => {
            Swal.fire({
                title: '문서 결재 반려 중 에러가 발생했습니다. 잠시후 다시 실행해주세요',
                icon: 'error',
                backdrop: false,
            })
        }
    });

    //수정버튼
    const costUpdate = () => {
        navigate('/cost/insert', { state: {oldPath:location.pathname, state:id, approval_no: approval_no} })
    }

    // modal open
    const [modalOpen, setModalOpen] = useState(false);

    /**
     * modal close
     * @param event
     */
    const modalClose = (event) => {
        if (event.target === event.currentTarget) {
            setModalOpen(false);
        }
    };

    Font.register({
        family: 'malgun',
        src: malgun,
    });

    //240216 수정
    let benefitDetailRow = [
        { key: "번호", width: '7%' },
        { key: "일자", width: '8.5%' },
        { key: "용도", width: '16%' },
        { key: "내 역", width: '35%' },
        { key: "인원", width: '8.5%' },
        { key: "금액", width: '13%' },
        { key: "비고", width: '12%' },
    ]

    let publicTransportaionDetailRow = [
        { key: "번호", width: '7%' },
        { key: "일자", width: '8.5%' },
        { key: "방문장소", width: "22%" },
        { key: "방문목적", width: "22%" },
        { key: "교통수단", width: "16.5%" },
        { key: "금액", width: '13%' },
        { key: "비고", width: '11%' },
    ]
    let fuelDetailRow = [
        { key: "번호", width: '6%' },
        { key: "일자", width: '8%' },
        { key: "용도", width: '17%' },
        { key: "차량번호", width: "14%" },
        { key: "출발지", width: "11%" },
        { key: "도착지", width: "11%" },
        { key: "운행거리", width: "10%" },
        { key: "금액", width: '13%' },
        { key: "비고", width: '10%' },
    ]
    const pdfStyles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            paddingTop: 30,
            paddingBottom: 30,
            paddingHorizontal: 18,
        },
        header: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        tableColHeaderText: {
            fontFamily: 'malgun',
            textAlign: 'center',
            fontSize: 13,
        },
        tableRowItem: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
    });

    const PDFCostView = () => {
        const companyLogo = "/company-logo.jpeg";
        return (
            <Document>
                <Page size="A4" style={pdfStyles.page}>
                    {/* 상단 로고 추가 */}
                    <View style={{ position:"absolute",left:15, top:15 }}>
                        <Image src={companyLogo} style={{ width: 75}}/>
                    </View>
                    <View style={{ marginBottom: 10 }}>
                        <Text style={[pdfStyles.tableColHeaderText, { fontSize: 17 }]}>
                            비용처리 명세서 ({cost_category})
                        </Text>
                    </View>
                    <View style={[pdfStyles.header, { marginBottom: 4 }]}>
                        <Text style={[pdfStyles.tableColHeaderText, { marginLeft: 8 }]}>
                            성명 : {mem_name}
                        </Text>
                        <Text style={[pdfStyles.tableColHeaderText, { marginRight: 8 }]}>{cost_date}</Text>
                    </View>
                    <View style={{ alignItems: 'center', width: '100%' }}>
                        <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "99%", borderRight: '1px solid black', borderLeft: '1px solid black', }}>
                            <View style={{ width: '98%', borderTop: '1px solid black', borderBottom: '1px solid black', }}>
                                <View style={{ flexDirection: 'row' }}>
                                    {(cost_category_code === 'C000') && benefitDetailRow.map((item, idx) =>
                                        <View key={idx} style={{ width: item.width, paddingBottom: 3, paddingTop: 1, backgroundColor: '#e8fcf2', borderRight: idx === benefitDetailRow.length - 1 ? 'none' : '1px solid black' }}>
                                            <Text style={pdfStyles.tableColHeaderText}>{item.key}</Text>
                                        </View>
                                    )}
                                    {(cost_category_code === 'C001') && benefitDetailRow.map((item, idx) =>
                                        <View key={idx} style={{ width: item.width, paddingBottom: 3, paddingTop: 1, backgroundColor: '#e8fcf2', borderRight: idx === benefitDetailRow.length - 1 ? 'none' : '1px solid black' }}>
                                            <Text style={pdfStyles.tableColHeaderText}>{item.key}</Text>
                                        </View>
                                    )}
                                    {(cost_category_code === 'C002') && publicTransportaionDetailRow.map((item, idx) =>
                                        <View key={idx} style={{ width: item.width, paddingBottom: 3, paddingTop: 1, backgroundColor: '#e8fcf2', borderRight: idx === publicTransportaionDetailRow.length - 1 ? 'none' : '1px solid black' }}>
                                            <Text style={pdfStyles.tableColHeaderText}>{item.key}</Text>
                                        </View>
                                    )}
                                    {(cost_category_code === 'C003') && fuelDetailRow.map((item, idx) =>
                                        <View key={idx} style={{ width: item.width, paddingBottom: 3, paddingTop: 1, backgroundColor: '#e8fcf2', borderRight: idx === fuelDetailRow.length - 1 ? 'none' : '1px solid black' }}>
                                            <Text style={pdfStyles.tableColHeaderText}>{item.key}</Text>
                                        </View>
                                    )}
                                </View>
                                {detail_otherList?.length !== 0 ? detail_otherList?.map((item, idx) =>
                                    <View style={{ flexDirection: 'row', borderTop: '1px solid black', flexWrap: 'wrap' }} key={idx}>
                                        <View style={{ width: benefitDetailRow[0].width, borderRight: '1px solid black' }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 }]}>{idx + 1}</Text>
                                        </View>
                                        <View style={{ width: benefitDetailRow[1].width, borderRight: '1px solid black' }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'center', }]}>{new Date(item.other_date).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' })}</Text>
                                        </View>
                                        <View style={{ width: benefitDetailRow[2].width, borderRight: '1px solid black' }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'left' , paddingLeft : 1}]}>{item.category_info}</Text>
                                        </View>
                                        <View style={{ width: benefitDetailRow[3].width, borderRight: '1px solid black', flexWrap: 'wrap' }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2, flexWrap: 'wrap' ,textAlign : 'left' , paddingLeft : 3 }]}>{item.other_contents}</Text>
                                        </View>
                                        <View style={{ width: benefitDetailRow[4].width, borderRight: '1px solid black' }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'center', paddingRight : 3 }]}>{item.other_personnel}</Text>
                                        </View>
                                        <View style={{ width: benefitDetailRow[5].width, borderRight: '1px solid black' }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'right', paddingRight : 3 }]}>{item.other_amount.toLocaleString()}</Text>
                                        </View>
                                        <View style={{ width: benefitDetailRow[6].width }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'center'}]}>{item.paytype_info}</Text>
                                        </View>
                                    </View>
                                ) : null}
                                {detail_trafficList.length !== 0 ? detail_trafficList.map((item, idx) =>
                                    <View style={{ flexDirection: 'row', borderTop: '1px solid black' }} key={idx}>
                                        <View style={{ width: publicTransportaionDetailRow[0].width, borderRight: '1px solid black' }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 }]}>{idx + 1}</Text>
                                        </View>
                                        <View style={{ width: publicTransportaionDetailRow[1].width, borderRight: '1px solid black' }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'center'}]}>{new Date(item.traffic_date).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' })}</Text>
                                        </View>
                                        <View style={{ width: publicTransportaionDetailRow[2].width, borderRight: '1px solid black' }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'left', paddingLeft :2}]}>{item.traffic_location}</Text>
                                        </View>
                                        <View style={{ width: publicTransportaionDetailRow[3].width, borderRight: '1px solid black' }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'left', paddingLeft :2, overflow: 'hidden'}]}>{item.traffic_purpose.split('')}</Text>
                                        </View>
                                        <View style={{ width: publicTransportaionDetailRow[4].width, borderRight: '1px solid black' }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'left', paddingLeft :2}]}>{item.traffic_method}</Text>
                                        </View>
                                        <View style={{ width: publicTransportaionDetailRow[5].width, borderRight: '1px solid black' }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'right' }]}>{item.traffic_amount.toLocaleString()} </Text>
                                        </View>
                                        <View style={{ width: publicTransportaionDetailRow[6].width }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'center'}]}>{commonCodeMap["PM001"]?.info}</Text>
                                        </View>
                                    </View>
                                ) : null}
                                {detail_fuelList.length !== 0 ? detail_fuelList.map((item, idx) =>
                                    <View style={{ flexDirection: 'row', borderTop: '1px solid black' }} key={idx}>
                                        <View style={{ width: fuelDetailRow[0].width, borderRight: '1px solid black' }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 }]}>{idx + 1}</Text>
                                        </View>
                                        <View style={{ width: fuelDetailRow[1].width, borderRight: '1px solid black' }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'center' }]}>{new Date(item.fuel_date).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' })}</Text>
                                        </View>
                                        <View style={{ width: fuelDetailRow[2].width, borderRight: '1px solid black' }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'left' , paddingLeft : 2 }]}>{item.fuel_category}</Text>
                                        </View>
                                        <View style={{ width: fuelDetailRow[3].width, borderRight: '1px solid black' }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'left' , paddingLeft : 2 }]}>{item.fuel_carnum}</Text>
                                        </View>
                                        <View style={{ width: fuelDetailRow[4].width, borderRight: '1px solid black' }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'left' , paddingLeft : 2 }]}>{item.fuel_start}</Text>
                                        </View>
                                        <View style={{ width: fuelDetailRow[5].width, borderRight: '1px solid black' }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'left' , paddingLeft : 2}]}>{item.fuel_end}</Text>
                                        </View>
                                        <View style={{ width: fuelDetailRow[6].width, borderRight: '1px solid black' }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'right' , paddingRight : 1}]}>{item.fuel_distance}</Text>
                                        </View>
                                        <View style={{ width: fuelDetailRow[7].width, borderRight: '1px solid black' }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'right' , paddingRight : 3 }]}>{item.fuel_amount.toLocaleString()}</Text>
                                        </View>
                                        <View style={{ width: fuelDetailRow[8].width }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 ,textAlign : 'right' , paddingRight : 3 }]}>{item.paytype_info}</Text>
                                        </View>
                                    </View>
                                ) : null}
                            </View>
                            <View style={{ width: '98%', borderTop: '1px solid black', borderBottom: '1px solid black' }}>
                                {detail_fuelList.length !== 0 ?
                                    <>
                                        <View style={{ flexDirection: 'row' , borderBottom: '1px solid black' }}>
                                            <View style={{ width: '80%', borderRightWidth: 1, borderRightColor: 'black' }}>
                                                <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 }]}>개 인 비 용</Text>
                                            </View>
                                            <View style={{ width: '20%', }}>
                                                <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 ,textAlign : 'right', paddingRight : 3 }]}>{myCardsumAmount.toLocaleString()}</Text>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row' , borderBottom: '1px solid black' }}>
                                            <View style={{ width: '80%', borderRightWidth: 1, borderRightColor: 'black' }}>
                                                <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 }]}>법 인 비 용</Text>
                                            </View>
                                            <View style={{ width: '20%', }}>
                                                <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 ,textAlign : 'right', paddingRight : 3 }]}>{comCardsumAmount.toLocaleString()}</Text>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row' }}>
                                            <View style={{ width: '80%', borderRight: '1px solid black' }}>
                                                <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 }]}>사  용  총  액</Text>
                                            </View>
                                            <View style={{ width: '20%' }}>
                                                <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 ,textAlign : 'right' , paddingRight : 3 }]}>{sumAmount.toLocaleString()}</Text>
                                            </View>
                                        </View>
                                    </>
                                    :
                                    <>
                                        <View style={{ flexDirection: 'row' , borderBottom: '1px solid black' }}>
                                            <View style={{ width: '80%', borderRightWidth: 1, borderRightColor: 'black' }}>
                                                <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 }]}>개 인 비 용</Text>
                                            </View>
                                            <View style={{ width: '20%', }}>
                                                <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 ,textAlign : 'right', paddingRight : 3 }]}>{myCardsumAmount.toLocaleString()}</Text>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row' , borderBottom: '1px solid black' }}>
                                            <View style={{ width: '80%', borderRightWidth: 1, borderRightColor: 'black' }}>
                                                <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 }]}>법 인 비 용</Text>
                                            </View>
                                            <View style={{ width: '20%', }}>
                                                <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 ,textAlign : 'right', paddingRight : 3 }]}>{comCardsumAmount.toLocaleString()}</Text>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row' }}>
                                            <View style={{ width: '80%', borderRightWidth: 1, borderRightColor: 'black' }}>
                                                <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 }]}>사  용  총  액</Text>
                                            </View>
                                            <View style={{ width: '20%', }}>
                                                <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 ,textAlign : 'right', paddingRight : 3 }]}>{sumAmount.toLocaleString()}</Text>
                                            </View>
                                        </View>
                                    </>
                                }
                                <View style={{ flexDirection: 'row', borderTopWidth: 1, borderTopColor: 'black' }}>
                                    <View style={{ width: '50%' }}>
                                        <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 5, paddingTop: 5 }]}>작 성 자 : {mem_name}</Text>
                                    </View>
                                    <View style={{ width: '25%' }}>
                                        <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 5, paddingTop: 5 }]}>서 명 : </Text>
                                    </View>
                                    <View style={{ width: '25%', }}>
                                        <Image src={signature} style={{ width: 28, height: 28, borderRadius: 5 }} />
                                    </View>
                                </View>
                                {approvalList?.length > 0 ?
                                    <>{approvalList?.map((item, idx) =>
                                        <View style={{ flexDirection: 'row', borderTopWidth: 1, borderTopColor: 'black' }}>
                                            <View style={{ width: '50%' }}>
                                                <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 5, paddingTop: 5 }]}>{idx + 1}차 결재자 : {item.approver_name}</Text>
                                            </View>
                                            <View style={{ width: '25%' }}>
                                                <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 5, paddingTop: 5 }]}>서 명 : </Text>
                                            </View>
                                            <View style={{ width: '25%', }}>
                                                {item?.approver_do === 1 &&
                                                    <Image src={item.signature} style={{ width: 28, height: 28, borderRadius: 5 }} />
                                                }
                                            </View>
                                        </View>
                                    )}</> : <></>}
                            </View>
                        </View>
                    </View>
                </Page>
            </Document>
        );
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                    <Card sx={{ height: "100%", marginBottom: "1rem" }}>
                        <SoftBox mt={1} mb={1} p={1}>
                            <Grid container justifyContent="space-between">
                                <Grid>
                                    <SoftTypography variant="h4" fontWeight="medium" sx={{ fontWeight: 'bold', display: 'inline' }}>
                                        {displayMonth}월 비용처리 상세보기
                                    </SoftTypography>
                                    <SoftTypography variant="h5" fontWeight="medium" sx={{ fontWeight: 'bold', display: 'inline', color: color }}>
                                        ({cost_approval})
                                    </SoftTypography>
                                </Grid>
                                <Grid item>
                                    <Grid container style={{ display: "flex", gap: "5px", flexWrap: "nowrap" }}>
                                        {approvalList?.map((item) => {
                                            if (item.approver_order === 1 && item.approver_mem_no === user.id) {
                                                return (
                                                    <>
                                                        <Grid item>
                                                            <SoftButton variant="gradient" color="success" onClick={() => costApprove()} >
                                                                <Icon sx={{ fontWeight: "bold", marginLeft: -1 }}>check_circle</Icon>
                                                                결재승인
                                                            </SoftButton>
                                                        </Grid>
                                                        <Grid item>
                                                            <SoftButton variant="gradient" color="secondary" onClick={() => costRejection()}>
                                                                <Icon sx={{ fontWeight: "bold", marginLeft: -1 }}>error</Icon>
                                                                결재반려
                                                            </SoftButton>
                                                        </Grid>
                                                    </>)
                                            } else {
                                                return null;
                                            }
                                        })}
                                        {approved_code === 'AP001' && mem_no == user.id ?

                                            <Grid item>
                                                <SoftButton variant="gradient" color="error" onClick={() => costApproveCancle()} >
                                                    <Icon sx={{ fontWeight: "bold", marginLeft: -1 }}>cancel</Icon>
                                                    상신취소 
                                                </SoftButton>
                                            </Grid>
                                            : null}
                                        {approved_code === 'AP005' && mem_no === user.id ?
                                            <Grid item>
                                                <SoftButton variant="gradient" color="error" onClick={() => costDelete()} >
                                                    <Icon sx={{ fontWeight: "bold", marginLeft: -1 }}>cancel</Icon>
                                                    삭제
                                                </SoftButton>
                                            </Grid>
                                            : null}
                                        {(approved_code === 'AP001' || approved_code === 'AP005' || approved_code === 'AP007') && mem_no == user.id ?

                                            <Grid item>
                                                <SoftButton variant="gradient" color="primary" onClick={() => costUpdate()}>
                                                    <Icon sx={{ fontWeight: "bold", marginLeft: -1 }}>edit_note</Icon>
                                                    문서수정
                                                </SoftButton>
                                            </Grid>
                                            : null}
                                        {approved_code === 'AP003' && user.role === "ADMIN" ?
                                            <></>
                                            // <Grid item>
                                            //     <SoftButton variant="gradient" color="warning" onClick={costReExamination}>
                                            //         <FaRedoAlt style={{ verticalAlign: "middle", marginRight:2 }} />
                                            //         재검토 요청
                                            //     </SoftButton>
                                            // </Grid>
                                            : null}
                                        {approved_code === 'AP003' ?
                                            <>
                                                <SoftButton variant="gradient" color="info" onClick={() => setModalOpen(true)}>
                                                    {/* <PictureAsPdfIcon sx={{ marginLeft: -1 }} /> */}
                                                    <Print /> 명세서
                                                </SoftButton>
                                                {/* 영수증 프린트 항목 : 영수증 버튼 클릭 시 WpBills 화면에 출력 */}
                                                <ReactToPrint
                                                    trigger={() => 
                                                        <SoftButton variant="gradient" color="success">
                                                            <Print /> 영수증
                                                        </SoftButton>}
                                                    content={() => componentRef.current}
                                                    />
                                                {/* 프린트되는 컴포넌트 */}
                                                <div style={{ display: 'none' }}>
                                                    <WpBills printRef={componentRef} imgList={imgList} />  
                                                </div>
                                            </>
                                            : null}
                                        {approved_code === 'AP004' && mem_no === user.id ?
                                            <Grid item>
                                                <SoftButton variant="gradient" color="primary" onClick={() => costUpdate()}>
                                                    <Icon sx={{ fontWeight: "bold", marginLeft: -1 }}>edit_note</Icon>
                                                    문서수정
                                                </SoftButton>
                                            </Grid>
                                            : null}
                                        {approved_code === 'AP006' && mem_no === user.id ?
                                            <Grid item>
                                                <SoftButton variant="gradient" color="primary" onClick={() => costUpdate()}>
                                                    <Icon sx={{ fontWeight: "bold", marginLeft: -1 }}>edit_note</Icon>
                                                    문서수정
                                                </SoftButton>
                                            </Grid>
                                            : null}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mb: 2.5 }}>
                <Grid item xs={12} sm={5} md={5} xl={5}>
                    <Card >
                        <SoftBox mb={3} px={1} pt={3} display="flex" justifyContent="space-between" alignItems="center">
                            <Grid container>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h5" fontWeight="bold" textTransform="capitalize" pl={2} >
                                        작성자
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={12}>
                                    <CostWriterInput url={mem_img} name={mem_name} team={mem_team} rank={mem_rank} size="lg" />
                                </Grid>
                            </Grid>
                        </SoftBox>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={7} md={7} xl={7}>
                    <Card sx={{ height: '100%' }}>
                        <SoftBox mb={3} px={1} pt={3} display="flex" justifyContent="space-between" alignItems="center">
                            <Grid container>
                                <Grid item xs={12}>
                                    <SoftBox mb={1}>
                                        <Grid container>
                                            <Grid item xs={12} sm={3} lg={2}>
                                                <SoftTypography variant="h5" fontWeight="bold" textTransform="capitalize" pl={2} mt={1.5} sx={{ width: "max-content" }}>
                                                    작성일
                                                </SoftTypography>
                                            </Grid>
                                            <Grid item xs={12} sm={9} lg={10} >
                                                <CostCustomSoftBox item={cost_date} justifyContent="space-between" fontWeight="bold" height="30px" />
                                            </Grid>
                                        </Grid>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} sm={3} lg={2}>
                                    <SoftTypography variant="h5" fontWeight="bold" textTransform="capitalize" pl={2} mt={1.5} sx={{ width: "max-content" }}>
                                        구분
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={12} sm={9} lg={10}>
                                    <CostCustomSoftBox item={cost_category} justifyContent="space-between" fontWeight="bold" height="30px" />
                                </Grid>
                            </Grid>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
            <Card sx={{ boxShadow: 1, p: 1 }}>
                <CostDetailTable columns={columns} rows={rows} sum={sumAmount} />
            </Card>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} lg={12} mt={3}>
                    <Card sx={{ height: "100%", boxShadow: 3 }}>
                        <SoftBox mb={3} px={1} pt={3} display="flex" justifyContent="space-between" alignItems="center">
                            <Grid container>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h5" fontWeight="bold" textTransform="capitalize" pl={2} >
                                        결재자
                                    </SoftTypography>
                                </Grid>
                                <Grid container spacing={3} p={2}>
                                    {   approvalList?.length > 0 &&
                                        // 2023-06-02 결재자 내용 없을시 빈 컴포넌트로 떠서 결재자 체크 추가
                                        approvalList[0].approver_name !== null && 
                                        approvalList.map((item, idx) =>
                                            <ApprovalList key={idx} item={item} length={approvalList.length} idx={idx} />
                                    )}
                                </Grid>
                            </Grid>
                        </SoftBox>
                    </Card>
                </Grid>
                {referrerList?.length > 0 ?
                    <>
                        <Grid item xs={12} sm={12} lg={12} mt={3}>
                            <Card sx={{ height: "100%", boxShadow: 3 }}>
                                <SoftBox mb={3} px={1} pt={3} display="flex" justifyContent="space-between" alignItems="center">
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <SoftTypography variant="h5" fontWeight="bold" textTransform="capitalize" pl={2} >
                                                참조자
                                            </SoftTypography>
                                        </Grid>
                                        <Grid container spacing={3} p={2}>
                                            {referrerList.map((item, idx) =>
                                                <>
                                                    <ReferrerList key={idx} item={item} length={referrerList.length} idx={idx} />
                                                </>
                                            )}
                                        </Grid>
                                    </Grid>
                                </SoftBox>
                            </Card>
                        </Grid>
                    </>
                    :
                    <></>
                }
            </Grid>
            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}
                    onClick={modalClose}>
                    <PDFViewer style={{ fontSize: "20px", width: "70%", height: "90%" }}>
                        <PDFCostView />
                    </PDFViewer>
                </div>
            </Modal>
        </DashboardLayout>
    );
}

export default CostDetail;
