import { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import './printCss.css'
import { position } from 'stylis';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const WpBills = ({printRef, imgList}) => {

    const [numPages, setNumPages] = useState(0);

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
    }

    return (
        <div ref={printRef}>
            {imgList.map((img, index) => {
                return (
                    <div key={index}>
                        {/* 파일이 pdf일 때 / img일 때 구분 */}
                        {img.endsWith('.pdf') ? (
                            <Document file={img} onLoadSuccess={onDocumentLoadSuccess}>
                                {Array.from({ length: numPages }, (_, pageIndex) => (
                                    <div>
                                        <Page
                                            key={`page_${pageIndex + 1}`}
                                            pageNumber={pageIndex + 1}
                                            scale={2}
                                            style={{top: 0}}
                                        />
                                    </div>
                                ))}
                            </Document>
                        ) : (
                            <div
                                style={{ width: '100vw', height: '100vh'}}
                            >
                                <img
                                    key={index}
                                    src={img}
                                    style={{ objectFit: 'contain', margin: '20mm', maxWidth: 'calc(100vw - 40mm)', maxHeight: 'calc(100vh - 50mm)'}}
                                    >
                                </img>
                            </div>
                        )}
                    </div>
                )
            })}
        </div>
    )
};

export default WpBills;
