import {useState} from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../../examples/Footer";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import {Badge, BottomNavigation, BottomNavigationAction} from "@mui/material";
import iconConfig from "../../../../iconConfig";
import {useQueries} from "react-query";
import allCommonCodeMap from "../../../../api/commonCode/allCommonCodeMap";
import {useLocation} from "react-router-dom";
import VacationReceiveDocs from "./components/VacationReceiveDocs";
import Grid from "@mui/material/Grid";
import CostReceiveDocs from "./components/CostReceiveDocs"
import WpReceiveDocs from "./components/WpReceiveDocs";
import colors from "../../../../assets/theme/base/colors";
import checkDocCount from "../../../../api/docs/checkDocCount";
import { useRecoilState, useRecoilValue } from "recoil";
import { loginUser } from "atom/member/loginUser";
import AllReceiveDocs from "./components";

/**
 * 전체 문서 수신함
 * @returns {JSX.Element}
 * @constructor
 */
function ApprovalReceive() {
    const location = useLocation();

    const user = useRecoilValue(loginUser);

    const {approvalType} = iconConfig;
    const {gradients} = colors;

    let approvalTypeList = [];
    // const approvalTypeCodeList = ['APT000', 'APT001', 'APT002', 'APT003'];
    // 비용처리, 품의, 복지포인트 가리기
    const approvalTypeCodeList = ['APT000','APT001','APT003'];
    const approvalTypeNo = {
        APT000: 0
        , APT001: 1
        // , APT002: 2
        , APT003: 2
    };

    const body = {
        mem_no : user?.id
    }

    const [count, setCount] = useState(0);

    const queries = useQueries([
        // queries[0] : 공용코드 맵
        {
            queryKey: ["allCommonCodeMap"],
            queryFn: () => allCommonCodeMap(),
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            },
            
        },{
            queryKey: ["checkDocCount", body ],
            queryFn: () => checkDocCount(body),
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            },
            onSuccess: (data) => {
                const countList = data.data;
                const count = countList[0].checkCount;
                setCount(count)
            }
        }
    ]);

    if (queries[0].isSuccess) {
        // 공용코드 맵
        const codeMap = queries[0].data.data;
        console.log(queries[0]);
        for (let i = 0; i < approvalTypeCodeList.length; i++) {
            approvalTypeList.push(codeMap[approvalTypeCodeList[i]].info)
        }
    }

    const [select, setSelect] = useState(approvalTypeNo[location.state]);

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Card>
                <Grid container p={1}>
                    <Grid item mt={1} ml={3} mr={3} xs={12} xl={12} lg={12}>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
                            <SoftBox>
                                <Badge color="error" badgeContent={count} sx={{paddingRight:1,}} >
                                    <SoftTypography variant="h5" gutterBottom fontWeight="bold">
                                        문서 수신함
                                    </SoftTypography>
                                </Badge>
                            </SoftBox>
                         
                        </SoftBox>
                    </Grid>
                    <Grid item mb={2} ml={3} mr={3} xs={12} xl={12} lg={12}>
                        <SoftBox
                            sx={{
                                "& .MuiTableRow-root:not(:last-child)": {
                                    "& td": {
                                        borderBottom: ({borders: {borderWidth, borderColor}}) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    },
                                },
                            }}
                        >
                            {/* {
                                
                                select === 0 ? <AllReceiveDocs/>
                                    // 비용처리
                                    : (select === 1) ? <CostReceiveDocs/>
                                        // 품의
                                        : (select === 2) ? 
                                            <p>2</p>
                                        : // 복지포인트
                                            <WpReceiveDocs/>
                            } */}

                            {/* 아래 수정 항목 --> 여러 개의 결재 처리할 때 복지포인트 제외 AllReceiveDocs 페이지로 넘어가게 처리 */}
                            { 
                                // 복지포인트
                                (select === 3) ? <WpReceiveDocs/>
                                    // 모든 항목
                                    : <AllReceiveDocs/> 
                            }
                        </SoftBox>
                    </Grid>
                </Grid>
            </Card>
            <Footer/>
        </DashboardLayout>
    );
}

export default ApprovalReceive;
