import {atom} from "recoil";

/**
 * 휴가 결재 상신함 문서 상태
 * @type {RecoilState<number>}
 */
export const vacationSentState = atom({
    key: "vacationSentState"
    , default: {
        nowPage: 1,
        docsNum: 10,
        ascending: 'DESC',
        searchEndStart: '',
        searchEndEnd: '',
        searchStartStart: '',
        searchStartEnd: '',
        searchName: '',
        searchTeam: '',
        searchCategory: '',
        searchOveruse: 0,
        approvedList: ['AP001', 'AP002', 'AP003', 'AP004','AP006', 'AP008', 'AP009'],
    }
})