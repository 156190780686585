import "../../styles/datePicker/DatePicker.css";

import {useEffect, useState} from "react";
import {useQueries} from "react-query";
import DatePicker from "react-datepicker";
import {ko} from "date-fns/esm/locale";
import 'react-datepicker/dist/react-datepicker.css';
import {add, getDay, getYear} from "date-fns";
import GetNowHolyDay from "../../api/date/GetNowHolyDay";

import GetNextHolyDay from "../../api/date/GetNextHolyDay";
import {DateToString} from "../../utils/DateToString";
import {useRecoilState} from "recoil";
import {selectDaysAtomFamily} from "../../atom/calendar/selectDaysAtomFamily";
import {toMap} from "../../utils/ToMap";
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import borders from "../../assets/theme/base/borders";
import Grid from "@mui/material/Grid";
import typography from "../../assets/theme/base/typography";
import {StringToDate} from "../../utils/DateUtils";

/**
 * 키 이름과 휴가 타입 공용코드를 받아서 휴가 레인지 지정
 * @param keyName : String 키 이름
 * @param vacationType : String 휴가 타입 공용코드
 * @param defaultStart : String 기본 시작일
 * @param defaultEnd : String 기본 종료일
 * @returns {JSX.Element}
 * @constructor
 */
const DateRangePicker = ({keyName, vacationType, defaultStart, defaultEnd, isWithdraw}) => {
    // 보더 설정값
    const {borderWidth, borderColor} = borders;
    // size 설정값
    const {size} = typography;

    // key값
    const [filteredHolyDayList, setFilteredHolyDayList] = useState([]);

    // 반차 여부
    const [halfUse, setHalfUse] = useState(false);

    // datePicker 관련 state
    const [dateRange, setDateRange] = useState([new Date(), new Date()]);
    const [startDate, endDate] = dateRange;

    // 산출된 일 수 저장
    const [useVacationDay, setUseVacationDay] = useRecoilState(selectDaysAtomFamily(keyName));

    // 오늘 연도와 다음해 연도
    const toDayYearAndMonth = {
        toDayYear: getYear(new Date()).toString()
        , nextYear: getYear(add(new Date(), {years: 1})).toString()
    }

    // 반차 일때 readonly 활성화
    useEffect(() => {
        if (vacationType === "VT004" || vacationType === "VT005") {
            setHalfUse(true);
            if (defaultStart !== undefined && defaultStart !== '') {
                defaultValueSet();
            } else {
                setDateRange([startDate, startDate]);
            }
        } else {
            setHalfUse(false);
            if (defaultStart !== undefined && defaultStart !== '') {
                defaultValueSet();
            } else {
                setDateRange([startDate, endDate]);
            }
        }
    }, [vacationType])

    // 처음 시작시 디폴트값 세팅
    useEffect(() => {
        defaultValueSet();
    }, [defaultStart]);

    // 처음 시작시 디폴트값 세팅
    const defaultValueSet = () => {
        if (defaultStart !== undefined && defaultStart !== '' && defaultEnd !== undefined && defaultEnd !== '') {
            if(vacationType === "VT004" || vacationType === "VT005"){
                setDateRange([StringToDate(defaultStart), StringToDate(defaultStart)]);
            } else {
                setDateRange([StringToDate(defaultStart), StringToDate(defaultEnd)]);
            }
            const useDay = toMap(useVacationDay);
            useDay.set("start", startDate);
            useDay.set("end", endDate);
            setUseVacationDay(Object.fromEntries(useDay));
        }
    };

    // 당해년도 내년도 공휴일
    useQueries([
        {
            queryKey: ["GetNowHolyDay"],
            queryFn: () => GetNowHolyDay(toDayYearAndMonth),
            onSuccess: (result) => {
                try {
                    const nowYearHolyDayList = result.data.response.body.items.item;
                    const filterHolyList = [];
                    for (let i = 0; i < nowYearHolyDayList.length; i++) {
                        // 제헌절이 리스트에 있으나 공휴일이 아니고 N으로 표시되어있어서 Y 인것만
                        if (nowYearHolyDayList[i].isHoliday === "Y") {
                            const map = new Map;
                            map.set("name", nowYearHolyDayList[i].dateName);
                            map.set("date", nowYearHolyDayList[i].locdate);
                            filterHolyList.push(map);
                        }
                    }
                    const saveList = [...filteredHolyDayList];
                    setFilteredHolyDayList(saveList.concat(filterHolyList));
                } catch (e) {
                    console.log("now holy day list load error - " + e);
                }
            },
        },
        {
            queryKey: ["GetNextHolyDay"],
            queryFn: () => GetNextHolyDay(toDayYearAndMonth),
            onSuccess: (result) => {
                try {
                    const nextYearHolyDayList = result.data.response.body.items.item;
                    const filterHolyList = [];
                    for (let i = 0; i < nextYearHolyDayList.length; i++) {
                        // 제헌절이 리스트에 있으나 공휴일이 아니고 N으로 표시되어있어서 Y 인것만
                        if (nextYearHolyDayList[i].isHoliday === "Y") {
                            const map = new Map;
                            map.set("name", nextYearHolyDayList[i].dateName);
                            map.set("date", nextYearHolyDayList[i].locdate);
                            filterHolyList.push(map);
                        }
                    }
                    const saveList = [...filteredHolyDayList];
                    setFilteredHolyDayList(saveList.concat(filterHolyList));
                } catch (e) {
                    console.log("now holy day list load error - " + e);
                }
            },
        }
    ])

    // 시작일과 종료일을 통해 총 일 수 구하기
    useEffect(() => {

        if (endDate !== null) {
            // 시작일과 종료일 같음 여부
            let equal = false;

            // 시작일과 종료일의 차이
            let diff = 0;
            if (DateToString(startDate) !== DateToString(endDate)) {
                diff = diffDays(startDate, endDate) + 1;
            } else {
                equal = true;
            }

            // 공휴일 카운트
            let holyDayCount = 0;
            let weekCount = 0;

            // 요일값 비교
            if (startDate.getDay() <= endDate.getDay()) {
                if (Math.floor(diff / 7) > 0 || diff % 7 === 0) {
                    weekCount = weekCount + Math.floor(diff / 7);
                }
            }
            if (startDate.getDay() > endDate.getDay()) {
                if (Math.floor(diff / 7) > 0 || diff % 7 === 0) {
                    weekCount = weekCount + Math.floor(diff / 7);
                } else {
                    weekCount = weekCount + 1;
                }
            }

            // 공휴일 체크
            for (let i = 0; i < filteredHolyDayList.length; i++) {
                const fullDate = String(filteredHolyDayList[i].get('date'));
                let year = fullDate.substring(0, 4);
                let month = fullDate.substring(4, 6) - 1;
                let day = fullDate.substring(6, 8);
                let targetDate = new Date(year, month, day);
                // 공휴일이 시작일과 종료일 사이에 있으면 +1
                if (dateExists(targetDate, startDate, endDate)) {
                    holyDayCount++
                }
            }

            // set
            const useDay = toMap(useVacationDay);
            if (vacationType === "VT004" || vacationType === "VT005") {
                useDay.set("day", 0.5);
            } else if (equal) {
                useDay.set("day", diff - holyDayCount - (weekCount * 2) + 1);
            } else {
                useDay.set("day", diff - holyDayCount - (weekCount * 2));
            }
            useDay.set("start", DateToString(startDate));
            useDay.set("end", DateToString(endDate));
            setUseVacationDay(Object.fromEntries(useDay));
        }
    }, [dateRange, vacationType]);

    // 캘린더 필터(공휴일 선택못하도록)
    const isHolyday = (date) => {
        // date 요일
        const day = getDay(date);
        let checkFilterDate;
        for (let i = 0; i < filteredHolyDayList.length; i++) {
            // 공휴일의 날짜 가져와서 date형식으로 만들기
            const fullDate = String(filteredHolyDayList[i].get('date'));
            let year = fullDate.substring(0, 4);
            let month = fullDate.substring(4, 6) - 1;
            let day = fullDate.substring(6, 8);
            let targetDate = new Date(year, month, day);
            // date와 공휴일이 년월일이 같으면 해당하는 date값을 set
            if (date.getFullYear() === targetDate.getFullYear()
                && date.getMonth() === targetDate.getMonth()
                && date.getDate() === targetDate.getDate()
            ) {
                checkFilterDate = targetDate;
            }
        }
        return day !== 0 && day !== 6 && checkFilterDate === undefined;
    };


    /**
     * 두 날짜의 차이
     * @param date1 : Date
     * @param date2 : Date
     * @returns {number}
     */
    function diffDays(date1, date2) {
        const diffTime = Math.abs(date2 - date1);
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }

    /**
     * 두 날짜 사이에 해당하는 날짜가 존재하는지 확인
     * @param date : Date
     * @param startTargetDate : Date
     * @param endTargetDate : Date
     * @returns {boolean}
     */
    function dateExists(date, startTargetDate, endTargetDate) {
        return date >= startTargetDate && date <= endTargetDate;
    }

    return (
        <>
            <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                <Grid item xs={12} md={12} lg={12}>
                    <SoftBox display="flex" alignItems="center">
                        <SoftBox
                            bgColor="info"
                            width="1.25rem"
                            height="1.25rem"
                            borderRadius="sm"
                            color="white"
                            fontSize={size.lg}
                            shadow="md"
                            mr={1}
                            mt={-0.7}
                            variant="gradient"
                        >
                            <Icon>edit_calendar</Icon>
                        </SoftBox>
                        <SoftTypography
                            variant="h6" gutterBottom fontWeight="bold"
                        >
                            휴가일정
                        </SoftTypography>
                    </SoftBox>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Tooltip title="휴가 일정 선택" placement="top">
                        <SoftBox
                            border={`${borderWidth[1]} solid ${borderColor}`}
                            borderRadius="lg"
                            display="flex"
                            justifyContent="space-around"
                            alignItems="center"
                            p={1}
                            width="100%"
                            sx={{
                                cursor: "pointer",
                                ':hover': {
                                    backgroundColor: "#B9E4EE",
                                    borderColor: "#35d1f5",
                                    borderWidth: borderWidth[4]
                                }
                            }}
                            onClick={() => {
                                if (isWithdraw) return;
                                document.getElementsByClassName("input-datepicker")[0].click()
                            }}
                        >
                            {endDate !== null && startDate !== null
                                ?
                                <>
                                    <SoftBox p={1} display="flex" justifyContent="space-between" alignItems="center">
                                        <SoftBox mb={-1} mr={2}>
                                            <Icon fontSize="3rem">flight_takeoff</Icon>
                                        </SoftBox>
                                        <SoftTypography variant="h6" fontWeight="medium">
                                            {DateToString(startDate)}
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox p={1} display="flex" justifyContent="space-between" alignItems="center">
                                        <SoftBox mb={-1} mr={2}>
                                            <Icon fontSize="3rem">flight_land</Icon>
                                        </SoftBox>
                                        <SoftTypography variant="h6" fontWeight="medium">
                                            {DateToString(endDate)}
                                        </SoftTypography>
                                    </SoftBox>
                                </>
                                :
                                <SoftTypography variant="h6" fontWeight="medium">
                                    휴가종료일을 선택하지 않았습니다. 종료일을 선택해주세요
                                </SoftTypography>
                            }
                        </SoftBox>
                    </Tooltip>
                    <DatePicker
                        locale={ko}    // 언어설정 기본값은 영어
                        dateFormat="yyyy-MM-dd"    // 날짜 형식 설정
                        placeholderText="휴가 일자 선택"    // placeholder
                        selectsRange={halfUse === false} // 레인지로 선택
                        showYearDropdown // 연도 선택
                        showMonthDropdown // 달 선택
                        filterDate={isHolyday} // 필터되는 날짜
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                            if (halfUse) {
                                setDateRange([update, update]);
                            } else {
                                setDateRange(update);
                            }
                        }}
                        withPortal
                        className="input-datepicker"
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default DateRangePicker;