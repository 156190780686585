import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import moment from "moment";
import { Icon } from "@mui/material";

// api
// atom
import { loginUser } from "atom/member/loginUser";
// wp components
import WpThisYearList from "./components/WpThisYearList";
import WpPointCard from "./components/WpPointCard.js";
// api
// components 
import Swal from "sweetalert2";
import WpWriteAndTemporary from "./components/WpWriteAndTemporary.js";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";



const WPMainPage = () => { 
    const user = useRecoilValue(loginUser);
    const navigate = useNavigate();
    
    if (user.joinDate === undefined) {
        Swal.fire({
            icon: 'error',
            title: '에러',
            text: '입사일을 먼저 입력해주세요',
            backdrop : false
          })
        navigate('/profile')
    }

    const today = new Date();

    /** 3개월 후 */
    const threeMonthAgo = new Date(today.setMonth(today.getMonth() - 3));

    /** 복지포인트 사용 가능 여부 (입사일로부터 3개월 이후 일 경우) */
    const isUseWp = threeMonthAgo > new Date(user.joinDate);

    const listBtn = () =>{
        navigate('/welfarePoint/list')
    }


    return (
        <> 
        <DashboardLayout>
            <DashboardNavbar/>
            {/*<Card*/}
            {/*    sx={{*/}
            {/*    backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),*/}
            {/*    boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,*/}
            {/*    position: "relative",*/}
            {/*    mt: 2,*/}
            {/*    mx: 0,*/}
            {/*    py: 2,*/}
            {/*    px: 2,*/}
            {/*    }}*/}
            {/*    >*/}
            {/*    <Grid container spacing={3} alignItems="center" display="flex" justifyContent="space-between">*/}
            {/*        <Grid item>*/}
            {/*            <SoftBox height="100%" mt={0.5} lineHeight={1}>*/}
            {/*                <SoftTypography variant="h5" fontWeight="bold">*/}
            {/*                    {today}*/}
            {/*                </SoftTypography>*/}
            {/*            </SoftBox>*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*</Card>*/}

            <SoftBox pt={1} pb={2}>
                <WpPointCard/>
            </SoftBox>

            { isUseWp ?
                (
                    <SoftBox>
                        <WpWriteAndTemporary />
                    </SoftBox>
                )
             : <></>
            }
            
            <SoftBox pb={1}>
                <Card  sx={{
                    backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
                    boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                    position: "relative",
                    mt: 2,
                    mx: 0,
                    mb : 4,
                    py: 2,
                    px: 2,
                    }}
                    >
                    <Grid container spacing={3} alignItems="center" display="flex" justifyContent="space-between">
                        <Grid item >
                            <SoftBox display="flex" justifyContent="space-between" alignItems="center" pl={2}>
                                <SoftTypography variant="h5" fontWeight="bold">
                                    이번 연도 작성 목록  
                                </SoftTypography>
                                <SoftTypography variant="overline" fontWeight="medium" pl={1}>
                                    ( 최근 작성한 5개의 문서만)
                                </SoftTypography>
                            </SoftBox>
                        </Grid>
                        <Grid item>
                            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
                                <SoftButton color="dark" variant="gradient" onClick={listBtn}>
                                    <Icon sx={{fontWeight: "bold" }}>format_list_bulleted</Icon>
                                        &nbsp;전체 목록
                                </SoftButton>
                            </SoftBox>
                        </Grid>
                    </Grid>
                    <WpThisYearList/>
                </Card>
            </SoftBox>
            <Footer/>

        </DashboardLayout>
        </>
    );
};



export default WPMainPage
