import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import Swal from "sweetalert2";
import moment from "moment";
import { useRecoilValue } from "recoil";
import { Document, Page, Text, PDFViewer, StyleSheet, View, Font, Image } from '@react-pdf/renderer';

// api
import wpDetail from "../../api/wp/wpDetail";
import allCommonCodeMap from "api/commonCode/allCommonCodeMap";
import wpDelete from "../../api/wp/wpDelete";

// atom
import { loginUser } from "atom/member/loginUser";

// component
import { customAxios } from '../../axios/CustomAxios'
import { myIpAddress } from "config";
import CostWriterInput from "../../layouts/costInsert/components/CostWriterInput";
import WpDetailTable from "./components/WpDetailTable";
import ApprovalList from "../../layouts/costInsert/components/ApprovalList";

// util
import { DateFormat } from "utils/DateFormat";
import { AmountFormat } from "utils/AmountFormat";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Icon, IconButton, Modal } from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

// Soft UI Dashboard React examples
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout/index.js";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import Footer from "examples/Footer";
import SoftBadge from "components/SoftBadge";
import wpApproval from "../../api/wp/wpApproval";
import wpApprovalReject from "../../api/wp/wpApprovalReject";
import wpApprovalCancel from "../../api/wp/wpApprovalCancel";
import { useState } from "react";
import malgun from "../../assets/font/malgun.ttf";
import { getFormattedDateTitle } from "layouts/wp/components/getFormattedDateTitle";
import wpTemporaryStore from "api/wp/wpTemporaryStore";
import rejectViewCheckUpdate from "../../api/approval/rejectViewCheckUpdate";
import { Print } from "@mui/icons-material";
import ReactToPrint from "react-to-print";
import WpBills from "./components/WpBills";


/**
 * 
 * @returns 
 */
const WPDetailPage = () => {
    const navigate = useNavigate();
    
    const user = useRecoilValue(loginUser);
    
    const location = useLocation();

    const componentRef = useRef(null);
    
    const wp_no = location.state;

    const [rejectDocCheck, setRejectDocCheck] = useState(true);

    /** 상세보기 테이블 칼럼 */
    const columns = [
		{ key: "wp_usedate" , name: "사용일", align: "left" , color : "warning" , icon : "event_available"},
		{ key: "wp_category" , name: "사용처", align: "center" , color : "success" , icon : "fact_check"},
		{ key: "wp_content" , name: "상세내역", align: "center" , color : "info" , icon : "notes"},
		{ key: "wp_filename" , name: "영수증", align: "left" , color : "secondary" , icon : "insert_photo"}, 
		{ key: "wp_usedpoint" , name: "금액", align: "center" , color : "primary" , icon : "paid"},
		{ key: "wp_paytype" , name: "결제수단", align: "center" , color : "dark" , icon : "payment"},
	]

    /** 상세보기 테이블 row */
    const rows = []
    /** 복지포인트 합계 */
    let totalpoint;
    /** 작성일 */
    let wp_date;


    /** 결재자 정보 */
    let approvalList;
    /** 결재 번호 */
    let approval_no;
    /** 결재 상태 */
    let approval_state;
    /** 결재 여부? */
    let approval_do;
    /** 결재자 번호 */
    let approver_mem_no;
    /** 개인 비용*/
    let myCardsumAmount = 0;
    /** 법인 비용*/
    let comCardsumAmount = 0;
    
    /** PDF 제목 */
    let PDFtitle;
    /** 결재자 번호 */
    //let approver_mem_no;
    /** 결재자 번호 */
    //let approver_mem_no;

    // 영수증 이미지 리스트
    let imgList = [];

    
    /** 상세 내용 불러오기  */
    const { data } = useQuery(['wpDetail', { wp_no : parseInt(wp_no) }], ()=> wpDetail({ wp_no : parseInt(wp_no) }), {
        select : (data) => {
            return data.data;
        },
        onError: (data) =>{
            Swal.fire({
                text: '상세 내용 로드 중 에러가 발생했습니다.',
                icon: 'error',
                backdrop: false,
            }).then(() => {
                navigate('/welfarePoint/list',);
            })
        }
    });
    /** 공용코드   */
    const { data:info} = useQuery('allCommonCodeMap', allCommonCodeMap, {
        select : (data) => {
            return data.data;
        }
    });

    // 반려문서 열람여부 업데이트
    const {mutate: rejectViewCheckBody} = useMutation(rejectViewCheckUpdate, {
        onSuccess: (data) => {

        },
        onError: () => {
            console.log('반려문서 열람 업데이트 중 에러')
        }
    });

    if(data && info){
        if(data.approvalList && data.approvalList.length > 0){
            // 결재자 정보
            approvalList = data.approvalList;
            approval_no = data.approvalList[0].approval_no;
            approver_mem_no = data.approvalList[0].approver_mem_no;
            approval_do = data.approvalList[0].approval_do;
            // console.log(approvalList);
            // console.log(approver_mem_no , " 번호 ")
        }
        approval_state = info[data.wp_approved].info;

        // 합계 
        totalpoint = data.wp_totalpoint;
        // 내역 row
        let wpdDtoRow = data.wpdDto;
        // 작성일
        wp_date = moment(data.wp_date).format('YYYY-MM-DD');

        // row 데이터 변환
        wpdDtoRow.map((list) =>{
            let row ={}
            columns.map((col)=>{
                if(col.key === "wp_usedate"){
                    row[col.name] = DateFormat(list[col.key])
                } else if(col.key === "wp_usedpoint"){
                    row[col.name] = AmountFormat(list[col.key])
                    // PDF 문서 비용 때문에 추가
                    if(list["wp_paytype"] === "PM002"){
                        comCardsumAmount += list["wp_usedpoint"]
                    } else {
                        myCardsumAmount += list["wp_usedpoint"]
                    }
                } else if(col.key === "wp_category" || col.key === "wp_paytype"){
                    if(list[col.key]){
                        row[col.name] = info[list[col.key]].info
                    }
                // 영수증 이미지가 없을 때
                } else if (col.key === "wp_filename_real" && list["wp_filename_real"] !== null) {
                    row[col.name] = list[col.key]
                } else if (col.key === "wp_filename" && list["wp_filename_real"] === null) {
                    row[col.name] = null
                } else {
                    row[col.name] = list[col.key]
                }
            })

            // 영수증 이미지가 있을 때
            if(list["wp_filename_real"] != null) {
                row["wp_filename_real"] = list["wp_filename_real"]
                imgList.push(row["wp_filename_real"]) //영수증 이미지 리스트 --> 프린트 수정 기능
            }

            rows.push(row)
        })

        // PDF 제목
        // moment(wp_date).format('MM월') +  <<- 전월달 복지포인트 사용 명세서 뽑을때 6월 사용분인데 7월 사용분으로 나오기때문에 월 제외
        PDFtitle = "복지포인트 사용 명세서"

        // 반려문서일때 해당하는 문서가 로그인 사람 문서일때 반려문서 확인처리
        if (rejectDocCheck && data?.wp_approved === "AP004" && user?.id === data?.mem_no) {
            const rejectViewBody = {
                mem_no: data?.mem_no,
                approval_no: data?.approval_no
            }
            rejectViewCheckBody(rejectViewBody);
            setRejectDocCheck(false);
        }
    }

    /** 상신 문서 결재 승인 */
    const handleSignUp = () => {
        const body = {
            doc_no: wp_no
            , approval_no: approval_no
            , wp_no: wp_no
            , mem_no : user?.id
            , wp_approved: 'AP003'
            , doc_type: 'APT003'
            , wp_date: wp_date
            , approval_do: approval_do
            , approver_mem_no: approver_mem_no
        }

        Swal.fire({
            title: '결재 승인 처리 하시겠습니까?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '결재승인',
            backdrop: false

        }).then((result) => {
            if (result.isConfirmed) {
                addSignUpBody(body);
            }
        })
    }

     // 상신 문서 결재
    const {mutate:addSignUpBody} = useMutation(wpApproval, {
        onSuccess: (data) => {
            Swal.fire({
                title: '정상처리 되었습니다.',
                icon: 'success',
                //showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: '목록으로 이동',
                backdrop: false
            }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/welfarePointAdmin', {state: 'APT003'})
                    }
                }
            )
        },
        onError: () => {
            Swal.fire({
                title: '문서 결재 중 에러가 발생했습니다. 잠시후 다시 실행해주세요',
                icon: 'error',
                backdrop: false
            })
        }
    });

    /** 상신 문서 결재 반려 */
    const handleReject = () => {
        Swal.fire({
            title: '결재 반려 처리 하시겠습니까?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '결재반려',
            backdrop: false

        }).then((result) => {
            if (result.isConfirmed) {
                (async () => {
                    const {value: getReason} = await Swal.fire({
                        title: '결재 반려 사유를 입력해주세요.',
                        text: '100자 이내로 작성해주십시오.',
                        input: 'text',
                        inputPlaceholder: '결재 반려사유 ',
                        backdrop: false,
                    })

                    // 이후 처리되는 내용.
                    if (getReason) {
                        // 마지막 결재일때 결재 완료 처리
                        const body = {
                            doc_no: wp_no
                            , approval_no: approval_no
                            , wp_no: wp_no
                            , wp_approved: 'AP004'
                            , approval_do: approval_do
                            , approver_mem_no: approver_mem_no
                            , approval_cancel_reason: getReason
                        }
                        addRejectBody(body);
                    }
                })()
            }
        })
    }


    // 상신 문서 결재 반려
    const {mutate:addRejectBody} = useMutation(wpApprovalReject, {
        onSuccess: (data) => {
            Swal.fire({
                title: '정상처리 되었습니다.',
                icon: 'success',
                //showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: '목록으로 이동',
                backdrop: false
            }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/welfarePointAdmin')
                    }
                }
            )
        },
        onError: () => {
            Swal.fire({
                title: '문서 결재 반려 중 에러가 발생했습니다. 잠시후 다시 실행해주세요',
                icon: 'error',
                backdrop: false,
            })
        }
    });


    /** 상신 취소  */
    function handleCancel() {
        Swal.fire({
            icon: 'warning',
            title: '상신취소 하시겠습니까?',
            backdrop: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '상신취소',
            showCancelButton: true,
            reverseButtons: false,
            focusCancel: true,
        }).then((result) => {
            if (result.isConfirmed) {
                const body = { 
                    wp_no: wp_no , 
                    approval_no : approval_no,
                    wp_approved : 'AP006' ,
                };
                cancelBody(body);
            }
        })
    }

    const {mutate:cancelBody} = useMutation(wpApprovalCancel, {
        onSuccess: (data) => {
            Swal.fire({
                title: '정상처리 되었습니다.',
                icon: 'success',
                //showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: '목록으로 이동',
                backdrop: false
            }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/welfarePoint/list', { state: { updated: true } });
                    }
                }
            )
        },
        onError: () => {
            Swal.fire({
                title: '상신취소 중 에러가 발생했습니다. 잠시후 다시 실행해주세요',
                icon: 'error',
                backdrop: false,
            })
        }
    });

    /** 임시저장 삭제 */
    function handleDelete () {
        Swal.fire({
            icon: 'error',
            title: '삭제 하시겠습니까?',
            backdrop: false,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '삭제하기',
        }).then((result) => {
            if (result.isConfirmed) {
                const body = { 
                    wp_no: wp_no , 
                    approval_no : approval_no, 
                };
                deleteBody(body)
            }
        })
    }

    const {mutate:deleteBody} = useMutation(wpDelete, {
        onSuccess: (data) => {
            Swal.fire({
                title: '정상처리 되었습니다.',
                icon: 'success',
                //showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: '목록으로 이동',
                backdrop: false
            }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/welfarePoint/list', { state: { updated: true } });
                    }
                }
            )
        },
        onError: () => {
            Swal.fire({
                title: '임시저장 삭제 중 에러가 발생했습니다. 잠시후 다시 실행해주세요',
                icon: 'error',
                backdrop: false,
            })
        }
    });


    /** 임시저장 으로 변경 */
    function handleStore () {
        Swal.fire({
            title: '문서를 보관함으로 이동하시겠습니까?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '문서보관',
            backdrop: false
        }).then((result) => {
            if (result.isConfirmed) {
                const body = { 
                    wp_no: wp_no , 
                    wp_approved : 'AP005', 
                };
                storeBody(body)
            }
        })
    }

    const {mutate:storeBody} = useMutation(wpTemporaryStore, {
        onSuccess: (data) => {
            Swal.fire({
                title: '정상처리 되었습니다.',
                icon: 'success',
                //showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: '목록으로 이동',
                backdrop: false
            }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/welfarePoint/list', { state: { updated: true } });
                    }
                }
            )
        },
        onError: () => {
            Swal.fire({
                text: '문서 보관함 이동 중 에러가 발생했습니다. 잠시후 다시 실행해주세요',
                icon: 'error',
                backdrop: false,
            })
        }
    });

    // modal open PDF 문서 
    const [modalPDFOpen, setModalPDFOpen] = useState(false);

    /**
     * modal close
     * @param event
     */
    const modalPDFClose = (event) => {
        if (event.target === event.currentTarget) {
            setModalPDFOpen(false);
        }
    };

    const pdfStyles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            paddingTop: 30,
            paddingBottom: 30,
            paddingHorizontal: 18,
        },
        header: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        tableRowItem: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        tableColHeaderText: {
            fontFamily: 'malgun',
            textAlign: 'center',
            fontSize: '13px',
            flexWrap: 'wrap'
        },
    });

    let wpPDFcolumns = [
        { key: "번호", width: '7%' },
        { key: "사용일", width: '10%' },
        { key: "사용처", width: '15%' },
        { key: "상세내역", width: '40%' },
        { key: "금액", width: '15%' },
        { key: "결제수단", width: '13%' },
    ]
    Font.register({
        family: 'malgun',
        src: malgun,
    });

    const PDFWpView = () => {
        const companyLogo = "/company-logo.jpeg";
        return (
            <Document title="복지포인트 사용 명세서">
                <Page size="A4" style={pdfStyles.page}>
                    {/* 상단 로고 추가 */}
                    <View style={{ position:"absolute",left:15, top:15 }}>
                        <Image src={companyLogo} style={{ width: 75}}/>
                    </View>
                    <View style={{ marginBottom: 10 }}>
                        <Text style={[pdfStyles.tableColHeaderText, { fontSize: 17 }]}>
                            {PDFtitle}
                        </Text>
                    </View>
                    <View style={[pdfStyles.header, { marginBottom: 4 }]}>
                        <Text style={[pdfStyles.tableColHeaderText, { marginLeft: 8 }]}>
                            성명 : {data && data.mem_name}
                        </Text>
                        <Text style={[pdfStyles.tableColHeaderText, { marginRight: 8 }]}>{data && wp_date}</Text>
                    </View>
                    <View style={{ alignItems: 'center', width: '100%'}}>
                        <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "99%", borderRight: '1px solid black', borderLeft: '1px solid black', }}>
                            <View style={{ width: '98%', borderTop: '1px solid black', borderBottom: '1px solid black', }}>
                                <View style={{ flexDirection: 'row' }}>
                                    {wpPDFcolumns.map((item, idx) =>
                                        <View key={idx} style={{ width: item.width, paddingBottom: 3, paddingTop: 1, backgroundColor: '#e8fcf2', borderRight: idx === columns.length ? 'none' : '1px solid black' }}>
                                            <Text style={pdfStyles.tableColHeaderText}>{item.key}</Text>
                                        </View>
                                    )}
                                </View>
                                {rows && rows.length > 0 ? rows.map((item, idx) =>
                                    <View style={{ flexDirection: 'row', borderTop: '1px solid black', flexWrap: 'wrap' }} key={idx}>
                                        <View style={{ width: wpPDFcolumns[0].width, borderRight: '1px solid black' }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 }]}>{idx + 1}</Text>
                                        </View>
                                        <View style={{ width: wpPDFcolumns[1].width, borderRight: '1px solid black' }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 }]}>{moment(item[wpPDFcolumns[1].key]).format('MM/DD')}</Text>
                                        </View>
                                        <View style={{ width: wpPDFcolumns[2].width, borderRight: '1px solid black' ,}}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 , textAlign:'left', paddingLeft : 2}]}>{item[wpPDFcolumns[2].key]}</Text>
                                        </View>
                                        <View style={{ width: wpPDFcolumns[3].width, borderRight: '1px solid black', flexWrap: 'wrap' }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2,  flexWrap: 'wrap', wordWrap: 'break-word' , paddingLeft : 2 ,textAlign : 'left'}]}>{item[wpPDFcolumns[3].key]}</Text>
                                        </View>
                                        <View style={{ width: wpPDFcolumns[4].width, borderRight: '1px solid black' , }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2, paddingRight : 2 ,textAlign : 'right' }]  }>{item[wpPDFcolumns[4].key]}</Text>
                                        </View>
                                        <View style={{ width: wpPDFcolumns[5].width,  }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 2, paddingTop: 2 }]}>{item[wpPDFcolumns[5].key]}</Text>
                                        </View>
                                    </View>
                                ) : null}
                             
                            </View>
                            <View style={{ width: '98%', borderTop: '1px solid black', borderBottom: '1px solid black' }}>
                                {data && 
                                <>
                                    <View style={{ flexDirection: 'row' }}>
                                        <View style={{ width: '77%', borderRight: '1px solid black', borderBottom: '1px solid black' }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 }]}>개 인 비 용</Text>
                                        </View>
                                        <View style={{ width: '23%' , borderBottom: '1px solid black'}}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 , paddingRight : 3 ,textAlign : 'right'  }]}>{myCardsumAmount?.toLocaleString()}</Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row' , borderBottom: '1px solid black'}}>
                                        <View style={{ width: '77%', borderRight: '1px solid black' }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 }]}>법 인 비 용</Text>
                                        </View>
                                        <View style={{ width: '23%' ,}}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 , paddingRight : 3 ,textAlign : 'right'  }]}>{comCardsumAmount?.toLocaleString()}</Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row' }}>
                                        <View style={{ width: '77%', borderRight: '1px solid black' }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 }]}>사  용  총  액</Text>
                                        </View>
                                        <View style={{ width: '23%' }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 4, paddingTop: 4 , paddingRight : 3 ,textAlign : 'right'  }]}>{data.wp_totalpoint.toLocaleString()}</Text>
                                        </View>
                                    </View>
                                </>
                                }
                                <View style={{ flexDirection: 'row', borderTopWidth: 1, borderTopColor: 'black' }}>
                                    <View style={{ width: '50%' }}>
                                        <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 5, paddingTop: 5 }]}>작 성 자 : {data && data.mem_name}</Text>
                                    </View>
                                    <View style={{ width: '25%' }}>
                                        <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 5, paddingTop: 5 }]}>서 명 : </Text>
                                    </View>
                                    <View style={{ width: '25%', }}>
                                        <Image src={data && data.signature} style={{ width: 28, height: 28, borderRadius: 5 }} />
                                    </View>
                                </View>
                                {approvalList.length > 0 && approvalList.map((item, idx) =>
                                    <View style={{ flexDirection: 'row', borderTopWidth: 1, borderTopColor: 'black' }}>
                                        <View style={{ width: '50%' }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 5, paddingTop: 5 }]}>{idx + 1}차 결재자 : {item.approver_name}</Text>
                                        </View>
                                        <View style={{ width: '25%' }}>
                                            <Text style={[pdfStyles.tableColHeaderText, { paddingBottom: 5, paddingTop: 5 }]}>서 명 : </Text>
                                        </View>
                                        <View style={{ width: '25%', }}>
                                            {item.approver_do === 1 &&
                                                <Image src={item.approver_signature ? item.approver_signature : null} style={{ width: 28, height: 28, borderRadius: 5 }} />
                                            }
                                        </View>
                                    </View>
                                )}
                            </View>
                        </View>
                    </View>
                </Page>
            </Document>
        );
    };

    //console.log(approvalList[0].approver_name);
    return (
        <>
            <DashboardLayout>
                <DashboardNavbar/>

                <Card
                    sx={{
                    backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
                    boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                    position: "relative",
                    mt: 2,
                    mx: 0,
                    mb : 2,
                    py: 2,
                    px: 2,
                    }}
                    >
                    <Grid container spacing={3} alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <SoftBox height="100%" mt={0.5} lineHeight={1} display={"flex"} alignItems="center">
                                <SoftTypography variant="h5" fontWeight="bold" mr={0.5}>
                                    복지포인트 상세
                                </SoftTypography>
                                <SoftBadge 
                                    variant="contained" 
                                    badgeContent={approval_state} 
                                    color={ approval_state === "결재완료" ? "success" : approval_state === "상신문서" ? "info" : approval_state === "임시보관" ? "secondary" : "error"} size="xs" container 
                                    sx={{ display: "inline-block", width: "max-content" }}
								/>
                            </SoftBox>
                        </Grid>
                        <Grid item>
                            <Grid container style={{ display: "flex", gap: "5px",flexWrap: "nowrap" }}>
                                {   
                                    // 로그인 유저 번호랑 data 유저 번호랑 같을 때
                                    data && data.mem_no === user.id ? (
                                        // 임시 저장 상태일때 
                                        data.wp_approved === "AP005" ? (
                                            <>
                                                <SoftButton variant="gradient" color="error" onClick={handleDelete}>
                                                    <Icon sx={{ fontWeight: "bold", marginLeft: -1 }}>cancel</Icon>
                                                    문서 삭제
                                                </SoftButton>
                                                <SoftButton variant="gradient" color="primary" onClick={() => navigate('/welfarePoint/edit', { state: wp_no })}>
                                                    <Icon sx={{ fontWeight: "bold", marginLeft: -1 }}>edit_note</Icon>
                                                    문서 수정
                                                </SoftButton>
                                            </>
                                        ) : (
                                            // 상신문서 상태 일때
                                            data.wp_approved === "AP001" ? (
                                                <>
                                                    <SoftButton variant="gradient" color="error" onClick={handleCancel}>
                                                        <Icon sx={{ fontWeight: "bold", marginLeft: -1 }}>cancel</Icon>
                                                        상신 취소
                                                    </SoftButton>
                                                    <SoftButton variant="gradient" color="info" onClick={handleStore}>
                                                        <Icon sx={{ fontWeight: "bold" }}>archive</Icon>
                                                        &nbsp;임시 보관
                                                    </SoftButton>
                                                </>
                                            ) : (
                                                // 상신취소 문서 상태 일때
                                                data.wp_approved === "AP006" ? (
                                                    <>
                                                        <SoftButton variant="gradient" color="primary" onClick={() => navigate('/welfarePoint/edit', { state: wp_no })}>
                                                            <Icon sx={{ fontWeight: "bold", marginLeft: -1 }}>edit_note</Icon>
                                                            문서 수정
                                                        </SoftButton>
                                                    </>
                                                ) : (
                                                    // 반려 문서 상태 일때
                                                    data.wp_approved === "AP004" ? (
                                                        <>
                                                            <SoftButton variant="gradient" color="primary" onClick={() => navigate('/welfarePoint/edit', { state: wp_no })}>
                                                                <Icon sx={{ fontWeight: "bold", marginLeft: -1 }}>edit_note</Icon>
                                                                문서 수정
                                                            </SoftButton>
                                                        </>
                                                    ) : (
                                                        // 결재완료 문서 상태 일때
                                                        data.wp_approved === "AP003" && (
                                                            <>
                                                                <SoftButton variant="gradient" color="info" onClick={() => setModalPDFOpen(true)}>
                                                                    {/* <PictureAsPdfIcon sx={{ marginLeft: -1 }} /> */}
                                                                    <Print /> 명세서
                                                                </SoftButton>
                                                                {/* 영수증 프린트 항목 : 영수증 버튼 클릭 시 WpBills 화면에 출력 */}
                                                                <ReactToPrint
                                                                    trigger={() => 
                                                                        <SoftButton variant="gradient" color="success">
                                                                            <Print /> 영수증
                                                                        </SoftButton>}
                                                                    content={() => componentRef.current}
                                                                    />
                                                                {/* 프린트되는 컴포넌트 */}
                                                                <div style={{ display: 'none' }}>
                                                                    <WpBills printRef={componentRef} imgList={imgList} />  
                                                                </div>
                                                            </>
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    ) : (
                                        data && data.wp_approved === "AP003" && (
                                            <>
                                                <SoftButton variant="gradient" color="info" onClick={() => setModalPDFOpen(true)}>
                                                    <PictureAsPdfIcon sx={{ marginLeft: -1 }} />
                                                    PDF 문서
                                                </SoftButton>
                                                {/* 영수증 프린트 항목 : 영수증 버튼 클릭 시 WpBills 화면에 출력 */}
                                                <ReactToPrint
                                                    trigger={() => 
                                                        <SoftButton variant="gradient" color="success">
                                                            <Print /> 영수증
                                                        </SoftButton>}
                                                    content={() => componentRef.current}
                                                    />
                                                {/* 프린트되는 컴포넌트 */}
                                                <div style={{ display: 'none' }}>
                                                    <WpBills printRef={componentRef} imgList={imgList} />  
                                                </div>
                                            </>
                                        )
                                    )
                                }
                                <SoftButton variant="gradient" color="dark" onClick={() => navigate('/welfarePoint/list')} >
                                    <Icon sx={{fontWeight: "bold" }}>format_list_bulleted</Icon>
                                        &nbsp;전체 목록
                                </SoftButton>
                            </Grid>
                        </Grid>
                    </Grid> 
                </Card> 
                
                <Card  
                    sx={{
                        backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
                        boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                        position: "relative",
                        mb : 2,
                    }}
                    >
                    <SoftBox px={1} pt={1}>
                        <Grid container display={"flex"} flexDirection="column">
                            <Grid item >
                                <SoftTypography variant="h5" fontWeight="bold" textTransform="capitalize" pl={2} >
                                    결재자
                                </SoftTypography>
                            </Grid>
                                
                            {  
                                <Grid container display={"flex"} flexDirection="column" >
                                    <Grid item width="inherit">
                                        <SoftBox m={2}>
                                            {   approvalList && 
                                                approvalList[0].approver_name !== null && 
                                                approvalList.map((item, idx) =>
                                                <>
                                                    <SoftBox>
                                                    <ApprovalList key={idx} item={item} length={approvalList.length} idx={idx} />
                                                    {   // 결재자 no 와 로그인한 유저 no가 같을때
                                                        user.id === approver_mem_no && 
                                                        data && 
                                                        // 상신문서 일때
                                                        (data.wp_approved === "AP001" ) &&
                                                        <Grid item display={"flex"} flexDirection="row" px={1}>
                                                            <SoftBox pr={0.5} pb={2}>
                                                                <SoftButton variant="gradient" color="success"  onClick={handleSignUp} >
                                                                    <Icon sx={{ fontWeight: "medium", marginLeft:-1 }}>check_circle</Icon>
                                                                        승인
                                                                </SoftButton>
                                                            </SoftBox>
                                                            <SoftBox  pb={2}>
                                                                <SoftButton 
                                                                    variant="gradient" 
                                                                    color="secondary"
                                                                    onClick={handleReject}
                                                                    
                                                                    >
                                                                    <Icon sx={{ fontWeight: "medium", marginLeft:-1 }}>error</Icon>
                                                                        반려
                                                                </SoftButton>
                                                            </SoftBox>
                                                        </Grid>
                                                    }
                                                    </SoftBox>
                                                </>
                                            )}
                                        </SoftBox>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </SoftBox>
                </Card>

                <Card>
                    <SoftBox pt={1}>
                        {   data && 
                            <CostWriterInput
                                url={data.imageUrl}
                                name={data.mem_name}
                                team={data.mem_team}
                                rank={data.mem_rank}
                                writer="writer"
                                />
                        }
                    </SoftBox>
                </Card>
                <SoftBox mt={3}>
                    <Card
                        sx={{
                            backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
                            boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                            position: "relative",
                            mt: 2,
                            mb : 1,
                            py: 1,
                            px: 1,
                            }}
                        >   
                            <SoftBox
                                pt={2} pb={2}
                                display="flex"
                                justifyContent="space-between"
                                sx={{
                                    flexDirection: 'row',
                                    '@media (max-width: 550px)': {
                                        flexDirection: 'column',
                                    }
                                }}
                                >
                                <SoftBox
                                    display="flex">
                                    <SoftTypography 
                                        pl={2}
                                        variant="h5"
                                        color="dark"
                                        fontWeight="bold"  
                                        > 
                                        {getFormattedDateTitle(wp_date, "복지포인트")}
                                    </SoftTypography>
                                    <SoftTypography 
                                        pl={2}
                                        variant="h5"
                                        color="dark"
                                        fontWeight="bold"  
                                        > 
                                        /                           
                                    </SoftTypography>
                                    <SoftTypography 
                                        pl={2}
                                        variant="h5"
                                        color="dark"
                                        fontWeight="bold"  
                                        > 
                                        작성일&nbsp;&nbsp;&nbsp;{wp_date}
                                    </SoftTypography>
                                </SoftBox>
                            </SoftBox>
                        <WpDetailTable columns={columns} rows={rows} totalpoint={totalpoint} wp_date={wp_date} />
                    </Card>
                </SoftBox>
                <SoftBox mt={3}>
                    <Footer/>
                </SoftBox>
                <Modal open={modalPDFOpen} onClose={() => setModalPDFOpen(false)}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}
                        onClick={modalPDFClose}>
                        <PDFViewer style={{ fontSize: "20px", width: "70%", height: "90%" }}>
                            <PDFWpView />
                        </PDFViewer>
                    </div>
                </Modal>                
            </DashboardLayout>
        </>
    );

}

export default WPDetailPage