import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import Header from "layouts/profile/components/Header";
import {useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";
import {loginUser} from "atom/member/loginUser";
import {useMutation, useQueries} from "react-query";
import {useEffect, useState} from "react";
import {customAxios} from "../../axios/CustomAxios";
import {ACCESS_TOKEN, myIpAddress} from "config";
import Swal from "sweetalert2";
import {loginState} from "atom/member/loginState";
import {useNavigate} from "react-router-dom";
import {useRef} from "react";
import ProfilePhoneNum from "./components/ProfilePhoneNum";
import SignatureCanvas from 'react-signature-canvas';
import SoftButton from "components/SoftButton";
import userWorkMemoSave from "../../api/user/userWorkMemoSave";
import userWorkMemoList from "../../api/user/userWorkMemoList";
import {TimestampToStringDate} from "../../utils/DateUtils";
import SoftInput from "../../components/SoftInput";
import "../../styles/signature/sigCanvas.css";
import DeleteCookie from "../../api/auth/deleteCookie";


function Overview() {

    const [isEditingSignature, setIsEditingSignature] = useState(false);

    //로그인 유저
    const user = useRecoilValue(loginUser);
    // 메모값
    const [memoValue, setMemoValue] = useState("");
    const [telValue, setTelValue] = useState("");
    const [signature, setSignature] = useState(user.signature);
    const [memoList, setMemoList] = useState();

    const updateUser = useSetRecoilState(loginUser);
    const resetLoginUser = useResetRecoilState(loginUser);
    const resetLoginState = useResetRecoilState(loginState);

    const navigate = useNavigate();

    const trimmedDataURL = useRef(null);
    const sigCanvas = useRef(null);

    const clearSignature = () => {
        sigCanvas.current.clear();
        trimmedDataURL.current = null;
    };

    const saveSignature = () => {
        if (sigCanvas.current.isEmpty()) {
            return;
        }
        const imageData = sigCanvas.current.toDataURL('image/png');
        const data = new FormData();
        data.append('imageData', imageData);
        data.append('userId', user.email)

        customAxios.post('/user/save-image', data)
            .then(response => {
                setIsEditingSignature(false);
                updateUser((prevState) => ({
                    ...prevState,
                    signature: imageData
                }));
                Swal.fire({
                    icon: 'success',
                    title: '서명 수정 완료!',
                    backdrop: false,
                });
            })
            .catch(error => {
                console.log(error);
            })

    };

    const handleEditSignature = () => {
        if (isEditingSignature) {
            setIsEditingSignature(false);
            setSignature(user.signature);
        } else {
            setSignature(null);
            setIsEditingSignature(true);
        }
    };

    // 수정 완료 후 비동기 업데이트
    useEffect(() => {
        setSignature(user.signature);
    }, [user]);
    
    // 연락처 저장
    const saveTel = () => {
        if(telValue === '') {
            Swal.fire({
                title: '수정할 연락처를 입력해주세요.',
                icon: 'warning',
                backdrop: false
            })
        } else {
            customAxios
                .post(myIpAddress+"/user/telUpdate", {
                    memNo: user?.id,
                    memTel: telValue
                })
                .then(()=>{
                updateUser((prevState) => ({
                    ...prevState,
                    tel: telValue
                }));
                Swal.fire({
                    icon: 'success',
                    title: '연락처 수정 완료!',
                    backdrop:false
                })
            })
            .catch(()=>{
                Swal.fire({
                title:'변경 실패',
                text:'연락처 수정 중 에러가 발생했습니다. 잠시후 다시 입력해주세요.',          
                icon:'error',
                backdrop:false               
                })
            })
        }
    }

    const HandleDelete = async () => {
        await Swal.fire({
            title: '정말로 탈퇴하시겠습니까?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '탈퇴',
            backdrop: false
        }).then((result) => {
            if (result.isConfirmed) {
                customAxios.post(myIpAddress + '/user/delete', {
                    email: user.email
                });
                DeleteCookie().then(r => {
                    resetLoginUser()
                    resetLoginState()
                    localStorage.removeItem(ACCESS_TOKEN);
                    navigate("/");
                }).catch(e =>{
                    console.log(e);
                });
                Swal.fire({
                    title: '회원 탈퇴 완료',
                    text: '정상적으로 탈퇴되었습니다.',
                    icon: 'success',
                    backdrop: false
                })
            }
        })
    };

    // 메모 입력
    const {mutate: addBody} = useMutation(userWorkMemoSave, {
        onSuccess: (data) => {
            memoSaveSucess();
            queries[0].refetch()
                .then(data => console.log(data))
                .catch(e => console.log(e));
        },
        onError: () => {
            memoSaveError();
        }
    });

    /**
     * 메모 리스트 불러오기
     * @type {QueriesResults<any[]>}
     */
    const queries = useQueries([
        // queries[0] : 메모리스트
        {
            queryKey: ["userWorkMemoList", user?.id],
            queryFn: () => userWorkMemoList(parseInt(user?.id)),
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            },
            onSuccess: (data) => {
                setMemoList(data.data);
            },
        },
    ]);

    /**
     * save error handle
     */
    const memoSaveError = () => {
        Swal.fire({
            title: '메모입력 중 에러가 발생했습니다. 잠시후 다시 입력해주세요',
            icon: 'warning',
            backdrop: false
        })
    }

    /**
     * save error handle
     */
    const memoSaveSucess = () => {
        Swal.fire({
            title: '메모가 정상적으로 입력되었습니다.',
            icon: 'success',
            backdrop: false
        }).then(()=>{
            setMemoValue("");
        })
        setMemoValue('')
    }

    /**
     * 메모 저장
     */
    const saveMemo = () => {
        if(memoValue !== "") {
            addBody({
                memNo: user?.id,
                memoContent: memoValue
            });
        } else {
            Swal.fire({
                title: '메모를 입력해주세요.',
                icon: 'warning',
                backdrop: false
            })
        }
    }

    // 메모 값 핸들링
    const memoHandle = (e) => {
        if (e.target.value !== '') {
            setMemoValue(e.target.value);
        } else {
            setMemoValue(e.target.value);
        }
    }

    return (
        <DashboardLayout>
            <Header user={user}/>
            <SoftBox mb={3}>
                <Grid container mb={3} spacing={3} justifyContent="space-between" alignItems="flex-start">
                    <Grid item xs={12} md={6} lg={6}>
                        <Card>
                            <Grid container p={3} display="flex" justifyContent="center"
                                  alignItems="center">
                                <Grid item xs={12} md={12} lg={12}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h5" color="black" fontWeight="bold">
                                            서명정보
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <SoftBox mb={2}>
                                        {signature ? (
                                            <Grid container display="flex" justifyContent="center"
                                                  alignItems="center">
                                                <Grid item xs={12} md={12} lg={12}>
                                                    <SoftBox p={1} display="flex" justifyContent="center"
                                                             alignItems="center" borderRadius="lg" sx={{border: '2px solid black'}}>
                                                        <img src={signature} alt="signature"
                                                             style={{width: "70%", height: "70%"}}/><br/>
                                                    </SoftBox>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid container display="flex" justifyContent="center"
                                                  alignItems="center">
                                                <Grid item xs={12} md={12} lg={12}>
                                                    <SoftBox p={1} display="flex" justifyContent="center"
                                                             alignItems="center">
                                                        {isEditingSignature && (
                                                            <SignatureCanvas
                                                                penColor='black'
                                                                canvasProps={{className: 'sigCanvas'}}
                                                                ref={sigCanvas}
                                                            />
                                                        )}
                                                    </SoftBox>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </SoftBox>
                                    <SoftBox display="flex" justifyContent="center" alignItems="center">
                                        <SoftButton variant="gradient" color="dark" onClick={handleEditSignature}>
                                            {isEditingSignature ? "취소" : "새로운 서명"}
                                        </SoftButton>
                                        {isEditingSignature &&
                                            <>
                                                &nbsp;&nbsp;<SoftButton variant="gradient" color="dark" onClick={clearSignature}>
                                                지우기
                                            </SoftButton>
                                            </>
                                        }
                                        &nbsp;&nbsp;{isEditingSignature &&<SoftButton variant="gradient" color="dark" onClick={saveSignature}>
                                        서명 저장
                                    </SoftButton>}
                                    </SoftBox>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Card style={{marginBottom:"24px"}}>
                            <Grid container p={3}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <SoftBox mb={2} display="flex" justifyContent="space-between" alignItems="center">
                                        <SoftTypography variant="h5" color="black" fontWeight="bold">
                                            연락처
                                        </SoftTypography>
                                        <SoftButton variant="gradient" color="dark" onClick={saveTel}>
                                            수정
                                        </SoftButton>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <ProfilePhoneNum category="TEL" defaultValue={user.tel === null ? '' : user.tel} setTelValue={setTelValue}/>
                                </Grid>
                            </Grid>
                        </Card>
                        <Card>
                            <Grid container p={3}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <SoftBox mb={2} display="flex" justifyContent="space-between" alignItems="center">
                                        <SoftTypography variant="h5" color="black" fontWeight="bold">
                                            개인작업일지 및 메모장
                                        </SoftTypography>
                                        <SoftButton variant="gradient" color="dark" onClick={saveMemo}>
                                            메모 입력
                                        </SoftButton>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <SoftBox mb={2}>
                                        <SoftInput
                                            multiline
                                            rows={2}
                                            onChange={memoHandle}
                                            value={memoValue}
                                        >
                                        </SoftInput>
                                    </SoftBox>
                                </Grid>
                                {memoList?.map((memo) => (
                                    <Grid item xs={12} md={12} lg={12}>
                                        <SoftBox>
                                            <SoftTypography variant="h6" color="black" fontWeight="bold">
                                                [{TimestampToStringDate(memo?.regDate)}] {memo?.memoContent}
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                ))
                                }
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container spacing={3} justifyContent="flex-end" alignItems="center">
                    <Grid item xs={12} md={12} lg={12}>
                        <Card sx={{width:"40%", margin : "auto"}}>
                            <SoftButton variant="gradient" color="error" onClick={HandleDelete}>
                                <SoftTypography variant="h6" color="white" fontWeight="bold">회원 탈퇴</SoftTypography>
                            </SoftButton>
                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
            <Footer/>
        </DashboardLayout>
    );
}

export default Overview;
